import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { ProductType } from 'Type/ProductList.type';

import CampaignLabel from './CampaignLabel.component';
/** @namespace Bodypwa/Component/CampaignLabel/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    customer: state.MyAccountReducer.customer
});

/** @namespace Bodypwa/Component/CampaignLabel/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Bodypwa/Component/CampaignLabel/Container */
export class CampaignLabelContainer extends PureComponent {
    static propTypes = {
        product: ProductType,
        discount: PropTypes.number,
        isCampaingLabel: PropTypes.bool,
        isBottom: PropTypes.bool,
        isAbsolute: PropTypes.bool,
        // eslint-disable-next-line react/forbid-prop-types
        customer: PropTypes.object.isRequired
    };

    static defaultProps = {
        product: {},
        isCampaingLabel: true,
        discount: 0,
        isBottom: false,
        isAbsolute: true
    };

    containerProps = () => {
        const {
            product, discount, isCampaingLabel, isBottom, isAbsolute, customer: { group_id } = {}
        } = this.props;

        return {
            product, discount, isCampaingLabel, isBottom, isAbsolute, group_id
        };
    };

    render() {
        return (
            <CampaignLabel
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignLabelContainer);
