import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/CheckMarkFilledIcon/Component */
export class CheckMarkFilledIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { size, color } = this.props;
        const { dimensions, colors } = this;

        return (
            <svg
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >

            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715
            17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM7 12.1937L10.6486 15.7973L17
            9.491L16.009 8.5L10.6486 13.9054L7.99097 11.2027L7 12.1937Z"
              fill={ colors[ color ] }
              className="icon-color"
            />
            </svg>
        );
    }
}
export default CheckMarkFilledIconComponent;
