/* eslint-disable @scandipwa/scandipwa-guidelines/only-render-in-component */
import {
    Overlay as SourceOverlay
} from 'SourceComponent/Overlay/Overlay.component';
import { toggleScroll } from 'Util/Browser';

import './Overlay.style';

/** @namespace Bodypwa/Component/Overlay/Component */
export class OverlayComponent extends SourceOverlay {
    componentDidUpdate(prevProps) {
        const prevWasVisible = this.getIsVisible(prevProps);
        const isVisible = this.getIsVisible();

        if (isVisible && !prevWasVisible) {
            this.onVisible();
        }

        if (!isVisible && prevWasVisible) {
            this.onHide();
        }
    }

    onVisible() {
        const { onVisible, isStatic, isFrezeScroll = true } = this.props;

        if (isStatic) {
            return;
        }

        if (isFrezeScroll) {
            this.freezeScroll();
        }

        this.overlayRef.current.focus();
        onVisible();
    }

    onHide() {
        const { onHide, isStatic, isFrezeScroll = true } = this.props;

        if (isStatic) {
            return;
        }

        if (isFrezeScroll) {
            this.unfreezeScroll();
        }

        onHide();
    }

    freezeScroll() {
        document.documentElement.style.setProperty(
            '--window-inner-height',
            `${window.innerHeight}px`
        );
        this.YoffsetWhenScrollDisabled = window.pageYOffset || document.body.scrollTop;
        toggleScroll(false);
        document.documentElement.style.top = `${-this.YoffsetWhenScrollDisabled}px`;
    }

    unfreezeScroll() {
        toggleScroll(true);
        const { scrollToElement, setScrollToElement } = this.props;
        document.documentElement.style.top = 0;
        if (scrollToElement) {
            window.scrollTo(0, scrollToElement);
            setScrollToElement(0);
        } else {
            window.scrollTo(0, this.YoffsetWhenScrollDisabled);
        }
    }
}

export default OverlayComponent;
