import {
    TextPlaceholder as SourceTextPlaceholder
} from 'SourceComponent/TextPlaceholder/TextPlaceholder.component';

/** @namespace Bodypwa/Component/TextPlaceholder/Component */
export class TextPlaceholderComponent extends SourceTextPlaceholder {
    // TODO implement logic
    render() {
        const {
            content, mix,
            length = false,
            height = false,
            align = false,
            gap = false,
            space = false,
            fill = false,
            circle = false
        } = this.props;

        if (content) {
            return content;
        }

        return (
            <span
              mix={ mix }
              block="TextPlaceholder"
              mods={ {
                  length, height, align, gap, space, fill, circle
              } }
            >
                        <span />
            </span>
        );
    }
}

export default TextPlaceholderComponent;
