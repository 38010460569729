import {
    NEW_VERSION_POPUP_ID as SOURCE_NEW_VERSION_POPUP_ID
} from 'SourceComponent/NewVersionPopup/NewVersionPopup.config';

// TODO: implement NEW_VERSION_POPUP_ID
export const NEW_VERSION_POPUP_ID = SOURCE_NEW_VERSION_POPUP_ID;
export const APP_VERSION = 'APP_VERSION';
export const APP_UPDATED = 'APP_UPDATED';
export const CURRENT_VERSION = process.env.APP_VERSION;
export const BODYAPP_VERSION = 'bodyapp-version';
