/* eslint-disable no-nested-ternary */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-lines */

import __ from '@scandipwa/webpack-i18n-runtime/src/util/__';
import { createRef } from 'react';

import Link from 'Component/Link';
import { STOCK_TYPE } from 'Component/Product/Stock.config';
import ProductAttributeValue from 'Component/ProductAttributeValue';
import {
    COLOR_ATTRIBUTE,
    SELECTEBALE_ATTRIBUTES_ARRAY,
    STONE_ATTRIBUTE
} from 'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.config';
import ScrollSwiper from 'Component/ScrollSwiper';
import ScrollSwiperSlide from 'Component/ScrollSwiperSlide';
import SizeGuideActionComponent from 'Component/SizeGuideAction';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    ProductConfigurableAttributes as SourceProductConfigurableAttributes
} from 'SourceComponent/ProductConfigurableAttributes/ProductConfigurableAttributes.component';

import './ProductConfigurableAttributes.override.style';
/** @namespace Bodypwa/Component/ProductConfigurableAttributes/Component */
export class ProductConfigurableAttributesComponent extends SourceProductConfigurableAttributes {
    static propTypes = {
        ...SourceProductConfigurableAttributes.propTypes
    };

    swiperRef = createRef(null);

    autoplay = {};

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
        this.state = {
            autoplay: false,
            reverseDirection: true
        };
    }

    renderConfigurableAttributeValue(attribute) {
        const {
            getIsConfigurableAttributeAvailable,
            handleOptionClick,
            isSelected,
            mix: { block },
            inStock,
            variantsPage
        } = this.props;

        // TODO move isPdp logic to use redux
        const isPlp = block === 'ProductCard';

        const { attribute_value } = attribute;
        const isAvailable = getIsConfigurableAttributeAvailable(attribute) && inStock;
        return (
            <ProductAttributeValue
              key={ attribute_value }
              attribute={ attribute }
              isSelected={ isSelected(attribute) }
              isAvailable={ isAvailable }
              onClick={ handleOptionClick }
              showProductAttributeAsLink={ false }
              variantsPage={ variantsPage }
              isPlp={ isPlp }
            />
        );
    }

    renderConfigurableAttributes(sizeOption) {
        const {
            configurable_options,
            isExpandable,
            inStock,
            mix: { block },
            parameters,
            getStockLabel,
            product: { sku } = {},
            showOptions,
            isSkuParam
        } = this.props;
        const isPlp = block === 'ProductCard';
        const sizeAttr = [510, 511, 515, 516, 517, 584, 591];

        const mainConfig = Object.keys(configurable_options).filter(
            (attribute_code) => SELECTEBALE_ATTRIBUTES_ARRAY.includes(attribute_code)
        )[0];

        if (!mainConfig && isPlp) {
            return null;
        }

        const configurableOptions = isPlp || ((!showOptions[sku]) && !isSkuParam && !isPlp)
            ? { [mainConfig]: configurable_options[mainConfig] }
            : configurable_options;

        return Object.values(configurableOptions).map((option) => {
            const {
                attribute_label,
                attribute_options,
                attribute_id,
                attribute_code
            } = option || {};

            const isSizeOption = sizeAttr.includes(attribute_id);
            const [{ swatch_data }] = attribute_options ? Object.values(attribute_options) : [{}];
            const isSwatch = !!swatch_data;
            const label = attribute_label ? `${attribute_label}:` : '';

            if (inStock === STOCK_TYPE.OUT_OF_STOCK || !inStock) {
                return null;
            }

            // render content without heading and subheading
            if (!isExpandable) {
                return isSwatch ? this.renderSwatch(option) : this.renderPlaceholders();
            }

            if (sizeOption && isSizeOption) {
                return (
                    <div key={ attribute_id }>
                        <div className="ProductConfigurableAttributes-Header">
                            <p block="ProductConfigurableAttributes" elem="Title" mods={ { oos: !showOptions[sku] } }>
                                <TextPlaceholder content={ label } length="short" />
                                <span>
                            { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional,max-len */ }
                                    { parameters[attribute_code] ? getStockLabel(attribute_code, attribute_options, parameters) : '' }
                                </span>
                            </p>
                        </div>
                        { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional,max-len */ }
                        { isSwatch ? (configurable_options[COLOR_ATTRIBUTE] || configurable_options[STONE_ATTRIBUTE]) && isPlp
                            ? this.renderImageSwatch(option)
                            : this.renderSwatch(option)
                            : this.renderPlaceholders() }
                    </div>
                );
            }

            if (!sizeOption && !isSizeOption) {
                return (
                    <div
                      key={ attribute_id }
                    >
                        <div className="ProductConfigurableAttributes-Header">
                            <p block="ProductConfigurableAttributes" elem="Title" mods={ { oos: !showOptions[sku] } }>
                                <TextPlaceholder content={ label } length="short" />
                                <span>
                            { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional,max-len */ }
                                    { parameters[attribute_code] && attribute_options[parameters[attribute_code]]
                                        ? getStockLabel(attribute_code, attribute_options, parameters) : '' }
                                </span>
                            </p>
                        </div>
                        { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional,max-len */ }
                        { isSwatch ? isPlp
                            ? this.renderImageSwatch(option)
                            : this.renderSwatch(option)
                            : this.renderPlaceholders() }
                    </div>
                );
            }

            return null;
        });
    }

    renderImageSwatch(option) {
        const { isSwiper } = this.props;
        const { attribute_code } = option;
        if (isSwiper && (attribute_code === COLOR_ATTRIBUTE || attribute_code === STONE_ATTRIBUTE)) {
            return this.renderSwatchPreview(option);
        }

        return this.renderSwatchSwiper(option);
    }

    renderSwatchPreview(option) {
        const { productUrl, isMobile = false } = this.props;
        const { attribute_values: attributeValues, attribute_options, attribute_code } = option;
        const attribute_values = attributeValues.length ? attributeValues : Object.keys(attribute_options);
        const previewItems = isMobile ? 2 : 3; // TODO create config
        const remainingItems = attribute_values.length - previewItems;
        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                { attribute_values.slice(0, previewItems).map((attribute_value) => (
                    <div key={ `${attribute_code}-${attribute_value}` }>
                        { this.renderConfigurableAttributeValue({ ...option, attribute_values, attribute_value }) }
                    </div>
                )) }
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { remainingItems > 0
                    ? <Link to={ productUrl }>{ `+${remainingItems}` }</Link> : null }
            </div>
        );
    }

    renderSwatchSwiper(option) {
        const { attribute_values: attributeValues, attribute_options, attribute_code } = option;
        const attribute_values = attributeValues.length ? attributeValues : Object.keys(attribute_options);

        return (
            <div
              block="ProductConfigurableAttributes"
              elem="SwatchList"
              key={ attribute_code }
            >
                <ScrollSwiper
                  isAutoScroll
                >
                    { attribute_values.map((attribute_value) => (
                        <ScrollSwiperSlide key={ `${attribute_code}-${attribute_value}` }>
                            { this.renderConfigurableAttributeValue({ ...option, attribute_values, attribute_value }) }
                        </ScrollSwiperSlide>
                    )) }
                </ScrollSwiper>
            </div>
        );
    }

    renderAditionalAttribute() {
        const {
            configurable_options
        } = this.props;

        if (configurable_options[COLOR_ATTRIBUTE]) {
            return null;
        }

        return (
            <p className="ProductCard-SimpleOptions">{ __('1 Color') }</p>
        );
    }

    render() {
        const {
            isReady, mix, configurable_options, variants, showOptions, product: { sku } = {}, isSkuParam
        } = this.props;

        const sizeAttr = [510, 511, 515, 516, 517, 584, 591];
        const optionIds = Object.values(configurable_options).map((optionId) => optionId.attribute_id);
        const hasSizeAttr = optionIds.some((r) => sizeAttr.includes(r));

        // TODO move isPdp logic to use redux
        const isPdp = mix.block === 'ProductActions';
        return (
            <div
              block="ProductConfigurableAttributes"
              mods={ { isLoading: !isReady } }
              mix={ mix }
            >
                <div
                  block="ProductConfigurableAttributes"
                  elem="Options"
                >
                    { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                    { (isPdp && isReady && variants?.length) || (!isPdp && isReady)
                        ? this.renderConfigurableAttributes(false) : this.renderPlaceholders() }
                </div>
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { isPdp && hasSizeAttr && (showOptions[sku] || isSkuParam) ? (
                    <div
                      block="ProductConfigurableAttributes"
                      elem="SizeOptions"
                    >
                        <SizeGuideActionComponent />
                        { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                        { (isPdp && isReady && variants?.length) || (!isPdp && isReady)
                            ? this.renderConfigurableAttributes(true) : this.renderPlaceholders() }
                    </div>
                ) : null }
            </div>
        );
    }
}

export default ProductConfigurableAttributesComponent;
