import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    SearchItemContainer as SourceSearchItemContainer
} from 'SourceComponent/SearchItem/SearchItem.container';
import componentLoader from 'Util/componentLoader';

export const SearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
), 2);

export {
    mapDispatchToProps,
    mapStateToProps
};

/** @namespace Bodypwa/Component/SearchItem/Container */
export class SearchItemContainer extends SourceSearchItemContainer {
    getLinkTo() {
        const {
            product,
            product: { url_rewrites = [] }
        } = this.props;

        if (!url_rewrites[0]) {
            return {};
        }

        return {
            pathname: url_rewrites[0].url,
            state: { product }
        };
    }

    containerProps() {
        const { product } = this.props;

        return {
            product,
            linkTo: this.getLinkTo(),
            imgSrc: this.getImgSrc(),
            image_cover: this.getOverlayDisable(),
            customAttribute: this.getCustomAttribute()
        };
    }

    getOverlayDisable() {
        const {
            product: {
                thumbnail: { image_cover } = {}
            }
        } = this.props;

        return image_cover;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchItemContainer);
