import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
import {
    StoreSwitcher as SourceStoreSwitcher
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.component';

import { STORE_CONFIG_NAME_MAP } from './StoreSwitcher.config';

/** @namespace Bodypwa/Component/StoreSwitcher/Component */
export class StoreSwitcherComponent extends SourceStoreSwitcher {
    renderDesktopStoreSwitcher() {
        const {
            currentStoreCode,
            storeList,
            onStoreSwitcherOutsideClick,
            onStoreSwitcherClick,
            isOpened,
            positionBottom: isBottom,
            storeSwitcherId
        } = this.props;

        const mods = { isOpen: isOpened[storeSwitcherId], isBottom };

        const store = storeList.find(
            ({ value }) => value === currentStoreCode
        );
        const storeLabel = store ? store.label : '';

        return (
            <div block="StoreSwitcher">
                <ClickOutside onClick={ onStoreSwitcherOutsideClick }>
                    <button
                      block="StoreSwitcher"
                      elem="Title"
                      mods={ mods }
                      onClick={ onStoreSwitcherClick }
                    >
                        <span className={ `fi fi-${STORE_CONFIG_NAME_MAP[storeLabel]}` }>
                            <span className="fi-border" />
                            <span className="fi-borderGray" />
                        </span>
                        <span
                          block="StoreItem"
                          elem="Label"
                        >
                        { storeLabel }
                        </span>
                        <ChevronIcon direction={ isOpened[storeSwitcherId] ? TOP : BOTTOM } />
                    </button>

                    <div block="StoreSwitcher" elem="StoreList" mods={ mods }>
                        { storeList.map(this.renderStore) }
                    </div>
                </ClickOutside>
            </div>
        );
    }

    renderMobileStoreSwitcher() {
        const {
            storeList,
            onStoreSwitcherOutsideClick,
            onStoreSwitcherClick,
            isOpened,
            storeLabel,
            storeSwitcherId
        } = this.props;

        const mods = { isOpen: isOpened[storeSwitcherId] };

        return (
            <div block="StoreSwitcher" className="MyAccountOverlay-StoreSwitcher">
                <ClickOutside onClick={ onStoreSwitcherOutsideClick }>
                    <div block="StoreSwitcher" elem="StoreList" mods={ mods }>
                        { storeList.map(this.renderStore) }
                    </div>
                    <button
                      block="StoreSwitcher"
                      elem="Title"
                      mods={ mods }
                      onClick={ onStoreSwitcherClick }
                    >
                        <span className={ `fi fi-${STORE_CONFIG_NAME_MAP[storeLabel]}` }>
                            <span className="fi-border" />
                            <span className="fi-borderGray" />
                        </span>
                        <span
                          block="StoreItem"
                          elem="Label"
                        >
                        { storeLabel }
                        </span>
                        <ChevronIcon direction={ isOpened[storeSwitcherId] ? TOP : BOTTOM } />
                    </button>
                </ClickOutside>
            </div>
        );
    }
}

export default StoreSwitcherComponent;
