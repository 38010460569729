import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';

/** @namespace Bodypwa/Component/LockIcon/Component */
export class LockIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color } = this.props;
        return (
          <svg
            width={ dimensions[ size ] }
            height={ dimensions[ size ] }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path
            d="M12 16C12.6904 16 13.25 15.4404 13.25 14.75C13.25 14.0596 12.6904 13.5 12
          13.5C11.3096 13.5 10.75 14.0596 10.75 14.75C10.75 15.4404 11.3096 16 12 16Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M17 7V8H19C20.1046 8 21 8.89543 21 10V20C21
          21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V10C3 8.89543 3.89543 8 5 8H7V7C7 4.23858
          9.23858 2 12 2C14.7614 2 17 4.23858 17 7ZM9.5 8H14.5V7C14.5 5.61929 13.3807 4.5 12 4.5C10.6193 4.5 9.5
          5.61929 9.5 7L9.5 8ZM5.5 19.5V10.5H18.5V19.5H5.5Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          </svg>

        );
    }
}
export default LockIconComponent;
