/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/UserIcon/Component */

export class UserIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { isActive } = this.props;
        const { size, color } = this.props;
        const { dimensions, colors } = this;

        return (
            <svg
              mods={ { isActive } }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              block="BodIcon"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 4.5C9.23858 4.5 7 6.73858 7 9.5C7 12.2614 9.23858 14.5 12 14.5C14.7614 14.5 17 12.2614 17 9.5C17 6.73858 14.7614 4.5 12 4.5ZM4.5 9.5C4.5 5.35786 7.85786 2 12 2C16.1421 2 19.5 5.35786 19.5 9.5C19.5 13.6421 16.1421 17 12 17C7.85786 17 4.5 13.6421 4.5 9.5ZM7.44993 18H16.5501L22 19.5571V22H19.5V21.4429L16.1999 20.5H7.80007L4.5 21.4429V22H2V19.5571L7.44993 18Z"
              fill={ colors[ color ] }
              className="icon-color"
            />
            </svg>
        );
    }
}

export default UserIconComponent;
