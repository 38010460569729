import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Image from 'Component/Image';
import Link from 'Component/Link';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CategoryTreeType } from 'Type/Category.type';
import { LinkType } from 'Type/Router.type';
import media, { CATEGORY_MEDIA } from 'Util/Media';

import './SearchCategory.style';

/** @namespace Bodypwa/Component/SearchCategory/Component */
export class SearchCategoryComponent extends PureComponent {
    static propTypes = {
        linkTo: LinkType,
        category: CategoryTreeType,
        onClick: PropTypes.func.isRequired
    };

    static defaultProps = {
        linkTo: {},
        category: {}
    };

    renderContent() {
        const { category: { name } } = this.props;

        return (
            <figcaption block="SearchCategory" elem="Title">
                <TextPlaceholder content={ name } length="long" />
            </figcaption>
        );
    }

    renderImage() {
        const {
            category: { image, thumbnail, name }
        } = this.props;

        if (!image && !thumbnail) {
            return (
                <div
                  block="SearchCategory"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchCategory"
              elem="Image"
              src={ thumbnail ? media(thumbnail, CATEGORY_MEDIA) : image }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
            />
        );
    }

    renderLink() {
        const { linkTo, onClick } = this.props;

        return (
            <Link
              block="SearchCategory"
              elem="Link"
              to={ linkTo }
              onClick={ onClick }
            >
                <figure
                  block="SearchCategory"
                  elem="Wrapper"
                >
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
            </Link>
        );
    }

    render() {
        return (
            <li block="SearchCategory">
                { this.renderLink() }
            </li>
        );
    }
}

export default SearchCategoryComponent;
