/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';

import './HeartIcon.style';

/** @namespace Bodypwa/Component/HeartIcon/Component */
export class HeartIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { isActive, size, color } = this.props;
        const { dimensions, colors } = this;
        if (isActive) {
            return (
                <svg
                  mods={ { isActive } }
                  width={ dimensions[ size] }
                  height={ dimensions[ size ] }
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 4.27813C9.64263 2.17156 6.02174 2.24995 3.7584 4.51328C1.41387 6.85782 1.41387 10.6591 3.7584 13.0036L12 21.2452L20.2416 13.0036C22.5861 10.6591 22.5861 6.85782 20.2416 4.51328C17.9783 2.24995 14.3574 2.17156 12 4.27813Z" fill={ colors[ color ] } className="icon-color" />
                </svg>

            );
        }

        return (
            <svg
              mods={ { isActive } }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M12 7.63087L10.3342 6.14228C8.96012
             4.9144 6.84641 4.96081 5.52617 6.28105C4.15794 7.64927 4.15794 9.86761 5.52617
             11.2358L12 17.7097L18.4738 11.2358C19.8421 9.86761 19.8421 7.64927 18.4738 6.28105C17.1536
             4.96081 15.0399 4.9144 13.6658 6.14228L12 7.63087ZM12 21.2452L3.7584 13.0036C1.41387 10.6591
             1.41387 6.85782 3.7584 4.51328C6.02174 2.24995 9.64263 2.17156 12 4.27813C14.3574 2.17156
              17.9783 2.24995 20.2416 4.51328C22.5861 6.85782 22.5861 10.6591 20.2416 13.0036L12 21.2452Z"
              fill={ colors[ color ] }
              className="icon-color"
            />
            </svg>

        );
    }
}

export default HeartIconComponent;
