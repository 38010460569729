export const META_INDEX_FOLLOW = 'INDEX,FOLLOW';
export const META_NOIDEX_NOFOLLOW = 'NOINDEX,NOFOLLOW';
export const META_INDEX_NOFOLLOW = 'INDEX,NOFOLLOW';
export const META_NOINDEX_FOLLOW = 'NOINDEX,FOLLOW';
export const META_NOINDEX_FOLLOW_PAGES = ['cart', 'checkout', 'account'];

export const PAGE_TYPE_CMS_PAGE = 'CMS_PAGE';
export const PAGE_TYPE_HOME = 'HOME';
export const PAGE_TYPE_PRODUCT = 'PRODUCT';
export const PAGE_TYPE_CATEGORY = 'CATEGORY';
export const PAGE_TYPE_CHECKOUT = 'CHECKOUT';
export const PAGE_TYPE_CART = 'CART';
export const PAGE_TYPE_SUCCESS = 'SUCCESS';
export const PAGE_TYPE_NOTFOUND = 'NOT_FOUND';
