/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';

import './CloseIcon.style';
/** @namespace Bodypwa/Component/CloseIcon/Component */

export class CloseIconComponent extends IconComponent {
  static propTypes = {
      size: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired
  };

  render() {
      const { dimensions, colors } = this;
      const { size, color } = this.props;
      return (
        <svg
          width={ dimensions[ size ] }
          height={ dimensions[ size ] }
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
        <path
          block="CloseBodIcon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM15.091 8L12 11.0998L8.90897 8L8.02259 8.88889L11.1136 11.9887L8 15.1111L8.88638 16L12 12.8776L15.1136 16L16 15.1111L12.8864
         11.9887L15.9774 8.88889L15.091 8Z"
          fill={ colors[ color ] }
          className="icon-color"
        />
        </svg>

      );
  }
}

export default CloseIconComponent;
