import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import AddIcon from 'Component/AddIcon';
import Button from 'Component/Button';
import { SIZE_NONE, TYPE_TEXT } from 'Component/Button/Button.config';
import { FIELD_TYPE } from 'Component/Field/Field.config';
import { BLACK_KEY, S_KEY } from 'Component/Icon/Icon.config';
import MinusIcon from 'Component/MinusIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { EventsType, FieldAttrType } from 'Type/Field.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import { LARGE_SIZE, SMALL_SIZE } from './FieldNumber.config';

import './FieldNumber.style.scss';
/**
 * Field Number
 * @class FieldNumber
 * @namespace Bodypwa/Component/FieldNumber/Component */
export class FieldNumberComponent extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        value: PropTypes.number.isRequired,
        handleValueChange: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        handleInputChange: PropTypes.func.isRequired,
        handleInputBlur: PropTypes.func.isRequired,
        size: PropTypes.oneOf([SMALL_SIZE, LARGE_SIZE]),
        isReadOnly: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        size: LARGE_SIZE,
        isLoading: false
    };

    render() {
        const {
            attr,
            attr: { min = 1, max = DEFAULT_MAX_PRODUCTS },
            events,
            setRef,
            value,
            handleValueChange,
            handleInputChange,
            handleInputBlur,
            isDisabled,
            size,
            isReadOnly,
            isLoading
        } = this.props;

        if (isLoading) {
            return (
                <div block="FieldNumber" elem="Number" mods={ { isLoading } }>
                    <TextPlaceholder height="filter" length="filter" />
                </div>
            );
        }

        const isAddDisabled = +value >= max;
        const isSubtractDisabled = value <= min;
        const message = isAddDisabled ? `Only ${value} items left` : '';
        return (
            // eslint-disable-next-line max-len
            <div
              block="FieldNumber"
              elem="Number"
              mods={ {
                  size, error: !!message, disabled: isDisabled, readonly: isReadOnly
              } }
            >
                <input
                  ref={ (elem) => setRef(elem) }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...attr }
                  // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                  { ...events }
                  type={ FIELD_TYPE.number }
                  readOnly={ isReadOnly }
                  aria-label={ __('Value') }
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={ (e) => handleInputChange(e.target.value ? parseInt(e.target.value, 10) : e.target.value) }
                  // eslint-disable-next-line react/jsx-no-bind
                  onBlur={ (e) => handleInputBlur(e.target.value ? parseInt(e.target.value, 10) : e.target.value) }
                  value={ value }
                  disabled={ isDisabled }
                />
                <Button
                  isDisabled={ isAddDisabled || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value + 1) }
                  aria-label={ __('Add') }
                  actionType={ FIELD_TYPE.button }
                  type={ TYPE_TEXT }
                  size={ SIZE_NONE }
                  isIcon
                  readOnly={ isReadOnly }
                >
                    <AddIcon block="SubtractButton" isPrimary size={ S_KEY } color={ BLACK_KEY } />
                </Button>
                <Button
                  isDisabled={ isSubtractDisabled || isDisabled }
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ () => handleValueChange(+value - 1) }
                  aria-label={ __('Subtract') }
                  actionType={ FIELD_TYPE.button }
                  type={ TYPE_TEXT }
                  size={ SIZE_NONE }
                  isIcon
                  readOnly={ isReadOnly }
                >
                    <MinusIcon block="AddButton" isPrimary size={ S_KEY } color={ BLACK_KEY } />
                </Button>
                <div block="FieldNumber" elem="MessageError">
                    { message && <div block="FieldNumber" elem="Message">{ message }</div> }
                </div>
            </div>
        );
    }
}

export default FieldNumberComponent;
