/* eslint-disable import/prefer-default-export */

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

export const REQUEST_SHIPPING_METHODS_FREQUENCY = 1000; // (ms)
export const CUSTOMER_SHIPPING_ADDRESS = 'CUSTOMER_SHIPPING_ADDRESS';
export const CUSTOMER_BILLING_ADDRESS = 'CUSTOMER_BILLING_ADDRESS';
