/** @namespace PageBuilder/Component/DotdigitalForm/Dotdigital/Component */
export class DotdigitalComponent extends React.PureComponent {
    __construct(props) {
        super.__construct(props);
        this.state = {
            iframeHeight: 0
        };
    }

  resizeIframe = () => {
      this.setState({ iframeHeight: document.documentElement.clientHeight });
  };

  renderIframe = () => {
      const { iframeHeight } = this.state;
      const iframeSrc = '//r1.dotdigital-pages.com/resources/sharing/embed.js?sharing=lp-embed&domain=r1.dotdigital-pages.com&id=5NPD-C55%2Fthank-you-check-your-inbox-soon-you-will-receive-news-from-bodymod%22';
      return (
        <iframe
          src={ iframeSrc }
          id="dotdigital"
          onLoad={ this.resizeIframe }
          className="_lpSurveyEmbed"
          id="dotdigital"
          width="100%"
          frameBorder="0"
          scrolling="yes"
          allow="geolocation"
          sandbox="allow-modals allow-downloads allow-top-navigation allow-forms allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation-by-user-activation"
          style={ {
              border: 'none 0!important;margin:0!important;padding:0!important',
              height: `${iframeHeight }px`,
              maxHeight: `${iframeHeight }px`
          } }
        />
      );
  };

  render() {
      return (
        <div>
            { this.renderIframe() }
        </div>
      );
  }
}

export default DotdigitalComponent;
