import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { ROUTE_CHANGE_THRESHOLD } from 'Component/Menu/Menu.config';
import CmsBlockQuery from 'Query/CmsBlock.query';
import { resetCmsBlocks, setCmsBlock } from 'Store/Cms/Cms.action';
import history from 'Util/History';
import { prepareQuery } from 'Util/Query';
import DataContainer from 'Util/Request/DataContainer';
import { hash } from 'Util/Request/Hash';
import { listenForBroadCast } from 'Util/Request/Request';

import CmsBlock from './CmsBlock.component';
/** @namespace Bodypwa/Component/CmsBlock/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    blockType: state.ConfigReducer.cms_block_type,
    blocks: state.CmsReducer.blocks,
    routeChangeCounter: state.MenuReducer.routeChangeCounter,
    baseUrl: state.ConfigReducer.base_url
});

/** @namespace Bodypwa/Component/CmsBlock/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setCmsBlock: (block) => dispatch(setCmsBlock(block)),
    resetCmsBlocks: () => dispatch(resetCmsBlocks())
});

/** @namespace Bodypwa/Component/CmsBlock/Container */
export class CmsBlockContainer extends DataContainer {
    dataModelName = 'CmsBlock';

    static propTypes = {
        identifier: PropTypes.string.isRequired
    };

    state = {
        cmsBlock: {},
        location: history.location
    };

    componentDidMount() {
        history.listen((history) => {
            this.onRouteChanged(history);
        });
        const { blocks, identifier } = this.props;

        if (blocks[identifier]) {
            this.setState({ cmsBlock: blocks[identifier] });
        } else {
            this._getCmsBlock();
        }
    }

    containerProps = () => {
        const { blockType, baseUrl } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, baseUrl };
    };

    onRouteChanged(history) {
        const { blocks, identifier, routeChangeCounter } = this.props;
        const { location: { pathname: prevPathname } = {} } = this.state;
        const { pathname } = history;
        if (prevPathname !== pathname) {
            if (routeChangeCounter + 1 >= ROUTE_CHANGE_THRESHOLD || !blocks[identifier]) {
                this._getCmsBlock();
            } else {
                this.setState({ cmsBlock: blocks[identifier] });
            }
            this.setState({ location: history });
        }
    }

    componentDidUpdate(prevProps) {
        const { identifier } = this.props;
        const { identifier: prevIdentifier } = prevProps;

        if (identifier !== prevIdentifier) {
            this._getCmsBlock();
        }
    }

    _getCmsBlock() {
        const { identifier, setCmsBlock } = this.props;

        this.fetchData(
            [CmsBlockQuery.getQuery({ identifiers: [identifier] })],
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
                setCmsBlock({ content: items[0], identifier });
            },
            () => {},
            identifier
        );

        // TODO this logic should be moved to the DataContainer
        const preparedQuery = prepareQuery([CmsBlockQuery.getQuery({ identifiers: [identifier] })]);
        const { query, variables } = preparedQuery;
        const queryHash = hash(query + JSON.stringify(variables));
        listenForBroadCast(identifier).then(
            /** @namespace Bodypwa/Component/CmsBlock/Container/CmsBlockContainer/_getCmsBlock/listenForBroadCast/then */
            ({ cmsBlocks: { items } }) => {
                if (!items.length) {
                    return;
                }

                this.setState({ cmsBlock: items[0] });
                setCmsBlock({ content: items[0], identifier });
                window.dataCache[queryHash] = { cmsBlocks: { items } };
            }
        );
    }

    containerProps = () => {
        const { blockType, identifier: id } = this.props;
        const { cmsBlock } = this.state;

        return { cmsBlock, blockType, id };
    };

    render() {
        return (
            <CmsBlock
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsBlockContainer);
