import PropTypes from 'prop-types';

import Link from 'Component/Link';
import {
    MenuItem as SourceMenuItem
} from 'SourceComponent/MenuItem/MenuItem.component';
import { DeviceType } from 'Type/Device.type';
import media from 'Util/Media';

import './MenuItem.override.style.scss';

/** @namespace Bodypwa/Component/MenuItem/Component */
export class MenuItemComponent extends SourceMenuItem {
    static propTypes = {
        ...SourceMenuItem.propTypes,
        hideIcon: PropTypes.bool,
        itemHidden: PropTypes.bool.isRequired,
        device: DeviceType.isRequired
    };

    static defaultProps = {
        ...SourceMenuItem.defaultProps,
        hideIcon: false,
        itemHidden: false,
        device: DeviceType
    };

    renderItemLinkContent() {
        const {
            activeMenuItemsStack,
            item,
            itemMods,
            handleCategoryHover,
            handleLinkLeave,
            isSubMenuActive,
            onItemClick,
            device: { isMobile }
        } = this.props;

        const {
            url,
            item_id
        } = item;
        const isHovered = activeMenuItemsStack.includes(item_id);

        if (url === '#') {
            return (
                <p
                  block="Menu"
                  elem="Link"
                  id={ item_id }
                  onMouseEnter={ handleCategoryHover }
                  onMouseLeave={ handleLinkLeave }
                  mods={ { isHovered } }
                  style={ isMobile && (isSubMenuActive && itemMods.type === 'main') ? { display: 'none' } : {} }
                >
                    { this.renderItemLinkImage(item) }
                    { this.renderItemContent(item, itemMods) }
                </p>
            );
        }

        return (
            <Link
              to={ url }
              block="Menu"
              elem="Link"
              id={ item_id }
              onMouseEnter={ handleCategoryHover }
              onMouseLeave={ handleLinkLeave }
              mods={ { isHovered } }
              style={ isMobile && (isSubMenuActive && itemMods.type === 'main') ? { display: 'none' } : {} }
              onClick={ onItemClick }
            >
                { this.renderItemLinkImage(item) }
                { this.renderItemContent(item, itemMods) }
            </Link>
        );
    }

    renderItemContent(item) {
        const { isSubMenuActive, itemMods, device = {} } = this.props;
        const {
            title,
            item_class
        } = item;

        return (
            <figcaption
              block="Menu"
              elem="ItemCaption"
              className={ item_class }
              style={ device?.isMobile && (isSubMenuActive && itemMods.type === 'main') ? { display: 'none' } : {} }
              mods={ itemMods }
            >
                { title }
            </figcaption>
        );
    }

    renderItemLinkImage(item) {
        const { icon, title } = item;
        const {
            hideIcon, device = {}, isSubMenuActive, itemMods
        } = this.props;

        if (!icon || (hideIcon && !device.isMobile)) {
            return null;
        }
        const image = media(icon);
        return (
            <>
                <div className={ device.isMobile ? 'Image-Holder' : 'Image-Loader' } />
                <img
                  src={ image }
                  mix={ {
                      block: 'MenuIcon',
                      elem: 'Image'
                  } }
                  style={ device.isMobile && (isSubMenuActive && itemMods.type === 'main') ? { display: 'none' } : {} }
                  alt={ `Category ${title} thumbnail.` }
                  loading="lazy"
                />
            </>
        );
    }

    render() {
        const {
            item, itemMods, isLink, device = {}, isSubMenuActive
        } = this.props;
        const { icon, title } = item;

        if (isLink) {
            return this.renderItemLinkContent();
        }

        return (
            <>
                <img
                  src={ media(icon) }
                  mix={ {
                      block: 'MenuIcon',
                      elem: 'Image'
                  } }
                  style={ device.isMobile && (isSubMenuActive && itemMods.type === 'main') ? { display: 'none' } : {} }
                  className={ itemMods.isExpanded ? 'Hidden' : '' }
                  alt={ `Category ${title} thumbnail.` }
                  loading="lazy"
                />
                { this.renderItemContent(item, itemMods) }
            </>
        );
    }
}

export default MenuItemComponent;
