import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import { BLACK_KEY, WHITE_KEY } from 'Component/Icon/Icon.config';
import Link from 'Component/Link';
import PreloaderIcon from 'Component/PreloaderIcon';
import TextPlaceholder from 'Component/TextPlaceholder';

import {
    POSITION_LEADING,
    SIZE_LARGE, TEXT_CENTER,
    TYPE_PRIMARY, WIDTH_FIXED
} from './Button.config';

// import ChevronIcon from 'Component/ChevronIcon';
// import { BOTTOM } from 'Component/ChevronIcon/ChevronIcon.config';
// import ChevronIcon from '../ChevronIcon';
import './Button.style';
/** @namespace Bodypwa/Component/Button/Component */
export class ButtonComponent extends PureComponent {
    static propTypes = {
        text: PropTypes.string,
        type: PropTypes.string,
        width: PropTypes.string,
        children: PropTypes.element,
        size: PropTypes.string,
        isIconSmall: PropTypes.bool,
        isIcon: PropTypes.bool,
        isLoading: PropTypes.bool,
        isSkeleton: PropTypes.bool,
        isFocus: PropTypes.bool,
        isNoBorder: PropTypes.bool,
        isNoBackground: PropTypes.bool,
        position: PropTypes.string,
        isDisabled: PropTypes.bool,
        onClick: PropTypes.func,
        actionType: PropTypes.string,
        isLink: PropTypes.bool,
        goTo: PropTypes.string,
        alignContent: PropTypes.string,
        isActive: PropTypes.string,
        customClass: PropTypes.string,
        isSelected: PropTypes.bool,
        onMouseEnterNext: PropTypes.func,
        onMouseLeaveNext: PropTypes.func,
        onMouseEnterPrev: PropTypes.func,
        onMouseLeavePrev: PropTypes.func,
        isHideNext: PropTypes.bool,
        isHidePrev: PropTypes.bool,
        btnImage: PropTypes.element,
        id: PropTypes.string
    };

    static defaultProps = {
        text: '',
        type: TYPE_PRIMARY,
        width: WIDTH_FIXED,
        children: null,
        size: SIZE_LARGE,
        isIconSmall: false,
        isIcon: false,
        isLoading: false,
        isSkeleton: false,
        isFocus: false,
        isNoBorder: false,
        isNoBackground: false,
        position: POSITION_LEADING,
        isDisabled: false,
        onClick: () => {},
        actionType: 'submit',
        isLink: false,
        goTo: '',
        alignContent: TEXT_CENTER,
        isActive: false,
        customClass: '',
        isSelected: false,
        onMouseEnterNext: () => {},
        onMouseLeaveNext: () => {},
        onMouseEnterPrev: () => {},
        onMouseLeavePrev: () => {},
        isHideNext: false,
        isHidePrev: false,
        btnImage: null,
        id: ''
    };

    colors = {
        tertiary: BLACK_KEY,
        secondary: WHITE_KEY,
        primary: BLACK_KEY
    };

    renderButtonContent() {
        const {
            text,
            children,
            isLoading,
            btnImage,
            type
        } = this.props;

        if (isLoading) {
            return (
                <PreloaderIcon color={ this.colors[type] } />
            );
        }

        return (
            <>
                { btnImage || '' }
                { children }
                { text }
            </>
        );
    }

    render() {
        const {
            text,
            type,
            width,
            size,
            isIconSmall,
            isIcon,
            isLoading,
            isSkeleton,
            isFocus,
            isNoBorder,
            isNoBackground,
            position,
            isDisabled,
            onClick,
            actionType,
            isLink,
            goTo,
            alignContent,
            isActive,
            isSelected,
            customClass,
            onMouseEnterNext,
            onMouseLeaveNext,
            onMouseEnterPrev,
            onMouseLeavePrev,
            isHideNext,
            isHidePrev,
            id
        } = this.props;

        if (isSkeleton) {
            return (
                <div className="button-placeholder">
                    <TextPlaceholder height="button" length="button" />
                </div>
            );
        }

        if (isLink) {
            return (
                <Link
                  block="Button"
                  mods={ {
                      type,
                      width,
                      size,
                      isIconSmall,
                      isIcon,
                      isNoBorder,
                      isNoBackground,
                      position: text ? position : false
                  } }
                  to={ goTo }
                  onClick={ onClick }
                >
                      { this.renderButtonContent() }
                      { /* { this.renderWithImage() } */ }
                </Link>
            );
        }

        return (
            <button
              block="Button"
              type={ actionType }
              id={ id }
              mods={ {
                  type,
                  width,
                  size,
                  isIconSmall,
                  isIcon,
                  isFocus,
                  isLoading,
                  isSkeleton,
                  isLoadingType: isLoading ? type : false,
                  isDisabled,
                  isNoBorder,
                  alignContent,
                  isActive,
                  isSelected,
                  isHideNext,
                  isHidePrev,
                  isNoBackground,
                  position: text ? position : false
              } }
              disabled={ isDisabled || isLoading }
              onClick={ onClick }
              onMouseEnterNext={ onMouseEnterNext }
              onMouseLeaveNext={ onMouseLeaveNext }
              onMouseEnterPrev={ onMouseEnterPrev }
              onMouseLeavePrev={ onMouseLeavePrev }
              className={ customClass }
            >
                { this.renderButtonContent() }
            </button>
        );
    }
}
export default ButtonComponent;
