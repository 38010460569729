import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import { EventsType, FieldAttrType } from 'Type/Field.type';
import { DEFAULT_MAX_PRODUCTS } from 'Util/Product/Extract';

import FieldNumberComponent from './FieldNumber.component';
import { LARGE_SIZE, SMALL_SIZE } from './FieldNumber.config';

/** @namespace Bodypwa/Component/FieldNumber/Container */
export class FieldNumberContainer extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        isReadOnly: PropTypes.bool,
        size: PropTypes.oneOf([SMALL_SIZE, LARGE_SIZE]),
        isLoading: PropTypes.bool
    };

    static defaultProps = {
        size: LARGE_SIZE,
        isReadOnly: false,
        isLoading: false
    };

    state = {
        value: 0
    };

    fieldRef = createRef();

    componentDidMount() {
        const { attr: { defaultValue = 0 } } = this.props;
        this.handleInitialLoad(defaultValue);
    }

    componentDidUpdate(prevProps) {
        const { attr: { value } = {} } = this.props;
        const { attr: { value: prevValue } = {} } = prevProps;

        if (value !== prevValue) {
            this.setState({ value });
        }
    }

    __construct(props) {
        super.__construct(props);

        this.containerFunctions = {
            handleValueChange: this.handleValueChange.bind(this),
            handleInputChange: this.handleInputChange.bind(this),
            handleInputBlur: this.handleInputBlur.bind(this),
            setRef: this.setRef.bind(this)
        };
    }

    setRef(elem) {
        const { setRef } = this.props;
        setRef(elem);

        if (elem && this.fieldRef.current !== elem) {
            this.fieldRef.current = elem;
        }
    }

    setValue(value) {
        const {
            attr: { min = 0, max = DEFAULT_MAX_PRODUCTS } = {}
        } = this.props;

        const rangedValue = Math.max(min, Math.min(value, max));

        if (this.fieldRef.current) {
            this.fieldRef.current.value = rangedValue;
        }
        this.setState({ value: rangedValue });

        return rangedValue;
    }

    handleInitialLoad(value) {
        const {
            events: { onLoad } = {}
        } = this.props;

        const newValue = this.setValue(value);

        if (typeof onLoad === 'function') {
            onLoad(newValue);
        }
    }

    handleValueChange(value) {
        const {
            events: { onChange } = {}, attr: { name } = {}
        } = this.props;

        const newValue = this.setValue(value);
        if (!newValue && name === 'item_qty') {
            return;
        }
        if (typeof onChange === 'function') {
            onChange(newValue);
        }
    }

    handleInputChange(value) {
        this.setValue(value);
    }

    handleInputBlur(value) {
        const {
            events: { onBlur } = {}, attr: { name, value: oldValue, minQuantity } = {}
        } = this.props;
        const newValue = this.setValue(value || oldValue || minQuantity);

        if (!newValue && name === 'item_qty') {
            if (typeof onBlur === 'function') {
                onBlur(value);
            }

            return;
        }
        if (typeof onBlur === 'function') {
            onBlur(newValue);
        }
    }

    render() {
        const {
            attr: {
                value,
                autoComplete,
                autocomplete,
                ...attr
            } = {},
            events,
            isDisabled,
            isReadOnly,
            isLoading,
            size
        } = this.props;

        const { value: stateValue } = this.state;
        return (
            <FieldNumberComponent
              attr={ {
                  ...attr,
                  value,
                  autoComplete: autoComplete || autocomplete
              } }
              events={ events }
              setRef={ this.setRef }
              isDisabled={ isDisabled }
              value={ stateValue }
              isReadOnly={ isReadOnly }
              isLoading={ isLoading }
              size={ size }
              { ...this.containerFunctions }
            />
        );
    }
}

export default FieldNumberContainer;
