import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/ErrorIcon/Component */
export class ErrorIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color } = this.props;
        return (
          <svg
            width={ dimensions[ size ] }
            height={ dimensions[ size ] }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path d="M13 10L11 10L11 14.5L13 14.5L13 10Z" fill={ colors[ color ] } className="icon-color" />
          <path
            d="M12 15C11.4477 15 11 15.4477 11 16C11 16.5523 11.4477
          17 12 17C12.5523 17 13 16.5523 13 16C13 15.4477 12.5523 15 12 15Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12 2L22 20L2 20L12 2ZM18.601
          18L5.39903 18L12 6.11825L18.601 18Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          </svg>
        );
    }
}
export default ErrorIconComponent;
