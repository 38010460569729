/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 * @link https://github.com/scandipwa/base-theme
 */

import { PaymentMethodsType } from '@scandipwa/scandipwa/src/type/Checkout.type';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import InstantPaymentComponent from './InstantPayment.component';
import { INSTANT_PAYMENT_LOCATION_CHECKOUT } from './InstantPayment.config';

/** @namespace Paypal/Component/InstantPayment/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    payment_methods: state.PaymentMethodsReducer?.methods
});
/** @namespace Paypal/Component/InstantPayment/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Paypal/Component/InstantPayment/Container */
export class InstantPaymentContainer extends PureComponent {
    static propTypes = {
        payment_methods: PaymentMethodsType.isRequired
    };

    render() {
        const {
            payment_methods
        } = this.props;

        if (!payment_methods) {
            return null;
        }

        return (
            <InstantPaymentComponent
              payment_methods={ payment_methods }
              widgetLocation={ INSTANT_PAYMENT_LOCATION_CHECKOUT }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(InstantPaymentContainer);
