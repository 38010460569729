import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import ChevronIcon from 'Component/ChevronIcon';
import { BOTTOM, TOP } from 'Component/ChevronIcon/ChevronIcon.config';
import ClickOutside from 'Component/ClickOutside';
// import TextPlaceholder from 'Component/TextPlaceholder';
import { EventsType, FieldAttrType, FieldOptionsType } from 'Type/Field.type';
import isMobile from 'Util/Mobile';

import { FIX_SIZE, FULL_SIZE } from './FieldSelect.config';

import './FieldSelect.style';

/**
 * Field Select
 * @class FieldSelect
 * @namespace Bodypwa/Component/FieldSelect/Component */
export class FieldSelectComponent extends PureComponent {
    static propTypes = {
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        options: FieldOptionsType.isRequired,
        setRef: PropTypes.func.isRequired,
        isExpanded: PropTypes.bool.isRequired,
        handleSelectListOptionClick: PropTypes.func.isRequired,
        handleSelectListKeyPress: PropTypes.func.isRequired,
        handleSelectExpandedExpand: PropTypes.func.isRequired,
        handleSelectExpand: PropTypes.func.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        size: PropTypes.oneOf([FIX_SIZE, FULL_SIZE])
    };

    static defaultProps = {
        size: FULL_SIZE
    };

    renderNativeOption = (option) => {
        const {
            id,
            value,
            disabled,
            label,
            subLabel = '',
            isPlaceholder
        } = option;

        return (
            <option
              key={ id }
              id={ id }
              value={ value }
              disabled={ disabled && !isPlaceholder }
            >
                { `${label} ${subLabel}` }
            </option>
        );
    };

    renderNativeSelect() {
        const {
            setRef, attr, events, isDisabled, options, handleSelectListOptionClick
        } = this.props;

        const filteredOptions = options.length === 2 ? options.filter((option) => !option.isPlaceholder) : options;

        return (
            <select
              block="FieldSelect"
              elem="Select"
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...attr }
              // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
              { ...events }
              onChange={ handleSelectListOptionClick }
            >
                { filteredOptions.map(this.renderNativeOption) }
            </select>
        );
    }

    renderOption = (option) => {
        const {
            id,
            label,
            subLabel,
            isPlaceholder = false,
            isHovered
        } = option;

        const {
            isExpanded,
            handleSelectListOptionClick
        } = this.props;

        return (
            <li
              block="FieldSelect"
              elem="Option"
              mods={ { isExpanded, isPlaceholder, isHovered } }
              key={ id }
              id={ `o${id}` }
              role="menuitem"
              // eslint-disable-next-line react/jsx-no-bind
              onMouseDown={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onTouchStart={ () => handleSelectListOptionClick(option) }
              // eslint-disable-next-line react/jsx-no-bind
              onKeyPress={ () => handleSelectListOptionClick(option) }
              tabIndex={ isExpanded ? '0' : '-1' }
            >
                { label }
                { subLabel && (
                    <strong>
                        { ` ${subLabel}` }
                    </strong>
                ) }
            </li>
        );
    };

    renderOptions() {
        const {
            options,
            isExpanded,
            size
        } = this.props;

        // Filtrăm opțiunile dacă există doar două și una este placeholder
        const filteredOptions = options.length === 2 ? options.filter((option) => !option.isPlaceholder) : options;
        return (
            <ul
              block="FieldSelect"
              elem="Options"
              role="menu"
              mods={ { isExpanded, size } }
            >
                { filteredOptions.map(this.renderOption) }
            </ul>
        );
    }

    render() {
        const {
            attr: { id = '' } = {},
            isExpanded,
            handleSelectExpand,
            handleSelectListKeyPress,
            handleSelectExpandedExpand,
            options,
            size
        } = this.props;

        const hasOneOption = options.length === 2 && options[0].isPlaceholder;
        const chevron = hasOneOption ? '' : <ChevronIcon direction={ isExpanded && !isMobile.any() ? TOP : BOTTOM } />;

        return (
            <ClickOutside onClick={ handleSelectExpandedExpand }>
                <div
                  id={ `${id}_wrapper` }
                  block="FieldSelect"
                  mods={ { isExpanded } }
                  onClick={ hasOneOption ? false : handleSelectExpand }
                  onKeyPress={ hasOneOption ? false : handleSelectListKeyPress }
                  role="button"
                  tabIndex="0"
                  aria-label="Select dropdown"
                  aria-expanded={ isExpanded }
                >
                    <div
                      block="FieldSelect"
                      elem="Clickable"
                      mods={ { size } }
                    >
                        { this.renderNativeSelect() }
                        { chevron }
                    </div>
                    { this.renderOptions() }
                </div>
            </ClickOutside>
        );
    }
}

export default FieldSelectComponent;
