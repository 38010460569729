/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/AddIcon/Component */
export class AddIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { isPrimary, size, color } = this.props;
        const { dimensions, colors } = this;

        return (

            <svg
              block="AddIcon"
              mods={ { isPrimary } }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              d="M10.75 10.7502L10.75 4H13.25L13.25 10.7502L20
            10.7502V13.2502H13.25L13.2501 20H10.7499L10.75 13.2502H4V10.7501L10.75
             10.7502Z"
              fill={ colors[ color ] }
              className="icon-color"
            />
            </svg>
        );
    }
}

export default AddIconComponent;
