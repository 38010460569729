import Image from 'Component/Image';
import Link from 'Component/Link';
import ProductPrice from 'Component/ProductPrice';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    SearchItem as SourceSearchItem
} from 'SourceComponent/SearchItem/SearchItem.component';
import {
    getPrice
} from 'Util/Product/Extract';

import './SearchItem.style';

/** @namespace Bodypwa/Component/SearchItem/Component */
export class SearchItemComponent extends SourceSearchItem {
    renderLink() {
        const { linkTo, onClick } = this.props;

        return (
            <Link
              block="SearchItem"
              elem="Link"
              to={ linkTo }
              onClick={ onClick }
            >
                <figure
                  block="SearchItem"
                  elem="Wrapper"
                >
                    { this.renderImage() }
                    { this.renderContent() }
                </figure>
            </Link>
        );
    }

    renderImage() {
        const {
            product: { name },
            imgSrc,
            image_cover
        } = this.props;

        if (name && !imgSrc) {
            return (
                <div
                  block="SearchItem"
                  elem="Image"
                />
            );
        }

        return (
            <Image
              block="SearchItem"
              mods={ { isImageFitCover: image_cover === '1' } }
              elem="Image"
              src={ imgSrc }
              alt={ __('Product %s thumbnail.', name) }
              isPlaceholder={ !name }
            />
        );
    }

    renderContent() {
        const {
            product: {
                name,
                price_range: priceRange,
                dynamic_price: dynamicPrice,
                type_id
            }
        } = this.props;

        return (
            <figcaption block="SearchItem" elem="Content">
                { this.renderCustomAttribute() }
                <h4 block="SearchItem" elem="Title" mods={ { isLoaded: !!name } }>
                    <TextPlaceholder content={ name } length="long" />
                </h4>
                <ProductPrice
                  price={ getPrice(priceRange, dynamicPrice, {}, type_id) }
                  mods={ { type_id } }
                />
            </figcaption>
        );
    }
}

export default SearchItemComponent;
