// eslint-disable-next-line import/prefer-default-export
export const STORE_CONFIG_KEY = 'config';

export const STORE_SWITCHER_HEADER_ID = 'storeSwitcherHeaderID';

export const STORE_SWITCHER_FOOTER_ID = 'storeSwitcherFooterID';

export const STORE_CONFIG_NAME_MAP = {
    COM: 'com',
    Denmark: 'dk',
    Norway: 'no',
    Germany: 'de',
    Sweden: 'se',
    Netherlands: 'nl',
    France: 'fr',
    Switzerland: 'ch',
    Spain: 'es',
    Portugal: 'pt',
    Italy: 'it',
    Belgium: 'be',
    Austria: 'at',
    Poland: 'pl',
    Finland: 'fi',
    Hungary: 'hu',
    Estonia: 'ee',
    'Czech Republic': 'cz',
    Romania: 'ro',
    Latvia: 'lv',
    Lithuania: 'lt',
    USA: 'us',
    'United Kingdom': 'gb',
    Ireland: 'ie',
    Europe: 'eu',
    'World Wide': 'ww'
};
