/* eslint-disable no-magic-numbers */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import Button from 'Component/Button';
import { SIZE_NONE, TYPE_TEXT, WIDTH_HUG } from 'Component/Button/Button.config';

import './ScrollSwiper.style';

/** @namespace Bodypwa/Component/ScrollSwiper/Component */
export class ScrollSwiperComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isAutoScroll: PropTypes.bool.isRequired,
        isMobile: PropTypes.bool.isRequired,
        dragElement: PropTypes.func.isRequired
    };

    sliderRef = createRef();

    sliderContianerRef = createRef();

    state = {
        isControls: false,
        interval: false,
        hideNext: false,
        hidePrev: true
    };

    // eslint-disable-next-line react/sort-comp
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidMount() {
        const { dragElement } = this.props;
        const { scrollWidth } = this.sliderRef.current;
        const { offsetWidth: sliderContainerWidth } = this.sliderContianerRef.current;
        this.setState({
            isControls: scrollWidth > sliderContainerWidth,
            scrollWidth,
            sliderContainerWidth
        });

        dragElement(this.sliderRef.current);
        this.setScrollListener();
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidUpdate(prevProps, prevState) {
        const { scrollWidth } = this.sliderRef.current;
        const { offsetWidth: sliderContainerWidth } = this.sliderContianerRef.current;
        this.setState({
            isControls: scrollWidth > sliderContainerWidth
        });

        if (
            scrollWidth !== prevState.scrollWidth
            || sliderContainerWidth !== prevState.sliderContainerWidth
        ) {
            this.setState({
                isControls: scrollWidth > sliderContainerWidth,
                scrollWidth,
                sliderContainerWidth
            });
        }
    }

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentWillUnmount() {
        if (this.sliderRef.current !== null) {
            this.sliderRef.current.removeEventListener('scroll', this.scrollHandler);
        }
    }

    slideNext = (ev, range) => {
        this.sliderRef.current.scroll({
            top: 0,
            left: range
                ? this.sliderRef.current.scrollLeft + range
                : this.sliderRef.current.scrollLeft + this.sliderRef.current.offsetWidth,
            behavior: 'smooth'
        });
    };

    slidePrev = (ev, range) => {
        this.sliderRef.current.scroll({
            top: 0,
            left: range
                ? this.sliderRef.current.scrollLeft - range
                : this.sliderRef.current.scrollLeft - this.sliderRef.current.offsetWidth,
            behavior: 'smooth'
        });
    };

    toggleAutoPlay = (toggle, direction) => {
        const { isAutoScroll, isMobile } = this.props;
        if (isAutoScroll && !isMobile) {
            const { interval } = this.state;
            clearInterval(interval);
            if (toggle) {
                const next = () => {
                    if (direction === 'right') {
                        this.slideNext(null, 15);
                    } else {
                        this.slidePrev(null, 15);
                    }
                };

                this.setState({ interval: setInterval(next, 100) });
            }
        }
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    setScrollListener() {
        const { current: scrollContainer } = this.sliderRef;
        const { isMobile } = this.props;
        const self = this;

        if (scrollContainer === null || isMobile) {
            return;
        }

        this.scrollHandler = () => {
            self.setState({
                hideNext:
                    scrollContainer.offsetWidth + scrollContainer.scrollLeft >= scrollContainer.scrollWidth,
                hidePrev: !scrollContainer.scrollLeft
            });
        };

        scrollContainer.addEventListener('scroll', this.scrollHandler, this);
    }

    renderControls = () => {
        const { isControls, hideNext, hidePrev } = this.state;
        if (!isControls) {
            return null;
        }

        return (
            <>
                { !hidePrev && (
                    <Button
                      // eslint-disable-next-line react/jsx-no-bind
                      onMouseEnter={ () => this.toggleAutoPlay(true, 'left') }
                      // eslint-disable-next-line react/jsx-no-bind
                      onMouseLeave={ () => this.toggleAutoPlay(false, 'left') }
                      customClass="swiper-button-prev"
                      onClick={ this.slidePrev }
                      width={ WIDTH_HUG }
                      type={ TYPE_TEXT }
                      size={ SIZE_NONE }
                    />
                ) }
                { !hideNext && (
                    <Button
                      // eslint-disable-next-line react/jsx-no-bind
                      onMouseEnter={ () => this.toggleAutoPlay(true, 'right') }
                      // eslint-disable-next-line react/jsx-no-bind
                      onMouseLeave={ () => this.toggleAutoPlay(false, 'right') }
                      customClass="swiper-button-next"
                      onClick={ this.slideNext }
                      width={ WIDTH_HUG }
                      type={ TYPE_TEXT }
                      size={ SIZE_NONE }
                    />
                ) }
            </>
        );
    };

    render() {
        return (
            <div className="navigation-container">
                <div
                  ref={ this.sliderContianerRef }
                  className="swiper-container swiper-container-initialized swiper-container-horizontal"
                >
                    <div
                      className="swiper-wrapper scroll"
                      ref={ this.sliderRef }
                    >
                        { this.props.children }
                    </div>

                </div>
                { this.renderControls() }
            </div>
        );
    }
}

export default ScrollSwiperComponent;
