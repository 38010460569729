/*
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandiweb/gdpr-scandipwa
 * @author    Reinis Mazeiks <info@scandiweb.com>
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import history from 'Util/History';
import { fetchQuery } from 'Util/Request';

import PrivacyInformationQuery from '../../query/PrivacyInformation.query';
import { PrivacyCheckboxAreaType } from '../../type/Privacy';
import { ALL } from '../../util/Privacy';
import PrivacyConsentCheckboxes from './PrivacyConsentCheckboxes.component';
import { PRIVACY_SETTINGS } from './PrivacyConsentCheckboxes.config';

/** @namespace Gdpr/Component/PrivacyConsentCheckboxes/Container */
export class PrivacyConsentCheckboxesContainer extends PureComponent {
    static propTypes = {
        area: PrivacyCheckboxAreaType.isRequired,
        updateRegistrationSelection: PropTypes.func,
        updateSelection: PropTypes.func,
        onSaveConsents: PropTypes.func
    };

    static defaultProps = {
        updateRegistrationSelection: () => {},
        updateSelection: () => {},
        onSaveConsents: null
    };

    containerFunctions = {
        isBoxAccepted: this.isBoxAccepted.bind(this),
        setBoxAccepted: this.setBoxAccepted.bind(this)
    };

    state = {
        consents: {},
        privacyPolicy: '',
        isLoading: true,
        acceptStatus: {},
        // Set default state to true to accept policy by default as it is not needed
        isSatisfied: false,
        isDisabled: false
    };

    componentDidMount() {
        this.requestPrivacyInformation();
        this.updateValidationForRegisteredUser();
        this.updateParent();
    }

    componentDidUpdate() {
        this.updateValidation();
        this.updateValidationForRegisteredUser();
        this.updateParent();
    }

    containerProps = () => {
        const {
            isSatisfied, acceptStatus, privacyPolicy, isDisabled, isLoading
        } = this.state;
        const { onSaveConsents } = this.props;

        return ({
            isSatisfied,
            acceptStatus,
            areaConsents: this.getCurrentConsents(),
            privacyPolicy,
            isDisabled,
            isLoading,
            onSaveConsents
        });
    };

    setBoxAccepted(code, value) {
        this.setState(({ acceptStatus }) => ({
            acceptStatus: {
                ...acceptStatus,
                [code]: value
            }
        }));
    }

    isBoxAccepted(code) {
        const { acceptStatus } = this.state;
        const setValue = acceptStatus[code];

        if (typeof setValue !== 'undefined') {
            return setValue;
        }

        return this.getCurrentConsents()
            .find(({ code: item }) => item === code).isAgreed;
    }

    updateValidationForRegisteredUser() {
        const { location: { pathname } } = history;

        if (pathname.includes(PRIVACY_SETTINGS)) {
            const { isSatisfied: oldIsSatisfied } = this.state;
            const consents = this.getCurrentConsents();

            const isSatisfied = consents
                .filter(({ isRequired }) => isRequired)
                .every(({ code }) => this.isBoxAccepted(code));

            if (isSatisfied !== oldIsSatisfied) {
                this.setState({ isSatisfied: true });
            }
        }
    }

    updateValidation() {
        const { isSatisfied: oldIsSatisfied } = this.state;
        const consents = this.getCurrentConsents();
        const isSatisfied = consents
            .filter(({ isRequired }) => isRequired)
            .every(({ code }) => this.isBoxAccepted(code));

        if (isSatisfied !== oldIsSatisfied) {
            // Set default state to true to accept policy by default as it is not needed
            this.setState({ isSatisfied });
        }
    }

    getCurrentConsents() {
        const { area } = this.props;
        const { consents } = this.state;

        if (area !== ALL) {
            return consents[area] || [];
        }

        const all = Object.values(consents)
            .reduce(
                (a, b) => [...a, ...b],
                []
            );

        // make unique
        return all.filter(
            ({ code }, i) => all.findIndex(({ code: other }) => other === code) === i
        );
    }

    updateParent() {
        const { acceptStatus: { privacy_checkbox } } = this.state;
        const { isSatisfied, acceptStatus } = this.state;
        const { updateRegistrationSelection, updateSelection } = this.props;

        if (privacy_checkbox || isSatisfied) {
            updateRegistrationSelection(
                acceptStatus,
                isSatisfied
            );
        }

        updateSelection(
            acceptStatus,
            isSatisfied
        );
    }

    requestPrivacyInformation() {
        this.setState(
            { isLoading: true },
            () => {
                fetchQuery(PrivacyInformationQuery.getQuery())
                    .then(
                        /** @namespace Gdpr/Component/PrivacyConsentCheckboxes/Container/PrivacyConsentCheckboxesContainer/requestPrivacyInformation/setState/catch/fetchQuery/then */
                        ({ getPrivacyInformation: { consents, privacyPolicy } }) => {
                            if (consents === null) {
                                this.setState({
                                    isLoading: false,
                                    isDisabled: true
                                });
                            } else {
                                this.setState({
                                    isLoading: false,
                                    consents,
                                    privacyPolicy
                                });
                            }
                        },
                        /** @namespace Gdpr/Component/PrivacyConsentCheckboxes/Container/PrivacyConsentCheckboxesContainer/requestPrivacyInformation/setState/catch/fetchQuery/then/catch */
                        (e) => console.error(e)
                    );
            }
        );
    }

    render() {
        return (
            <PrivacyConsentCheckboxes
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default PrivacyConsentCheckboxesContainer;
