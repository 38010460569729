import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/InfoIcon/Component */
export class InfoIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color } = this.props;

        return (
          <svg
            width={ dimensions[ size ] }
            height={ dimensions[ size ] }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path
            d="M10.75 16V11H10V10H14V11H13.25V16H14V17H10V16H10.75Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          <path
            d="M10.75 7.75C10.75 8.44036 11.3096 9 12 9C12.6904 9 13.25 8.44036 13.25 7.75C13.25
          7.05964 12.6904 6.5 12 6.5C11.3096 6.5 10.75 7.05964 10.75 7.75Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2
          17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM19.5 12C19.5 16.1421 16.1421 19.5
          12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786
          19.5 12Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          </svg>
        );
    }
}

export default InfoIconComponent;
