import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionContainer as SourceCheckoutDeliveryOptionContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.container';

/** @namespace Bodypwa/Component/CheckoutDeliveryOption/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state)
    // TODO extend mapStateToProps
});

/** @namespace Bodypwa/Component/CheckoutDeliveryOption/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
    // TODO extend mapDispatchToProps
});

/** @namespace Bodypwa/Component/CheckoutDeliveryOption/Container */
export class CheckoutDeliveryOptionContainer extends SourceCheckoutDeliveryOptionContainer {
    containerProps() {
        const {
            isSelected,
            getCartShippingItemPrice,
            getCartShippingItemSubPrice,
            option = {},
            totals: {
                quote_currency_code
            },
            shippingCountryId
        } = this.props;

        return {
            isSelected,
            option,
            optionPrice: getCartShippingItemPrice(option),
            optionSubPrice: getCartShippingItemSubPrice(option),
            currency: quote_currency_code,
            shippingCountryId
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionContainer);
