/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import {
//     MyAccountAddressTable as SourceMyAccountAddressTable
// } from 'SourceComponent/MyAccountAddressTable/MyAccountAddressTable.component';
import PropTypes from 'prop-types';

import Button from 'Component/Button';
import { TYPE_TERTIARY, WIDTH_FULL } from 'Component/Button/Button.config';
import { ResetButton as SourceResetButton } from 'SourceComponent/ResetButton/ResetButton.component';
import { MixType } from 'Type/Common.type';
import { scrollToTop } from 'Util/Browser';

/** @namespace Bodypwa/Component/ResetButton/Component */
export class ResetButtonComponent extends SourceResetButton {
    static propTypes = {
        mix: MixType,
        resetFilters: PropTypes.func.isRequired,
        onClick: PropTypes.func.isRequired,
        isContentFiltered: PropTypes.bool.isRequired
    };

    static defaultProps = {
        mix: {}
    };

    onClick = () => {
        const { onClick, resetFilters } = this.props;

        onClick();
        resetFilters();
        scrollToTop();
    };

    render() {
        const { mix, isContentFiltered } = this.props;

        if (!isContentFiltered) {
            return null;
        }

        return (
            <div
              block="ResetButton"
              mix={ mix }

            >
                <Button
                  onClick={ this.onClick }
                  text={ __('Reset all') }
                  width={ WIDTH_FULL }
                  type={ TYPE_TERTIARY }

                />
            </div>
        );
    }
}

export default ResetButtonComponent;
