import { STORE_CONFIG_NAME_MAP } from 'Component/StoreSwitcher/StoreSwitcher.config';
import {
    StoreItem as SourceStoreItem
} from 'SourceComponent/StoreItem/StoreItem.component';
/** @namespace Bodypwa/Component/StoreItem/Component */
export class StoreItemComponent extends SourceStoreItem {
    render() {
        const {
            item: { label },
            getStoreCode
        } = this.props;

        return (
            <button
              block="StoreItem"
              elem="Item"
              onClick={ getStoreCode }
            >
                <span className={ `fi fi-${STORE_CONFIG_NAME_MAP[label]}` }>
                    <span className="fi-border" />
                    <span className="fi-borderGray" />
                </span>
                <span
                  block="StoreItem"
                  elem="Label"
                >
                { label }
                </span>
            </button>
        );
    }
}

export default StoreItemComponent;
