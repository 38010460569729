import HeartIcon from 'Component/HeartIcon';
import OrderIcon from 'Component/OrderIcon';
import UserIcon from 'Component/UserIcon';
import {
    MY_WISHLIST
} from 'Type/Account.type';

export const SCROLL_DEBOUNCE_DELAY = 10;

export const ROUTE_CHANGE_THRESHOLD = 10;

// eslint-disable-next-line no-magic-numbers
export const STATIC_CONTENT_REFRESH_INTERVAL = 1000 * 60 * 60 * 0.25;

export const HEADER_CMS_BLOCK = 'header_cms';

export const HORIZONTAL = 'horizontal';

export const ACCOUNT_LINKS = {
    isItemsHorizontal: false,
    items: [
        {
            href: '/my-account',
            icon: UserIcon,
            title: __('My Account'),
            name: 'MyAccount'
        },
        {
            href: '/my-account/my-orders',
            icon: OrderIcon,
            title: __('My Orders'),
            name: 'MyOrders'
        },
        {
            href: '/my-account/my-wishlist',
            icon: HeartIcon,
            title: __('Wishlist'),
            name: MY_WISHLIST
        }
    ]
};
