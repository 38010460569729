import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import './SaleCountdown.style.scss';
/** @namespace Bodymod/SaleCountdown/Component/SaleCountdown/Component */
export class SaleCountdownComponent extends PureComponent {
    static propTypes = {
        fieldRef: PropTypes.shape({
            current: PropTypes.instanceOf(Element)
        }).isRequired,
        isDisabled: PropTypes.bool.isRequired,
        remaining: PropTypes.string.isRequired,
        isWidget: PropTypes.bool.isRequired,
        fontSize: PropTypes.string.isRequired,
        fontColor: PropTypes.string.isRequired,
        fontWeight: PropTypes.string.isRequired
    };

    renderTimer() {
        const { remaining } = this.props;
        return <span block="SaleCountdown" elem="Timer">{ remaining }</span>;
    }

    render() {
        const {
            isDisabled,
            remaining,
            isWidget
        } = this.props;

        if ((isDisabled && !isWidget) || !remaining) {
            return null;
        }

        if (isWidget) {
            const {
                fontSize,
                fontColor,
                fontWeight
            } = this.props;

            const widgetStyle = {
                fontSize,
                color: fontColor,
                fontWeight
            };

            return <span style={ widgetStyle }>{ remaining }</span>;
        }

        return (
            <div
              block="SaleCountdown"
              elem="BusinessProduct"
            >
              <div
                block="ProductActions"
                elem="BusinessInfo"
                mods={ { saleCountdown: true } }
              >
                    <span>
                        { __('This offer ends in ') }
                        <span block="SaleCountdown" elem="Timer">{ remaining }</span>
                    </span>
              </div>
            </div>
        );
    }
}

export default SaleCountdownComponent;
