import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import Button from 'Component/Button';
import { SIZE_NONE, TYPE_TEXT, WIDTH_HUG } from 'Component/Button/Button.config';
import { GREY600_KEY, S_KEY } from 'Component/Icon/Icon.config';
import RulerIconComponent from 'Component/RulerIcon';
import { ProductType } from 'Type/ProductList.type';

import './SizeGuideAction.style';

/** @namespace Bodypwa/Component/SizeGuideAction/Component */
export class SizeGuideActionComponent extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        onSizeGuideClick: PropTypes.func.isRequired
    };

    renderSizeGuideAction() {
        const {
            onSizeGuideClick,
            product
        } = this.props;

        if (!product) {
            return null;
        }
        // eslint-disable-next-line max-len,no-magic-numbers
        const excludeFrom = [1359, 1959, 1958, 1976, 1984, 1982, 1983, 1991, 1992, 1993, 1994, 1995];
        const showSizeGuide = excludeFrom.includes(product.type);

        if (showSizeGuide) {
            return null;
        }

        return (
            <Button
              onClick={ onSizeGuideClick }
              type={ TYPE_TEXT }
              size={ SIZE_NONE }
              isIcon
              text={ __('Size Guide') }
              width={ WIDTH_HUG }
            >
                <RulerIconComponent size={ S_KEY } color={ GREY600_KEY } />

            </Button>
        );
    }

    render() {
        return (
            <div block="SizeGuideAction">
                <p>{ __('Select size') }</p>
                { this.renderSizeGuideAction() }
            </div>
        );
    }
}

export default SizeGuideActionComponent;
