import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import Html from 'Component/Html';
import {
    CheckoutDeliveryOption as SourceCheckoutDeliveryOption
} from 'SourceComponent/CheckoutDeliveryOption/CheckoutDeliveryOption.component';
import { formatPrice } from 'Util/Price';

import './CheckoutDeliveryOption.style';
/** @namespace Bodypwa/Component/CheckoutDeliveryOption/Component */
export class CheckoutDeliveryOptionComponent extends SourceCheckoutDeliveryOption {
    renderRate() {
        const {
            option: {
                method_title,
                available
            } = {}
        } = this.props;

        if (!available) {
            return null;
        }

        return (
            <span>
                <strong>
                    { method_title }
                </strong>
            </span>
        );
    }

    renderComments() {
        const {
            option: {
                description
            } = {}
        } = this.props;

        if (!description) {
            return null;
        }
        const formatedDescription = description.replaceAll('span', 'li').replaceAll('<li></li>', '');
        return (
            <ul block="CheckoutDeliveryOption" elem="List">
                <Html content={ formatedDescription } />
            </ul>
        );
    }

    renderRow() {
        const { isSelected } = this.props;
        const comments = isSelected ? this.renderComments() : '';
        return (
            <div
              block="CheckoutDeliveryOption"
              elem="Row"
            >
                { this.renderRate() }
                { this.renderPrice() }
                { this.renderAvailabilityMessage() }
                { comments }
                { this.renderAditionalFields() }
            </div>
        );
    }

    renderAditionalFields() {
        return null;
    }

    renderPrice() {
        const {
            option: {
                available
            } = {}
        } = this.props;

        if (!available) {
            return null;
        }

        return (
            <strong>
                { ` - ${ this.getOptionPrice() }` }
            </strong>
        );
    }

    getOptionPrice = () => {
        const {
            currency,
            optionPrice
        } = this.props;

        return formatPrice(optionPrice, currency);
    };

    render() {
        const {
            option: {
                carrier_title,
                available
            } = {},
            onOptionClick,
            isSelected
        } = this.props;

        return (
            <li
              block="CheckoutDeliveryOption"
              mods={ { isDisabled: !available } }
              className={ isSelected ? 'CheckoutDeliveryOption_selected' : '' }
            >

                <button
                  block="CheckoutDeliveryOption"
                  mods={ { isDisabled: !available } }
                  elem="Button"
                  type="button"
                  onClick={ onOptionClick }
                  disabled={ !available }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ carrier_title }`,
                          name: `option-${ carrier_title }`,
                          checked: !!isSelected
                      } }
                    />
                    { this.renderRow() }
                </button>
            </li>
        );
    }
}

export default CheckoutDeliveryOptionComponent;
