import Html from 'SourceComponent/Html';

/** @namespace PageBuilder/Component/HtmlCode/Component */
export class HtmlCodeComponent extends React.PureComponent {
    render() {
        const {
            BaseHtmlCode
        } = this.props.elements;

        const html = BaseHtmlCode.childEleBag[0];
        return (
            <BaseHtmlCode.Ele>
                { !!html && typeof html === 'string' ? <Html content={ html } /> : html }
            </BaseHtmlCode.Ele>
        );
    }
}

export default HtmlCodeComponent;
