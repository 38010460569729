import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConfigQuery from 'Query/Config.query';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { fetchQuery } from 'Util/Request';
import DataContainer from 'Util/Request/DataContainer';

import SaleCountdown from './SaleCountdown.component';
import { DAYS_IN_HOURS, MILI_SECOND_INTERVAL, MINUTE_IN_SECONDS } from './SaleCountdown.config';

export const ConfigDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Config/Config.dispatcher'
), 2);
/** @namespace Bodymod/SaleCountdown/Component/SaleCountdown/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    campaignCountdownStartDate: state.ConfigReducer.campaign_countdown_start_date,
    campaignCountdownEndDate: state.ConfigReducer.campaign_countdown_end_date,
    campaignCountdownGtmAdjustment: state.ConfigReducer.campaign_countdown_gtm_adjustment,
    pages: state.ProductListReducer.pages

});
/** @namespace Bodymod/SaleCountdown/Component/SaleCountdown/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    updateCountDownSaleConfig: (data) => ConfigDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCountDownSaleConfig(dispatch, data)
    )
});

/** @namespace Bodymod/SaleCountdown/Component/SaleCountdown/Container */
export class SaleCountdownContainer extends DataContainer {
    static propTypes = {
        campaignCountdownStartDate: PropTypes.string.isRequired,
        campaignCountdownEndDate: PropTypes.string.isRequired,
        campaignCountdownGtmAdjustment: PropTypes.string.isRequired,
        widgetCampaignCountdownStartDate: PropTypes.string.isRequired,
        widgetCampaignCountdownEndDate: PropTypes.string.isRequired,
        widgetCampaignCountdownGtmAdjustment: PropTypes.string.isRequired,
        campaignLabel: PropTypes.string.isRequired,
        isWidget: PropTypes.bool,
        fontSize: PropTypes.string,
        fontColor: PropTypes.string,
        fontWeight: PropTypes.string
    };

    static defaultProps = {
        isWidget: false,
        fontSize: '',
        fontColor: '',
        fontWeight: ''
    };

    state = {
        isDisabled: false,
        isLoading: false,
        pathname: ''
    };

    componentDidMount() {
        const { location: { pathname } } = history;
        this._getData();
        this.tick();
        this.interval = setInterval(this.tick.bind(this), MILI_SECOND_INTERVAL);
        this.setState({ pathname });
    }

    componentDidUpdate() {
        const { pathname, remaining, isLoading } = this.state;
        const { location: { pathname: currentPathname } } = history;
        const {
            campaignCountdownStartDate,
            campaignCountdownEndDate,
            campaignCountdownGtmAdjustment,
            campaignLabel
        } = this.props;

        if (pathname !== currentPathname && !remaining) {
            this.setState({ pathname: currentPathname });
            this._getData();
            this.tick();
            this.interval = setInterval(this.tick.bind(this), MILI_SECOND_INTERVAL);
        }

        if (pathname !== currentPathname && !isLoading) {
            this._getData();
            this.setState({ pathname: currentPathname });
        }

        this.setState({
            isDisabled: !(campaignCountdownStartDate
            && campaignCountdownEndDate && campaignCountdownGtmAdjustment && campaignLabel)
        });
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    dateBetween = (startDate, endDate) => {
        const second = MILI_SECOND_INTERVAL;
        const minute = second * MINUTE_IN_SECONDS;
        const hour = minute * MINUTE_IN_SECONDS;
        const day = hour * DAYS_IN_HOURS;
        const distance = endDate - startDate;

        if (distance <= 0 || `${distance}` === 'NaN') {
            return false;
        }

        const days = Math.floor(distance / day);
        const hours = Math.floor((distance % day) / hour);
        const minutes = Math.floor((distance % hour) / minute);
        const seconds = Math.floor((distance % minute) / second);

        const between = [];

        if (days > 0) {
            between.push(`${days}d`);
        }

        if (hours > 0) {
            between.push(`${hours}h`);
        }

        between.push(`${minutes}m`);

        between.push(`${seconds}s`);

        return between.join(' ');
    };

    tick() {
        const {
            campaignCountdownEndDate,
            campaignCountdownGtmAdjustment,
            widgetCampaignCountdownStartDate,
            widgetCampaignCountdownEndDate,
            widgetCampaignCountdownGtmAdjustment,
            campaignCountdownStartDate
        } = this.props;
        const startDate = new Date();
        // eslint-disable-next-line max-len
        const campaignStartDate = new Date(`${widgetCampaignCountdownStartDate?.replace(/\s/, 'T') || campaignCountdownStartDate?.replace(/\s/, 'T')}${widgetCampaignCountdownGtmAdjustment || campaignCountdownGtmAdjustment}`);
        // eslint-disable-next-line max-len
        const endDate = new Date(`${widgetCampaignCountdownEndDate?.replace(/\s/, 'T') || campaignCountdownEndDate?.replace(/\s/, 'T')}${widgetCampaignCountdownGtmAdjustment || campaignCountdownGtmAdjustment}`);

        const remaining = this.dateBetween(startDate, endDate);
        const distance = startDate - campaignStartDate;
        if (`${distance}` === 'NaN' || distance <= 0) {
            this.setState({ remaining: '' });
            window.clearInterval(this.interval);
        } else {
            if (remaining === false) {
                window.clearInterval(this.interval);
            }

            this.setState({
                remaining: remaining || ''
            });
        }
    }

    containerProps = () => {
        const {
            campaignCountdownStartDate,
            campaignCountdownEndDate,
            campaignCountdownGtmAdjustment,
            isWidget,
            fontSize,
            fontColor,
            fontWeight
        } = this.props;

        const { remaining, isDisabled } = this.state;
        return {
            campaignCountdownStartDate,
            campaignCountdownEndDate,
            campaignCountdownGtmAdjustment,
            isWidget,
            remaining,
            isDisabled,
            fontSize,
            fontColor,
            fontWeight
        };
    };

    async _getData() {
        const { isWidget } = this.props;

        if (isWidget) {
            try {
                const {
                    storeConfig
                } = await fetchQuery(ConfigQuery.getSaleCountdownQuery());

                this.updateCountDown(storeConfig);

                return storeConfig;
            } catch (error) {
                return null;
            }
        }

        return null;
    }

    updateCountDown(data) {
        const { updateCountDownSaleConfig } = this.props;
        const { campaign_countdown_start_date, campaign_countdown_end_date, campaign_countdown_gtm_adjustment } = data;
        updateCountDownSaleConfig({
            campaign_countdown_start_date, campaign_countdown_end_date, campaign_countdown_gtm_adjustment
        });
        this.setState({ isLoading: false });
    }

    render() {
        return (
            <SaleCountdown
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SaleCountdownContainer);
