/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable max-lines */
/* eslint-disable react/boolean-prop-naming */
/* eslint-disable spaced-comment,@scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

// import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import CheckMarkFilledIcon from 'Component/CheckMarkFilledIcon';
import { FieldNumberContainer } from 'Component/FieldNumber/FieldNumber.container';
import FieldSelectContainer from 'Component/FieldSelect/FieldSelect.container';
import LockIcon from 'Component/LockIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import { MixType } from 'Type/Common.type';
import {
    EventsType,
    FieldAttrType,
    LabelType,
    OptionType,
    ValidationRuleType
} from 'Type/Field.type';
import { noopFn } from 'Util/Common';

import { GREEN_KEY, M_KEY, XS_KEY } from '../Icon/Icon.config';
import {
    FIELD_TYPE, FIX_SIZE,
    FULL_SIZE
} from './Field.config';

import './Field.style';

/**
 * Field
 * @class Field
 * @namespace Bodypwa/Component/Field/Component */
export class FieldComponent extends PureComponent {
    static propTypes = {
        // Field attributes
        type: PropTypes.oneOf(Object.values(FIELD_TYPE)).isRequired,
        attr: FieldAttrType.isRequired,
        events: EventsType.isRequired,
        isDisabled: PropTypes.bool.isRequired,
        setRef: PropTypes.func.isRequired,
        mix: MixType.isRequired,
        options: PropTypes.arrayOf(OptionType).isRequired,
        isSkeletonCheckBoxOrRadio: PropTypes.bool.isRequired,
        isDisabledSelected: PropTypes.bool.isRequired,
        isReadOnly: PropTypes.bool.isRequired,
        size: PropTypes.oneOf([FIX_SIZE, FULL_SIZE]),
        isToggle: PropTypes.bool,
        validationRule: ValidationRuleType,

        isChecked: PropTypes.bool.isRequired,
        // Validation
        showErrorAsLabel: PropTypes.bool.isRequired,
        validationResponse: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.object
        ]),

        // Labels
        label: LabelType.isRequired,
        subLabel: PropTypes.string.isRequired,
        isLoading: PropTypes.bool,
        // Placeholder
        placeholder: PropTypes.string
    };

    static defaultProps = {
        validationResponse: null,
        isLoading: false,
        placeholder: '',
        size: FULL_SIZE,
        isToggle: false,
        validationRule: {}
    };

    renderMap = {
        // Checkboxes & Radio
        [FIELD_TYPE.radio]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.checkbox]: this.renderCheckboxOrRadio.bind(this),
        [FIELD_TYPE.multi]: this.renderCheckboxOrRadio.bind(this),

        // Default input
        [FIELD_TYPE.email]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.text]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.time]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.dateTime]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.date]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.password]: this.renderDefaultInput.bind(this),
        [FIELD_TYPE.submit]: this.renderDefaultInput.bind(this),

        // Custom fields
        [FIELD_TYPE.select]: this.renderSelect.bind(this),
        [FIELD_TYPE.textarea]: this.renderTextArea.bind(this),
        [FIELD_TYPE.button]: this.renderButton.bind(this),
        [FIELD_TYPE.number]: this.renderNumber.bind(this)

    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);
        this.state = {
            focused: false,
            value: '',
            errorMessage: '',
            isValidEmail: false,
            isValid: true,
            showError: false
        };
    }

    //#region INPUT TYPE RENDER
    renderDefaultInput() {
        const {
            type,
            setRef,
            attr,
            attr: { id },
            events,
            isDisabled,
            validationResponse,
            isReadOnly,
            size,
            placeholder,
            isLoading
        } = this.props;

        if (isLoading) {
            return <TextPlaceholder height="button" length="button" />;
        }

        return (
            <div
              block="FieldInput"
              elem="InputContainer"
              mods={ { size } }
            >
                <input
                  ref={ (elem) => setRef(elem) }
                  disabled={ isDisabled }
                  type={ type }
                  readOnly={ isReadOnly }
                  placeholder={ placeholder }
                  { ...attr }
                  { ...events }
                />
                { this.renderLabel() }
                { validationResponse === true && id !== 'couponCode' && (
                    <div block="FieldInput" elem="ValidIcon">
                        <CheckMarkFilledIcon
                          size={ M_KEY }
                          color={ GREEN_KEY }
                        />
                    </div>
                ) }
            </div>
        );
    }

    // Renders fields label above field
    renderLabel() {
        const { type, label, attr: { name } = {} } = this.props;

        if (!label) {
            return null;
        }

        return (
            <div block="Field" elem="LabelContainer">
                <label
                  htmlFor={ name || `input-${type}` }
                  block="FieldInput"
                  elem="Label"
                >
                    { label }
                    { this.renderRequiredTag() }
                </label>
            </div>
        );
    }

    renderNumber() {
        const {
            attr,
            events,
            setRef,
            isDisabled = false,
            size,
            validationRule
        } = this.props;

        return (
            <FieldNumberContainer
              attr={ attr }
              events={ events }
              setRef={ setRef }
              isDisabled={ isDisabled }
              size={ size }
              validationRule={ validationRule }
            />
        );
    }

    renderSelect() {
        const {
            attr,
            events,
            setRef,
            options,
            isReadOnly,
            isDisabled,
            isLoading
        } = this.props;

        return (
            <FieldSelectContainer
              attr={ attr }
              events={ events }
              options={ options }
              setRef={ setRef }
              isDisabled={ isDisabled }
              isReadOnly={ isReadOnly }
              isLoading={ isLoading }
            />
        );
    }

    renderButton() {
        const {
            setRef, attr, events, isDisabled
        } = this.props;
        const { value = __('Submit') } = attr;

        return (
            <button
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
            >
                { value }
            </button>
        );
    }

    renderToggleRequiredIcon() {
        const { validationRule: { isRequired } } = this.props;

        if (isRequired) {
            return <LockIcon size={ XS_KEY } color={ GREEN_KEY } />;
        }

        return null;
    }

    renderCheckboxOrRadio() {
        const {
            type,
            setRef,
            attr,
            attr: { id = '' } = {},
            events: { onChange },
            size,
            events,
            isDisabled,
            label,
            isLoading,
            isToggle,
            validationRule: { isRequired }
        } = this.props;

        const inputEvents = {
            ...events,
            onChange: onChange || noopFn
        };

        if (isLoading) {
            if (isToggle) {
                return (
                 <div block="Field" elem="InputContainer">
                    <TextPlaceholder height="content" length="slider" />
                 </div>
                );
            }

            return (
            <div block="Field" elem="InputContainer" mods={ { isLoading } }>
                <TextPlaceholder height="icon" length="icon" />
                <TextPlaceholder height="paragraph" length="sort" />
            </div>
            );
        }

        return (
            <div block="Field" elem="InputContainer" mods={ { size } }>
                <label
                  htmlFor={ id }
                  block="Field"
                  elem={ isToggle ? 'Toggle' : `${type}Label` }
                  mods={ { size } }
                >
                    <input
                      ref={ setRef }
                      disabled={ isDisabled }
                      type={ type }
                      block="Field"
                      elem="Input"
                      { ...attr }
                      { ...inputEvents }
                    />
                    { isToggle && (
                      <span block="Field" elem="Slider" mods={ { size, isRequired } }>
                        { this.renderToggleRequiredIcon() }
                      </span>
                    ) }
                    { !isToggle && (
                        <>
                            <div block="input-control" mods={ { isDisabled } } />
                            <div block="Field" elem="Label" mods={ { isDisabled } }>
                                { label }
                            </div>
                        </>
                    ) }
                </label>
            </div>
        );
    }

    renderTextArea() {
        const {
            setRef, attr, events, isDisabled
        } = this.props;

        return (
            <textarea
              ref={ (elem) => setRef(elem) }
              disabled={ isDisabled }
              { ...attr }
              { ...events }
            />
        );
    }

    //#region LABEL/TEXT RENDER
    // Renders validation error messages under field
    renderErrorMessage = (message, key) => (
        <div block="Field" elem="ErrorMessage" key={ key }>{ message }</div>
    );

    renderErrorMessages() {
        const {
            showErrorAsLabel,
            validationResponse,
            attr: { name }
        } = this.props;

        if (!showErrorAsLabel || !validationResponse || validationResponse === true) {
            return null;
        }

        const { errorMessages } = validationResponse;

        if (!errorMessages) {
            return null;
        }

        return (
            <div block="Field" elem="ErrorMessages">
                { errorMessages.map((message, index) => this.renderErrorMessage(message, name + index)) }
            </div>
        );
    }

    // Renders * for required fields
    renderRequiredTag() {
        const { validationRule: { isRequired } } = this.props;

        if (!isRequired) {
            return null;
        }

        return (
            <span block="Field" elem="Label" mods={ { isRequired: true } }>
                *
            </span>
        );
    }

    // Renders fields label under field
    renderSubLabel() {
        const { subLabel } = this.props;

        if (!subLabel) {
            return null;
        }

        return (
            <div block="Field" elem="SubLabelContainer">
                <div block="Field" elem="SubLabel">
                    { subLabel }
                </div>
            </div>
        );
    }
    //#endregion

    renderInputContainer(inputRenderer) {
        const { type } = this.props;
        const needsLabelInInput = [
            FIELD_TYPE.email,
            FIELD_TYPE.text,
            FIELD_TYPE.password,
            FIELD_TYPE.date,
            FIELD_TYPE.dateTime,
            FIELD_TYPE.time
        ].includes(type);

        if (needsLabelInInput) {
            return inputRenderer();
        }

        return (
            <>
                { inputRenderer() }
            </>
        );
    }

    render() {
        const { type, validationResponse, mix } = this.props;
        const inputRenderer = this.renderMap[type];

        return (
            <div block="Field" elem="Wrapper" mods={ { type } }>
                <div
                  block="Field"
                  mods={ {
                      type,
                      isValid: validationResponse === true,
                      hasError: validationResponse !== true && Object.keys(validationResponse || {}).length !== 0
                  } }
                  mix={ mix }
                >

                    { this.renderInputContainer(inputRenderer) }
                </div>
                { this.renderErrorMessages() }
                { this.renderSubLabel() }
            </div>
        );
    }
}

export default FieldComponent;
