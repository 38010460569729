import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import smoothscroll from 'smoothscroll-polyfill';

import ScrollSwiperSlide from './ScrollSwiperSlide.component';
/** @namespace Bodypwa/Component/ScrollSwiperSlide/Container */
export class ScrollSwiperSlideContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    componentDidMount() {
        smoothscroll.polyfill();
    }

    containerProps() {
        const { children } = this.props;
        // isDisabled: this._getIsDisabled()
        return {
            children
        };
    }

    render() {
        return (
            <ScrollSwiperSlide
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default ScrollSwiperSlideContainer;
