/* eslint-disable max-lines */
/* eslint-disable guard-for-in */
import { connect } from 'react-redux';

import {
    SELECTEBALE_ATTRIBUTES_ARRAY
} from
'Component/ProductConfigurableAttributes/ProductConfigurableAttributes.config';
import {
    mapDispatchToProps,
    mapStateToProps,
    ProductContainer as SourceProductContainer
} from 'SourceComponent/Product/Product.container';
import fromCache from 'Util/Cache/Cache';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { getNewParameters, getVariantIndex } from 'Util/Product';
import {
    getMaxQuantity,
    getMinQuantity,
    getName,
    getPrice,
    getProductInStock
} from 'Util/Product/Extract';
import { getQueryParam } from 'Util/Url';
import { validateGroup } from 'Util/Validator';

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);

export {
    mapDispatchToProps,
    mapStateToProps
};
/** @namespace Bodypwa/Component/Product/Container */
export class ProductContainer extends SourceProductContainer {
    containerFunctions = {
        addToCart: this.addToCart.bind(this),

        // Used to update entered and selected state values
        updateSelectedValues: this.updateSelectedValues.bind(this),
        setDownloadableLinks: this.setStateOptions.bind(this, 'downloadableLinks'),
        setQuantity: this.setQuantity.bind(this),
        handleInputBlur: this.handleInputBlur.bind(this),
        setAdjustedPrice: this.setAdjustedPrice.bind(this),
        getActiveProductImages: this.getActiveProductImages.bind(this),
        getActiveProduct: this.getActiveProduct.bind(this),
        setActiveProduct: this.updateConfigurableVariant.bind(this),
        getMagentoProduct: this.getMagentoProduct.bind(this),
        setValidator: this.setValidator.bind(this)
    };

    /**
     * Returns currently selected product, differs from prop product, for
     * configurable products, as active product can be one of variants.
     * @returns {*}
     */
    getActiveProduct() {
        const { selectedProduct } = this.state;
        const { product } = this.props;

        return selectedProduct ?? product;
    }

    state = {
        // Used for customizable & bundle options
        enteredOptions: this.setDefaultProductOptions('defaultEnteredOptions', 'enteredOptions'),
        selectedOptions: this.setDefaultProductOptions('defaultSelectedOptions', 'selectedOptions'),

        // Used for downloadable
        downloadableLinks: [],

        quantity: 1,

        // Used to add to the base price a selected option prices
        adjustedPrice: {},

        // Used for configurable product - it can be ether parent or variant
        selectedProduct: null,

        selectedProductImages: null,
        // eslint-disable-next-line react/destructuring-assignment
        parameters: this.props.parameters,
        originalProduct: this.props.product,
        selectebalAttributesArray: SELECTEBALE_ATTRIBUTES_ARRAY
    };

    handleInputBlur(quantity) {
        const activeProduct = this.getActiveProduct();
        const minQuantity = getMinQuantity(activeProduct);
        this.setQuantity(quantity || minQuantity);
    }

    componentDidUpdate(prevProps, prevState) {
        const {
            enteredOptions, selectedOptions, downloadableLinks, originalProduct,
            selectebalAttributesArray,
            selectedProduct
        } = this.state;
        const {
            selectedFilters, isPlp, product, product: { configurable_options, price_range }
        } = this.props;

        const {
            enteredOptions: prevEnteredOptions,
            selectedOptions: prevSelectedOptions,
            downloadableLinks: prevDownloadableLinks
        } = prevState;

        const { product: prevProduct } = prevProps;
        if (
            enteredOptions !== prevEnteredOptions
            || selectedOptions !== prevSelectedOptions
            || downloadableLinks !== prevDownloadableLinks
        ) {
            this.updateAdjustedPrice();
        }
        if (product !== prevProduct && selectedProduct && isPlp) {
            this.setState({
                selectedProduct: { ...selectedProduct, price_range }
            });
        }

        if ((product !== prevProduct && !selectedProduct) || (!selectedProduct && isPlp)) {
            const quantity = ProductContainer.getDefaultQuantity(this.props, this.state);

            if (quantity) {
                this.setQuantity(quantity);
            }

            this.updateSelectedValues();
            if (selectedFilters && Object.keys(product).length) {
                const mainParam = Object.keys(configurable_options).filter(
                    (attribute_code) => SELECTEBALE_ATTRIBUTES_ARRAY.includes(attribute_code)
                )[0];

                if (!selectedFilters[mainParam]) {
                    Object.entries(selectedFilters).map(([key, value]) => {
                        if (selectebalAttributesArray.includes(key)
                         && configurable_options && configurable_options[key]) {
                            return this.updateConfigurableVariant(key, value);
                        }

                        return null;
                    });
                } else {
                    this._computeConfigurableVariantUpdate(configurable_options);
                }
            } else if (!selectedProduct) {
                if (configurable_options) {
                    this._computeConfigurableVariantUpdate(configurable_options);
                }
            }
        }

        if (!originalProduct.sku && product.sku) {
            this.setState({ originalProduct: { ...product } });
        }
    }

    componentWillUnmount() {
        this.setState({
            selectedProduct: null,
            parameters: {}
        });
    }

    _computeConfigurableVariantUpdate(configurable_options) {
        const parameters = {};
        const { product: { variants = [] } } = this.props;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sku = urlParams.get('sku');
        const slectedProductBySku = variants.filter((variant) => variant.sku?.toLowerCase() === sku?.toLowerCase());
        if (slectedProductBySku.length && variants[0]?.id) {
            const { attributes } = slectedProductBySku[0];
            Object.keys(configurable_options).forEach((key) => {
                const value = attributes[key];
                if (value) {
                    parameters[key] = value.attribute_value;
                }
            });

            this.setState({ parameters, selectedProduct: slectedProductBySku[0] });
        } else if (configurable_options && variants[0]?.id) {
            Object.keys(configurable_options).forEach((key) => {
                const value = this._getProductFromUrl(key);
                if (value) {
                    parameters[key] = value;
                }
            });
            const newProductIndex = variants[0]?.attributes ? getVariantIndex(variants, parameters, false) : -1;
            const selectedProduct = newProductIndex !== -1 ? variants[newProductIndex] : null;
            if (Object.keys(parameters).length) {
                this.setState({ parameters, selectedProduct });
            }
        }
    }

    _getProductFromUrl(key) {
        const { location } = history;
        return getQueryParam(key, location) || false;
    }

    /**
     * Updates configurable products selected variant
     * @param key
     * @param value
     */
    updateConfigurableVariant(key, value, isManual) {
        const { parameters: prevParameters, selectedProduct } = this.state;
        const { product: { variants }, isPlp, isSwiper } = this.props;

        const parameters = getNewParameters(prevParameters, key, value);
        // Handle PLP or Swiper case
        if ((isPlp || isSwiper)) {
            if (variants.length) {
                this._handlePlpVariantUpdate(this._getVariantByAttributes(variants, key, value, isManual),
                    parameters, key);
            }

            return;
        }

        const newProduct = Object.keys(parameters).length ? this._getNewProduct(key, value, parameters) : null;
        if (newProduct !== selectedProduct
            && (this.shouldUpdateProductPreview(parameters) || this.shouldUpdateProductPreview(prevParameters))) {
            const updatedParameters = newProduct?.length
                ? { ...parameters, [key]: newProduct[key] }
                : { ...parameters };

            this.setState({
                selectedProduct: newProduct,
                parameters: updatedParameters
            });
        } else if (newProduct) {
            this.setState({ parameters });
        }
    }

    shouldUpdateProductPreview(parameters) {
        const { product: { attributes } } = this.props;

        if (!attributes) {
            return false;
        }

        return SELECTEBALE_ATTRIBUTES_ARRAY.some((key) => key in parameters);
        // ! TODO check with BE, why update_product_preview_image value comes null
        // return Object.keys(parameters).some((key) => attributes[key] && attributes[key].update_product_preview_image);
    }

    _getNewProduct(key, value, parameters) {
        const { selectebalAttributesArray, parameters: prevParameters } = this.state;
        const {
            product: { variants }
        } = this.props;

        const newProductIndex = variants ? getVariantIndex(variants, parameters, true) : -1;
        return newProductIndex !== -1
        && (prevParameters[key] !== value || !selectebalAttributesArray.includes(key)
        || prevParameters !== parameters)
            ? variants[newProductIndex] : null;
    }

    _getVariantByAttributes(variants, attrKey, attrValue, isManual = false) {
        const { selectedFilters } = this.props;
        const { parameters } = this.state;
        // Wehen user manually selects an attribute, we need to check if the selected value is in the selected filters
        if (parameters[attrKey] || !selectedFilters[attrKey]?.filter((val) => val === attrValue).length || isManual) {
            return this._getVariantByValue(variants, attrValue, attrKey);
        }

        const filteredVariants = variants.filter((variant) => Object.entries(selectedFilters).every(([key, values]) => {
            // If the variant doesn't have the key, it doesn't match
            if (!variant[key]) {
                return false;
            }

            // Convert the variant's value to string for comparison
            const variantValue = String(variant[key]);

            // Check if the variant's value is in the selected filter values
            return values.includes(variantValue);
        }));

        if (!filteredVariants.length) {
            return this._getVariantByValue(variants, attrValue, attrKey);
        }

        return filteredVariants;
    }

    _getVariantByValue(variants, value, key) {
        return variants.filter((item) => value.includes(`${item[key]}`));
    }

    _handlePlpVariantUpdate(variantProduct, parameters, key) {
        const {
            product, product: { price_range, configurable_options }
        } = this.props;
        const { parameters: prevParameters } = this.state;

        const mainParam = Object.keys(configurable_options).filter(
            (attribute_code) => SELECTEBALE_ATTRIBUTES_ARRAY.includes(attribute_code)
        )[0];

        const updatedParameters = variantProduct.length
            ? { [mainParam]: parameters[mainParam], [key]: `${variantProduct[0][key]}` }
            : { [mainParam]: parameters[mainParam] };

        if ((prevParameters[key] && updatedParameters[key] === prevParameters[key])) {
            // Handle case when user clicks on the same attribute
            this.setState({
                selectedProduct: null,
                selectedProductImages: product,
                product,
                parameters: {}
            });
        } else {
            // Handle case when user clicks on different attribute
            this.setState({
                selectedProduct: variantProduct[0] ? { ...variantProduct[0], price_range } : product,
                selectedProductImages: variantProduct[0] ? { ...variantProduct[0], price_range } : null,
                product,
                parameters: updatedParameters
            });
        }
    }

    containerProps() {
        const {
            quantity, parameters, adjustedPrice, originalProduct
        } = this.state;
        const {
            product,
            product: { options = [] } = {},
            configFormRef,
            device,
            isWishlistEnabled,
            isPlp
        } = this.props;

        const activeProduct = this.getActiveProduct();
        const magentoProduct = this.getMagentoProduct();
        const {
            price_range: priceRange = {},
            dynamic_price: dynamicPrice = false,
            type_id: type
        } = activeProduct || {};

        const output = {
            inStock: fromCache(getProductInStock, [activeProduct, product]),
            maxQuantity: getMaxQuantity(activeProduct),
            minQuantity: getMinQuantity(activeProduct),
            productName: getName(product),
            productPrice: fromCache(getPrice, [priceRange, dynamicPrice, adjustedPrice, type, options])
        };

        return {
            isWishlistEnabled,
            quantity,
            product,
            configFormRef,
            parameters,
            device,
            magentoProduct,
            originalProduct,
            activeProduct,
            isPlp,
            ...output
        };
    }

    getActiveProductImages() {
        const { selectedProductImages } = this.state;
        const { product } = this.props;

        return selectedProductImages ?? product;
    }

    /**
     * Event that validates and invokes product adding into cart
     * @returns {*}
     */
    async addToCart() {
        this.updateSelectedValues();
        const { product } = this.props;

        const isValid = validateGroup(this.validator);

        if (isValid !== true && !this.filterAddToCartFileErrors(isValid.values)) {
            const { showError } = this.props;
            this.validator.scrollIntoView();
            showError(__('Incorrect or missing options!'));
            return;
        }

        const { addProductToCart, cartId } = this.props;
        const products = this.getMagentoProduct();
        await addProductToCart({
            products, cartProduct: this.getActiveProduct(), product, cartId
        });
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductContainer);
