import { connect } from 'react-redux';

import {
    CheckoutDeliveryOptionsContainer as SourceCheckoutDeliveryOptionsContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/CheckoutDeliveryOptions/CheckoutDeliveryOptions.container';
import {
    setIsCheckoutLoading
} from 'Store/Checkout/Checkout.action';
import componentLoader from 'Util/componentLoader';
/** @namespace Bodypwa/Component/CheckoutDeliveryOptions/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    cartData: state.CartReducer.cartTotals
});

export const CartDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/Cart/Cart.dispatcher'
), 2);
/** @namespace Bodypwa/Component/CheckoutDeliveryOptions/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateCartShippingtData: (cartData, shippingMethod) => CartDispatcher.then(
        ({ default: dispatcher }) => dispatcher.updateCartShippingtData(dispatch, cartData, shippingMethod)
    ),
    setIsCheckoutLoading: (isLoading) => dispatch(setIsCheckoutLoading(isLoading))
});

/** @namespace Bodypwa/Component/CheckoutDeliveryOptions/Container */
export class CheckoutDeliveryOptionsContainer extends SourceCheckoutDeliveryOptionsContainer {
    containerProps() {
        const {
            estimateAddress,
            onShippingMethodSelect,
            onStoreSelect,
            shippingMethods,
            handleSelectDeliveryMethod,
            selectedShippingMethod,
            cartData
        } = this.props;
        const { isShippingMethodPreSelected } = this.state;
        return {
            estimateAddress,
            onShippingMethodSelect,
            onStoreSelect,
            selectedShippingMethod,
            shippingMethods,
            handleSelectDeliveryMethod,
            isShippingMethodPreSelected,
            cartData
        };
    }

    selectShippingMethod(shippingMethod) {
        const {
            onShippingMethodSelect,
            updateCartShippingtData, cartData, setIsCheckoutLoading, selectedShippingMethod: { method_code }
        } = this.props;
        const { isShippingMethodPreSelected } = this.state;
        const { method_code: currentMethodCode } = shippingMethod;
        if (currentMethodCode === method_code) {
            return;
        }

        setIsCheckoutLoading(true);
        if (isShippingMethodPreSelected) {
            this.setState({ isShippingMethodPreSelected: false });
        }
        updateCartShippingtData(cartData, shippingMethod);
        onShippingMethodSelect(shippingMethod);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutDeliveryOptionsContainer);
