/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

import PropTypes from 'prop-types';
import { PureComponent } from 'react';

// import ClickOutside from 'Component/ClickOutside';
import Popup from 'Component/Popup';

import { CookieInfoType } from '../../type/GdprCookie';
import { GDPR_COOKIE_GROUP_POPUP_ID } from './GdprCookieGroupPopup.config';

import './GdprCookieGroupPopup.style.scss';

/** @namespace GdprCookie/Component/GdprCookieGroupPopup/Component */
export class GdprCookieGroupPopupComponent extends PureComponent {
    static propTypes = {
        cookieInfo: CookieInfoType.isRequired,
        handleClosePopup: PropTypes.func.isRequired,
        isCookieBarActive: PropTypes.bool.isRequired
    };

    renderName() {
        const { cookieInfo: { name } } = this.props;

        return (
            <h4
              block="GdprCookieGroupPopup"
              elem="Heading"
            >
                { name }
            </h4>
        );
    }

    renderDescription() {
        const { cookieInfo: { description } } = this.props;

        return (
            <p
              block="GdprCookieGroupPopup"
              elem="Description"
            >
                { description }
            </p>
        );
    }

    renderCookiesTableHeadings() {
        return (
            <tr>
                <th>{ __('Cookie Name') }</th>
                <th>{ __('Cookie Description') }</th>
                <th>{ __('Cookie Lifetime') }</th>
            </tr>
        );
    }

    renderCookiesRows() {
        const { cookieInfo: { cookies = {} } } = this.props;

        if (Object.keys(cookies).length === 0) {
            return null;
        }

        return cookies.reduce(
            (acc, e) => [...acc, this.renderCookie(e)],
            []
        );
    }

    renderCookie(cookie) {
        const { name, description, lifetime } = cookie;

        return (
            <div
              block="GdprCookieGroupPopup"
              elem="Row"
              key={ name }
            >
                <div
                  block="GdprCookieGroupPopup"
                  elem="Column"
                >
                    <h4>{ __('Cookie name') }</h4>
                    { name }
                </div>
                <div
                  block="GdprCookieGroupPopup"
                  elem="Column"
                >
                    <h4>{ __('Cookie description') }</h4>
                    { description }
                </div>
                <div
                  block="GdprCookieGroupPopup"
                  elem="Column"
                >
                    <h4>{ __('Cookie lifetime') }</h4>
                    { lifetime }
                </div>
            </div>
        );
    }

    renderCookiesTable() {
        return (
            <div
              block="GdprCookieGroupPopup"
              elem="Table"
            >
                { this.renderCookiesRows() }
            </div>
        );
    }

    renderActions() {
        const { handleClosePopup } = this.props;

        return (
            <button
              block="GdprCookieGroupPopup"
              elem="Done"
              type="button"
              mix={ { block: 'Button' } }
              onClick={ handleClosePopup }
            >
                { __('Done') }
            </button>
        );
    }

    renderGroupContent() {
        return (
            <>
                { this.renderCookiesTable() }
                { this.renderActions() }
            </>
        );
    }

    render() {
        const { isCookieBarActive } = this.props;

        if (!isCookieBarActive) {
            return null;
        }

        return (
            <Popup
              id={ GDPR_COOKIE_GROUP_POPUP_ID }
              clickOutside
              mix={ { block: 'GdprCookieGroupPopup' } }
            >
                { this.renderGroupContent() }
            </Popup>
        );
    }
}

export default GdprCookieGroupPopupComponent;
