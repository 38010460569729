import PropTypes from 'prop-types';

import {
    Loader as SourceLoader
} from 'SourceComponent/Loader/Loader.component';
import loaderAnimation from 'Style/icons/logos/loader-bodymood.gif';

/** @namespace Bodypwa/Component/Loader/Component */
export class LoaderComponent extends SourceLoader {
    static propTypes = {
        isLoading: PropTypes.bool,
        isButton: PropTypes.bool,
        loaderTxt: PropTypes.string
    };

    static defaultProps = {
        isLoading: true,
        isButton: false,
        loaderTxt: ''
    };

    renderMain() {
        const { loaderTxt } = this.props;
        return (
            <div block="Loader" elem="Main">
                <img src={ loaderAnimation } alt="loader" />
                <p block="Loader" elem="Text">
                    { loaderTxt }
                </p>
            </div>
        );
    }

    renderButton() {
        return (
            <div className="lds-ring">
                <div />
                <div />
                <div />
                <div />
            </div>
        );
    }

    render() {
        const { isLoading, isButton } = this.props;

        if (!isLoading) {
            return null;
        }

        if (isButton) {
            return this.renderButton();
        }

        return (
            <div block="Loader">
                <div block="Loader" elem="Scale">
                    { this.renderMain() }
                </div>
            </div>
        );
    }
}

export default LoaderComponent;
