/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 * @link https://github.com/scandipwa/base-theme
 */

import { CheckoutPayments } from '@scandipwa/scandipwa/src/component/CheckoutPayments/CheckoutPayments.component';
import { PaymentMethodsType } from '@scandipwa/scandipwa/src/type/Checkout.type';
import React, { PureComponent } from 'react';

import { WidgetLocationType } from '../../type/PayPal.type';

/** @namespace Paypal/Component/InstantPayment/Component */
export class InstantPaymentComponent extends PureComponent {
     static propTypes = {
         payment_methods: PaymentMethodsType.isRequired,
         widgetLocation: WidgetLocationType.isRequired
     };

     render() {
         const { payment_methods, widgetLocation } = this.props;
         const { paymentRenderMap } = new CheckoutPayments(this.props);
         const instantMethods = payment_methods.filter((method) => method?.location?.[widgetLocation] === '1');
         const uniqueMethods = instantMethods
             .filter((comppare, i, current) => current.findIndex((elem) => (elem.id === comppare.id)) === i);
         const components = uniqueMethods.map(({ code }) => paymentRenderMap[code]?.call());

         return React.cloneElement(
             <div block="InstantCheckout" />,
             { widgetLocation },
             components
         );
     }
}

export default InstantPaymentComponent;
