import { connect } from 'react-redux';

import DataContainer from 'Util/Request/DataContainer';

import ProductListPageComponent from './ProductListPage.component';

/** @namespace Bodypwa/Component/ProductListPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
    // configuredVariants: state.ProductListReducer.configuredVariants,
    // cachedItems: state.ProductListReducer.cachedItems,
    // pages: state.ProductListReducer.pages
});

/** @namespace Bodypwa/Component/ProductListPage/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({});

/** @namespace Bodypwa/Component/ProductListPage/Container */
export class ProductListPageContainer extends DataContainer {
     containerProps = () => ({
         ...this.props
     });

     render() {
         return (
                 <ProductListPageComponent
                   // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                   { ...this.props }
                 />
         );
     }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductListPageContainer);
