import __ from '@scandipwa/webpack-i18n-runtime/src/util/__';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { SIZE_GUIDE_POPUP_ID } from 'Component/SizeGuidePopup/SizeGuidePopup.config';
import { showPopup } from 'Store/Popup/Popup.action';
import { ProductType } from 'Type/ProductList.type';

import SizeGuideAction from './SizeGuideAction.component';

/** @namespace Bodypwa/Component/SizeGuideAction/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    product: state.ProductReducer ? state.ProductReducer.product : null
});

/** @namespace Bodypwa/Component/SizeGuideAction/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showPopup: (payload) => dispatch(showPopup(SIZE_GUIDE_POPUP_ID, payload))
});

/** @namespace Bodypwa/Component/SizeGuideAction/Container */
export class SizeGuideActionContainer extends PureComponent {
    static propTypes = {
        product: ProductType.isRequired,
        showPopup: PropTypes.func.isRequired,
        // eslint-disable-next-line react/require-default-props
        onSizeGuideClick: PropTypes.func
    };

    containerFunctions = {
        onSizeGuideClick: this.onSizeGuideClick.bind(this)
    };

    onSizeGuideClick() {
        const { showPopup } = this.props;
        showPopup({
            action: SIZE_GUIDE_POPUP_ID,
            title: __('Size Guide')
        });
    }

    containerProps() {
        const { product } = this.props;

        return {
            product
        };
    }

    render() {
        return (
            <SizeGuideAction
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SizeGuideActionContainer);
