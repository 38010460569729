import { PureComponent } from 'react';

/** @namespace Bodypwa/Component/MenuSearchIcon/Component */
export class MenuSearchIconComponent extends PureComponent {
    render() {
        return (
            <svg width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M0 2.5H20V0H0V2.5Z" fill="#070707" />
                <path d="M0 18H20V15.5H0V18Z" fill="#070707" />
                <path d="M0 10.25H20V7.75H0V10.25Z" fill="#070707" />
            </svg>
        );
    }
}

export default MenuSearchIconComponent;
