export const BEFORE_ITEMS_TYPE = 'BEFORE_ITEMS_TYPE';
export const SWITCH_ITEMS_TYPE = 'SWITCH_ITEMS_TYPE';
export const AFTER_ITEMS_TYPE = 'AFTER_ITEMS_TYPE';

// BEFORE_ITEMS_TYPE
export const WELCOME_PAGE = 'WELCOME_PAGE';
export const NOTIFICATION_LIST = 'NOTIFICATION_LIST';
export const NAVIGATION_TABS = 'NAVIGATION_TABS';
export const DEMO_NOTICE = 'DEMO_NOTICE';
export const HEADER = 'HEADER';
export const BREADCRUMBS = 'BREADCRUMBS';
export const NEW_VERSION_POPUP = 'NEW_VERSION_POPUP';
export const PROMOTIONS_TOP_BLOCK = 'PROMOTIONS_TOP_BLOCK';

// SWITCH_ITEMS_TYPE
export const HOME = 'HOME';
export const SEARCH = 'SEARCH';
export const CMS_PAGE = 'CMS_PAGE';
export const CART = 'CART';
export const CHECKOUT = 'CHECKOUT';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const LOGIN = 'LOGIN';
export const ACCOUNT_FORGOT_PASSWORD = 'ACCOUNT_FORGOT_PASSWORD';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const MY_ACCOUNT = 'MY_ACCOUNT';
export const MENU = 'MENU';
export const SHARED_WISHLIST = 'SHARED_WISHLIST';
export const STYLE_GUIDE = 'STYLE_GUIDE';
export const URL_REWRITES = 'URL_REWRITES';

// AFTER_ITEMS_TYPE
export const FOOTER = 'FOOTER';
export const COOKIE_POPUP = 'COOKIE_POPUP';
export const MISC_SCRIPTS = 'MISC_SCRIPTS';
export const SMARTLOOK = 'SMARTLOOK';
export const STIKY_HEADER = 'STIKY_HEADER';

export const HOST = 'bodymod';
