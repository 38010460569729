import PRODUCT_TYPE from 'Component/Product/Product.config';
import {
    ProductPrice as SourceProductPrice
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import './ProductPrice.style';

/** @namespace Bodypwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPrice {
    renderOldPrice() {
        const {
            price: {
                originalPrice: {
                    value: originalPriceValue,
                    valueFormatted: originalPriceFormatted
                } = {}
            } = {},
            discountPercentage,
            isSchemaRequired,
            variantsCount
        } = this.props;

        if (discountPercentage === 0 || originalPriceValue === 0) {
            return null;
        }

        return (
            <span
              block="ProductPrice"
              elem="HighPrice"
              aria-label={ __('Old product price') }
              itemProp={ isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : null }
            >
                { originalPriceFormatted }
            </span>
        );
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label
        } = this.props;

        return (
            <>
                { this.renderPriceWithTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { this.renderSchema() }
                { this.renderOldPrice() }
            </>
        );
    }

    renderPrice(price) {
        const {
            discountPercentage
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'span' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { priceFormatted }
                </span>
            </PriceSemanticElementName>
        );
    }

    renderPriceWithTax(basePrice, taxPrice, label) {
        return (
            <>
                { this.renderPrice(basePrice, label) }
            </>
        );
    }

    render() {
        const {
            price: {
                finalPrice,
                originalPrice,
                finalPrice: {
                    value: finalPriceValue = 0
                } = {}
            } = {},
            priceType,
            isPreview,
            discountPercentage,
            mix
        } = this.props;

        if (!finalPrice || !originalPrice) {
            return this.renderPlaceholder();
        }

        return (
            <div
              block="ProductPrice"
              mods={ { hasDiscount: discountPercentage !== 0, isPreview } }
              mix={ mix }
              aria-label={ `Product price: ${finalPriceValue}` }
            >
                { this.renderDefaultPrice() }
                { priceType !== PRODUCT_TYPE.bundle && this.renderTierPrice() }
            </div>
        );
    }
}

export default ProductPriceComponent;
