import { Suspense } from 'react';

import ClickOutside from 'Component/ClickOutside';
import CloseIcon from 'Component/CloseIcon';
import {
    GREY_KEY, GREY500_KEY, M_KEY, S_KEY
} from 'Component/Icon/Icon.config';
import Loader from 'Component/Loader';
import SearchIcon from 'Component/SearchIcon';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    SearchField as SourceSearchField,
    SearchOverlay
} from 'SourceComponent/SearchField/SearchField.component';
import { scrollToTop } from 'Util/Browser';
import { appendWithStoreCode } from 'Util/Url';

export {
    SearchOverlay
};

/** @namespace Bodypwa/Component/SearchField/Component */
export class SearchFieldComponent extends SourceSearchField {
    clearSearch = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        this.onClearSearchButtonClick(false);
    };

    renderSearchIcons() {
        const { isActive, searchCriteria, size } = this.props;

        return (
            <div block="SearchField" elem="IconsContainer">
                <div
                  block="SearchField"
                  elem="SearchIcon"
                  role="button"
                  tabIndex="0"
                  aria-label={ __('Search') }
                >
                    <SearchIcon size={ S_KEY } color={ GREY_KEY } />
                </div>
                { isActive && searchCriteria && (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                    <div
                      block="SearchField"
                      elem="CloseIcon"
                      mods={ { size } }
                      role="button"
                      tabIndex="0"
                      onClick={ this.clearSearch }
                      aria-label={ __('Close') }
                    >
                        <CloseIcon size={ M_KEY } color={ GREY500_KEY } />
                    </div>
                ) }
            </div>
        );
    }

    onSearchEnterPress = (e) => {
        const { searchCriteria, hideActiveOverlay, onSearchBarChange } = this.props;
        const search = searchCriteria.trim().replace(/\s/g, '+');
        const trimmedSearch = searchCriteria.trim();

        if (e.key === 'Enter' && trimmedSearch !== '') {
            hideActiveOverlay();
            onSearchBarChange({ target: { value: '' } });
            this.searchBarRef.current.blur();
            this.closeSearch();
            scrollToTop();
            history.push(appendWithStoreCode(`/search/${ search }`));
        }
    };

    closeSearch = () => {
        const { onSearchOutsideClick } = this.props;
        onSearchOutsideClick();
    };

    renderOverlayFallback() {
        return <Loader isLoading />;
    }

    renderSearch() {
        const {
            searchCriteria,
            onSearchBarFocus,
            isActive,
            device,
            onClearSearchButtonClick,
            isUrlRedirectLoading,
            size
        } = this.props;

        return (
            <>

            <Loader isLoading={ isUrlRedirectLoading } />
                <div
                  block="SearchField"
                  elem="SearchInnerWrapper"
                  mods={ { size } }
                >
                    <input
                      id="search-field"
                      ref={ this.searchBarRef }
                      block="SearchField"
                      elem="Input"
                      onFocus={ onSearchBarFocus }
                      onChange={ this.handleChange }
                      onKeyDown={ this.onSearchEnterPress }
                      value={ searchCriteria }
                      mods={ { isActive, size } }
                      placeholder={ __('Search') }
                      autoComplete="off"
                      aria-label={ __('Search') }
                    />
                    { this.renderSearchIcons() }
                    <Suspense fallback={ this.renderOverlayFallback() }>
                        <SearchOverlay
                          isHideOverlay={ isUrlRedirectLoading || !device?.isMobile }
                          clearSearch={ onClearSearchButtonClick }
                          searchCriteria={ searchCriteria }
                          mods={ { size } }
                        />
                    </Suspense>
                </div>
            </>
        );
    }

    render() {
        const {
            isVisible,
            isActive,
            isLoading
        } = this.props;

        if (isLoading) {
            return <TextPlaceholder height="button" length="button" />;
        }

        return (
            <div block="SearchField" mods={ { isVisible, isActive } }>
                <ClickOutside onClick={ this.closeSearch }>
                    <div block="SearchField" elem="Wrapper">
                        { this.renderSearch() }
                    </div>
                </ClickOutside>
            </div>
        );
    }
}

export default SearchFieldComponent;
