import { STORE_CONFIG_KEY } from '@scandipwa/scandipwa/src/component/StoreSwitcher/StoreSwitcher.config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import ConfigQuery from 'Query/Config.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    StoreSwitcherContainer as SourceStoreSwitcherContainer
} from 'SourceComponent/StoreSwitcher/StoreSwitcher.container';
import StoresDispatcher from 'Store/Stores/Stores.dispatcher';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import StoreSwitcher from './StoreSwitcher.component';

/** @namespace Bodypwa/Component/StoreSwitcher/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    isOpened: state.StoresReducer.isOpened,
    isLoading: state.StoresReducer.isLoading,
    isComplete: state.StoresReducer.isComplete,
    storeList: state.StoresReducer.storeList
});

/** @namespace Bodypwa/Component/StoreSwitcher/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    toggleStoreSelector: (isOpened) => StoresDispatcher.toggleStoreSelector(isOpened, dispatch),
    storesRequestSent: (isLoading, isComplete) => StoresDispatcher.storesRequestSent(isLoading, isComplete, dispatch),
    setStoreList: (storeList) => StoresDispatcher.setStoreList(storeList, dispatch)
});

/** @namespace Bodypwa/Component/StoreSwitcher/Container */
export class StoreSwitcherContainer extends SourceStoreSwitcherContainer {
    static propTypes = {
        showErrorNotification: PropTypes.func.isRequired,
        storeSwitcherId: PropTypes.string.isRequired,
        currentStoreCode: PropTypes.string
    };

    static defaultProps = {
        currentStoreCode: 'default',
        positionBottom: false
    };

    state = {
        storeList: [],
        storeLabel: ''
    };

    containerProps = () => {
        const {
            currentStoreCode, device, positionBottom, isOpened, storeSwitcherId, storeList
        } = this.props;
        const { storeLabel } = this.state;

        return {
            currentStoreCode,
            device,
            isOpened,
            storeLabel,
            storeList,
            positionBottom,
            storeSwitcherId
        };
    };

    componentDidMount() {
        const { storeList } = this.state;
        if (!storeList.length) {
            this._getStoreList();
        }
    }

    componentDidUpdate(prevProps) {
        const { currentStoreCode } = this.props;
        const { prevStoreCode } = prevProps;
        const { storeLabel } = this.state;

        if (currentStoreCode && (!storeLabel || (prevStoreCode !== currentStoreCode))) {
            this.getCurrentLabel(currentStoreCode);
        }
    }

    _formatStoreList(storeList) {
        return storeList.reduce((acc, {
            code, is_active, base_url, base_link_url, store_group_name, locale
        }) => {
            if (!is_active) {
                return acc;
            }

            return [
                ...acc,
                {
                    id: `store_${ code }`,
                    value: code,
                    storeUrl: base_url,
                    storeLinkUrl: base_link_url,
                    label: store_group_name,
                    locale: locale.replace(/_/g, '-')
                }
            ];
        }, []);
    }

    onStoreSwitcherClick() {
        const {
            isOpened, toggleStoreSelector, storeSwitcherId, menuRefProp
        } = this.props;
        const isActive = {};
        isActive[storeSwitcherId] = !isOpened[storeSwitcherId];
        toggleStoreSelector({ ...isOpened, ...isActive });
        if (menuRefProp) {
            menuRefProp.current.style.overflowY = 'hidden';
        }
    }

    onStoreSwitcherOutsideClick() {
        const {
            toggleStoreSelector, storeSwitcherId, isOpened, menuRefProp
        } = this.props;
        const isActive = {};
        isActive[storeSwitcherId] = false;
        toggleStoreSelector({ ...isOpened, ...isActive });
        if (menuRefProp) {
            menuRefProp.current.style.overflowY = 'auto';
        }
    }

    _handleStoreSelect(storeCode) {
        const { showErrorNotification, menuRefProp } = this.props;
        const { storeList } = this.state;

        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            showErrorNotification(__('This store can not be opened!'));

            return;
        }
        if (menuRefProp) {
            menuRefProp.current.style.overflowY = 'auto';
        }
        BrowserDatabase.deleteItem(STORE_CONFIG_KEY);
        window.location = store.storeLinkUrl;
    }

    _getStoreList() {
        const {
            storesRequestSent, isLoading, isComplete, setStoreList
        } = this.props;

        storesRequestSent(true, false);
        if (isLoading || isComplete) {
            return;
        }
        this.fetchData(
            [ConfigQuery.getStoreListField()],
            ({ storeList }) => {
                storesRequestSent(false, true);
                setStoreList(this._formatStoreList(storeList));
            }
        );
    }

    getCurrentLabel(storeCode) {
        const { storeList } = this.props;

        const store = storeList.find(
            ({ value }) => value === storeCode
        );

        if (!store) {
            return;
        }

        const { label } = store;

        this.setState({ storeLabel: label });
    }

    render() {
        return (
            <StoreSwitcher
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreSwitcherContainer);
