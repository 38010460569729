/* eslint-disable simple-import-sort/sort */
/* eslint-disable max-lines */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable @scandipwa/scandipwa-guidelines/no-jsx-variables */
/* eslint-disable max-len */
/* eslint-disable @scandipwa/scandipwa-guidelines/jsx-no-props-destruction */
import 'react-app-polyfill/ie11';
import 'regenerator-runtime/runtime';
import { Route, Switch } from 'react-router-dom';

import Header from 'Component/Header';
import {
    cloneElement
} from 'react';
import WelcomePage from 'Component/WelcomePage';
import UrlRewrites from 'Route/UrlRewrites';
import {
    Router as SourceRouter
} from 'SourceComponent/Router/Router.component';
import { withCookieScript } from 'Component/CookieScript/CookieScript.component';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import {
    ACCOUNT_FORGOT_PASSWORD,
    AFTER_ITEMS_TYPE,
    BEFORE_ITEMS_TYPE,
    BREADCRUMBS,
    CART,
    CHANGE_PASSWORD,
    CHECKOUT,
    CMS_PAGE,
    CONFIRM_ACCOUNT,
    CREATE_ACCOUNT,
    FOOTER,
    FORGOT_PASSWORD,
    HEADER,
    HOME,
    HOST,
    LOGIN,
    MENU,
    MISC_SCRIPTS,
    MY_ACCOUNT,
    NAVIGATION_TABS,
    NEW_VERSION_POPUP,
    NOTIFICATION_LIST,
    PROMOTIONS_TOP_BLOCK,
    SEARCH,
    STYLE_GUIDE,
    SWITCH_ITEMS_TYPE,
    URL_REWRITES,
    WELCOME_PAGE,
    STIKY_HEADER
} from './Router.config';

/** @namespace Bodypwa/Component/Router/Component/withStoreRegex */
export const withStoreRegex = (path) => window?.storeRegexTextRoute?.concat(path) || path;

export const GdprCookiePage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "gdpr" */ 'Route/GdprCookiePage'), 2);
export const CartPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cart" */ 'Route/CartPage'), 2);
export const Checkout = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "checkout" */ 'Route/Checkout'), 2);
export const CmsPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cms-main" */ 'Route/CmsPage'), 2);
export const HomePage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "cms" */ 'Route/HomePage'), 2);
export const MyAccount = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/MyAccount'), 2);
export const PasswordChangePage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "misc" */ 'Route/PasswordChangePage'), 2);
export const SearchPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "search" */ 'Route/SearchPage'), 2);
export const ConfirmAccountPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "account" */ 'Route/ConfirmAccountPage'), 2);
export const MenuPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "menu-page" */ 'Route/MenuPage'), 2);
export const Footer = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/Footer'), 2);
export const NavigationTabs = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "header-additional" */ 'Component/NavigationTabs'), 2);
export const NewVersionPopup = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NewVersionPopup'), 2);
export const NotificationList = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/NotificationList'), 2);
export const OfflineNotice = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "notice" */ 'Component/OfflineNotice'), 2);
export const CreateAccountPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/CreateAccount'), 2);
export const LoginAccountPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/LoginAccount'), 2);
export const ForgotPasswordPage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/ForgotPassword'), 2);
export const SomethingWentWrong = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "something-went-wrong" */ 'Route/SomethingWentWrong'), 2);
export const StyleGuidePage = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "compare" */ 'Route/StyleGuidePage'), 2);
export const Breadcrumbs = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/Breadcrumbs'), 2);
export const MiscScripts = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */ 'Component/MiscScripts'), 2);
export const StikyHeader = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "header" */ 'Component/StikyHeader'), 2);
export const PromotionsTopBlock = lazyComponentLoader(() => import(/* webpackMode: "lazy", webpackChunkName: "footer" */'Component/PromotionsTopBlock'), 2);

/** @namespace Bodypwa/Component/Router/Component */
export class RouterComponent extends SourceRouter {
    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    componentDidCatch(err, info) {
        this.setState({
            hasError: true,
            errorDetails: { err, info }
        });
    }

    [BEFORE_ITEMS_TYPE] = [
        {
            component: <WelcomePage />,
            position: 5,
            name: WELCOME_PAGE
        },
        {
            component: <NotificationList />,
            position: 10,
            name: NOTIFICATION_LIST
        },
        {
            component: <Header />,
            position: 20,
            name: HEADER
        },
        {
            component: <NavigationTabs />,
            position: 25,
            name: NAVIGATION_TABS
        },
        {
            component: <PromotionsTopBlock />,
            position: 26,
            name: PROMOTIONS_TOP_BLOCK
        },
        {
            component: <Breadcrumbs />,
            position: 30,
            name: BREADCRUMBS
        },
        {
            component: <NewVersionPopup />,
            position: 35,
            name: NEW_VERSION_POPUP
        }
    ];

    [AFTER_ITEMS_TYPE] = [
        {
            component: <Footer />,
            position: 10,
            name: FOOTER
        },
        {
            component: <MiscScripts />,
            position: 25,
            name: MISC_SCRIPTS
        },
        {
            component: <StikyHeader />,
            position: 30,
            name: STIKY_HEADER
        }
    ];

    [SWITCH_ITEMS_TYPE] = [
        {
            component: <Route key="10" path={ withStoreRegex('/') } exact render={ (props) => <HomePage { ...props } /> } />,
            position: 10,
            name: HOME
        },
        {
            component: <Route key="25" path={ withStoreRegex('/search/:query/') } render={ (props) => <SearchPage { ...props } /> } />,
            position: 25,
            name: SEARCH
        },
        {
            component: <Route key="40" path={ withStoreRegex('/page') } render={ (props) => <CmsPage { ...props } /> } />,
            position: 40,
            name: CMS_PAGE
        },
        {
            component: <Route key="50" path={ withStoreRegex('/cart') } exact render={ (props) => <CartPage { ...props } /> } />,
            position: 50,
            name: CART
        },
        {
            component: <Route key="55" path={ withStoreRegex('/checkout/:step?') } render={ (props) => <Checkout { ...props } /> } />,
            position: 55,
            name: CHECKOUT
        },
        {
            component: <Route key="60" path={ withStoreRegex('/:account*/createPassword/') } render={ (props) => <PasswordChangePage { ...props } /> } />,
            position: 60,
            name: CHANGE_PASSWORD
        },
        {
            component: <Route key="61" path={ withStoreRegex('/:account*/create/') } render={ (props) => <CreateAccountPage { ...props } /> } />,
            position: 61,
            name: CREATE_ACCOUNT
        },
        {
            component: <Route key="62" path={ withStoreRegex('/:account*/login/') } render={ (props) => <LoginAccountPage { ...props } /> } />,
            position: 62,
            name: LOGIN
        },
        {
            component: <Route key="63" path={ withStoreRegex('/:account*/forgotpassword/') } render={ (props) => <ForgotPasswordPage { ...props } /> } />,
            position: 63,
            name: ACCOUNT_FORGOT_PASSWORD
        },
        {
            component: <Route key="65" path={ withStoreRegex('/:account*/confirm') } render={ (props) => <ConfirmAccountPage { ...props } /> } />,
            position: 65,
            name: CONFIRM_ACCOUNT
        },
        {
            component: <Route key="70" path={ withStoreRegex('/my-account/:tab?') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 70,
            name: MY_ACCOUNT
        },
        {
            component: <Route key="71" path={ withStoreRegex('/forgot-password') } render={ (props) => <MyAccount { ...props } /> } />,
            position: 71,
            name: FORGOT_PASSWORD
        },
        {
            component: <Route key="80" path={ withStoreRegex('/menu') } render={ (props) => <MenuPage { ...props } /> } />,
            position: 80,
            name: MENU
        },
        {
            component: <Route key="85" path={ withStoreRegex('/cookie-settings') } exact render={ (props) => <GdprCookiePage { ...props } /> } />,
            position: 85
        },
        {
            component: <Route key="84" path={ withStoreRegex('/styleguide') } render={ (props) => <StyleGuidePage { ...props } /> } />,
            position: 84,
            name: STYLE_GUIDE
        },
        {
            component: <Route key="1000" render={ (props) => <UrlRewrites { ...props } /> } />,
            position: 1000,
            name: URL_REWRITES
        }
    ];

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    getSortedItems(type) {
        const getUrl = window.location;
        return this[type].sort(
            (a, b) => a.position - b.position
        ).filter(
            (entry) => {
                if (!entry.component) {
                    // eslint-disable-next-line no-console
                    console.warn('There is an item without a component property declared in main router.');

                    return false;
                }
                if (!getUrl.host.indexOf(HOST) && entry.name === STYLE_GUIDE) {
                    return false;
                }

                return true;
            }
        );
    }

    renderMainItems() {
        const { isBigOffline } = this.props;

        if (!navigator.onLine && isBigOffline) {
            return <OfflineNotice isPage />;
        }

        return (
            <Switch>
                { this.renderComponentsOfType(SWITCH_ITEMS_TYPE) }
            </Switch>
        );
    }

    renderComponentsOfType(type) {
        return this.getSortedItems(type)
            .map(({
                position, component, widget, name
            }) => {
                if (widget) {
                    return component(name);
                }

                return cloneElement(component, { key: position });
            });
    }
}

export default withCookieScript(RouterComponent);
