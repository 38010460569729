/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import { Children, cloneElement } from 'react';

import { useBlockReference, useLayoutUpdate } from '../hooks/useLayoutUpdate';
import { LayoutUpdateType } from '../type/LayoutUpdate.type';
import Widget from './Widget';
/** @namespace LayoutUpdates/Component/LayoutUpdate/LayoutUpdate */
export const LayoutUpdate = ({ layoutUpdate }) => {
    const { instance_id } = layoutUpdate;

    const isRenderLU = useLayoutUpdate(layoutUpdate);
    if (!isRenderLU) {
        return null;
    }

    return (
        <Widget id={ instance_id } />
    );
};

LayoutUpdate.propTypes = {
    layoutUpdate: LayoutUpdateType.isRequired
};

LayoutUpdate.displayName = 'LayoutUpdate';
/** @namespace LayoutUpdates/Component/LayoutUpdate/sortBySortOrder */
export const sortBySortOrder = (array) => array.sort((a, b) => {
    // Ensure the sort_order property exists and is a number, to avoid unexpected behavior
    const orderA = typeof a.sort_order === 'number' ? a.sort_order : Number.MAX_SAFE_INTEGER;
    const orderB = typeof b.sort_order === 'number' ? b.sort_order : Number.MAX_SAFE_INTEGER;

    // Compare the two orders
    return orderA - orderB;
});
/** @namespace LayoutUpdates/Component/LayoutUpdate/LayoutUpdates */
export const LayoutUpdates = ({ blockReference }) => {
    const layoutUpdates = useBlockReference(blockReference);

    return layoutUpdates.map((layoutUpdate) => {
        const { page_id } = layoutUpdate;
        return (
            <LayoutUpdate
              key={ page_id }
              layoutUpdate={ layoutUpdate }
            />
        );
    });
};

LayoutUpdates.propTypes = {
    blockReference: PropTypes.string.isRequired
};

LayoutUpdates.displayName = 'LayoutUpdates';

/** @namespace LayoutUpdates/Component/LayoutUpdate/addBlockReferenceAfter */
export const addBlockReferenceAfter = (blockReference) => (args, callback) => (
    <>
        { callback(...args) }
        <LayoutUpdates blockReference={ blockReference } />
    </>
);

/** @namespace LayoutUpdates/Component/LayoutUpdate/addBlockReferenceBefore */
export const addBlockReferenceBefore = (blockReference) => (args, callback) => {
    if (!callback) {
        return (
            <LayoutUpdates blockReference={ blockReference } />
        );
    }

    return (
        <>
            <LayoutUpdates blockReference={ blockReference } />
            { callback(...args) }
        </>
    );
};

/** @namespace LayoutUpdates/Component/LayoutUpdate/addBlockReferenceBeforeWrapper */
export const addBlockReferenceBeforeWrapper = (blockReference) => (args, callback) => {
    const wrapper = callback(...args);

    if (!wrapper) {
        return addBlockReferenceBefore(blockReference)(args, callback);
    }

    return cloneElement(wrapper, wrapper.props, Children.map(wrapper.props.children, (child, i) => {
        if (i !== 0) {
            return child;
        }

        return (
            <>
                <LayoutUpdates blockReference={ blockReference } />
                { child }
            </>
        );
    }));
};

/** @namespace LayoutUpdates/Component/LayoutUpdate/addBlockReferenceAfterWrapper */
export const addBlockReferenceAfterWrapper = (blockReference) => (args, callback) => {
    const wrapper = callback(...args);

    if (!wrapper) {
        return addBlockReferenceAfter(blockReference)(args, callback);
    }

    return cloneElement(wrapper, wrapper.props, Children.map(wrapper.props.children, (child, i) => {
        if (i !== wrapper.props.children.length - 1) {
            return child;
        }

        return (
            <>
                { child }
                <LayoutUpdates blockReference={ blockReference } />
            </>
        );
    }));
};
