/**
 * Layout Updates compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import SaleCountdown from '@bodymod/sale-countdown/src/component/SaleCountdown';
import { PureComponent } from 'react';

import CmsBlock from 'Component/CmsBlock';

import { WidgetType } from '../../type/Widget.type';

import './Widget.style';

/** @namespace LayoutUpdates/Component/Widget/Component */
export class WidgetComponent extends PureComponent {
    static propTypes = {
        widget: WidgetType.isRequired
    };

    typeToRenderMap = {
        // Map widget types (class names) to their components
        'Magento\\Cms\\Block\\Widget\\Block': this.renderBlockWidget,
        'BodyPWA\\SaleCountDown\\Block\\Widget\\SaleCountDown': this.renderCountDownWidget
    };

    renderWidgetOfType() {
        const { widget, widget: { type } } = this.props;
        const TypeRenderer = this.typeToRenderMap[type];
        if (!TypeRenderer) {
            return null;
        }

        return (
            <TypeRenderer
              widget={ widget }
            />
        );
    }

    renderBlockWidget(widget) {
        const { widget: { params: { block_identifier } } } = widget;

        return (
            <CmsBlock identifier={ block_identifier } />
        );
    }

    renderCountDownWidget(widget) {
        const { widget: { params } } = widget;
        const {
            start_date,
            end_date,
            gmt,
            font_size,
            font_color,
            font_weight
        } = params;

        return (
            <SaleCountdown
              widgetCampaignCountdownStartDate={ start_date }
              widgetCampaignCountdownEndDate={ end_date }
              widgetCampaignCountdownGtmAdjustment={ gmt }
              fontSize={ font_size }
              fontColor={ font_color }
              fontWeight={ font_weight }
              isWidget
            />
        );
    }

    render() {
        return this.renderWidgetOfType();
    }
}

export default WidgetComponent;
