import Button from 'Component/Button';
import { TYPE_TERTIARY } from 'Component/Button/Button.config';
import { CUSTOMER_BILLING_ADDRESS, CUSTOMER_SHIPPING_ADDRESS }
from 'Component/CheckoutAddressForm/CheckoutAddressForm.config';
import {
    CheckoutSuccess as SourceCheckoutSuccess
} from 'SourceComponent/CheckoutSuccess/CheckoutSuccess.component';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';

import './CheckoutSuccess.style';

/** @namespace Bodypwa/Component/CheckoutSuccess/Component */
export class CheckoutSuccessComponent extends SourceCheckoutSuccess {
    renderButtons() {
        return (
            <>
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Button
                      isLink
                      goTo="/my-account/my-orders"
                      text={ __('See order status') }
                    />
                </div>
                <div block="CheckoutSuccess" elem="ButtonWrapper">
                    <Button
                      type={ TYPE_TERTIARY }
                      mods={ { isHollow: true } }
                      isLink
                      goTo="/"
                      text={ __('Back to shopping') }
                    />
                </div>
            </>
        );
    }

    renderAditonalContent() {
        return '';
    }

    render() {
        const { orderID, resetCheckout } = this.props;
        // setIsCheckout();
        BrowserDatabase.deleteItem(CUSTOMER_SHIPPING_ADDRESS);
        BrowserDatabase.deleteItem(CUSTOMER_BILLING_ADDRESS);
        resetCheckout();
        return (
            <div block="CheckoutSuccess">
                <p
                  block="CheckoutSuccess"
                  elem="Subtitle"
                >
                    { __('Your order # is:') }
                    <strong>{ orderID || '' }</strong>
                </p>
                <p
                  block="CheckoutSuccess"
                  elem="Description"
                >
                      { __('We`ll email you an order confirmation with details and tracking info.') }

                </p>
                { this.renderButtons() }
            </div>
        );
    }
}

export default CheckoutSuccessComponent;
