import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MetaContainer as SourceMetaContainer
} from 'SourceComponent/Meta/Meta.container';
import { MetaTitleType } from 'Type/Common.type';
import { shallowEqual } from 'Util/Product';

import Meta from './Meta.component';
/** @namespace Bodypwa/Component/Meta/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    storeList: state.StoresReducer.storeList,
    hreflangs: state.MetaReducer.hreflangs,
    seo_markup: state.MetaReducer.mw_seo_markup
});

/** @namespace Bodypwa/Component/Meta/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch)
});

/** @namespace Bodypwa/Component/Meta/Container */
export class MetaContainer extends SourceMetaContainer {
    static propTypes = {
        default_description: PropTypes.string,
        default_keywords: PropTypes.string,
        default_title: PropTypes.string,
        canonical_url: PropTypes.string,
        title_prefix: PropTypes.string,
        title_suffix: PropTypes.string,
        description: PropTypes.string,
        keywords: PropTypes.string,
        title: MetaTitleType,
        robots: PropTypes.string,
        status_code: PropTypes.string,
        // eslint-disable-next-line react/forbid-prop-types
        hreflangs: PropTypes.array
    };

    static defaultProps = {
        default_description: '',
        default_keywords: '',
        default_title: '',
        canonical_url: '',
        title_prefix: '',
        title_suffix: '',
        description: '',
        keywords: '',
        title: '',
        robots: '',
        status_code: '',
        seo_markup: '',
        hreflangs: []
    };

    shouldComponentUpdate(nextProps) {
        return !shallowEqual(this.props, nextProps);
    }

    containerProps() {
        const {
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
            storeList,
            hreflangs,
            seo_markup
        } = this.props;

        return {
            metadata: this._getMetadata(),
            canonical_url,
            default_title,
            title,
            title_prefix,
            title_suffix,
            storeList,
            hreflangs,
            seo_markup
        };
    }

    _getRobots() {
        const { robots } = this.props;
        return robots;
    }

    render() {
        return (
            <Meta
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MetaContainer);
