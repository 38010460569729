/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/SearchIcon/Component */

export class SearchIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { isActive } = this.props;
        const { size, color } = this.props;
        const { dimensions, colors } = this;

        return (
            <svg
              block="SearchIcon"
              isActive={ isActive }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
                <path
                  block="BodIcon"
                  isActive={ isActive }
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.4188 18.1865C14.9114 19.3249 13.0345 20 11 20C6.02944 20 2 15.9706 2 11C2 6.02944 6.02944 2 11 2C15.9706 2 20 6.02944 20 11C20 13.0345 19.3249 14.9114 18.1865 16.4188L22 20.2322L20.2322 22L16.4188 18.1865ZM17.5 11C17.5 14.5899 14.5899 17.5 11 17.5C7.41015 17.5 4.5 14.5899 4.5 11C4.5 7.41015 7.41015 4.5 11 4.5C14.5899 4.5 17.5 7.41015 17.5 11Z"
                  fill={ colors[ color ] }
                  className="icon-color"
                />
            </svg>

        );
    }
}

export default SearchIconComponent;
