import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/OrderIcon/Component */
export class OrderIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color } = this.props;
        return (
            <svg
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.48181 2H18.5182L21
             6.90024V22H3V6.90024L5.48181 2ZM7.01819 4.4993L6.284
             5.94892H10.75V4.4993H7.01819ZM13.25 4.4993V5.94892H17.716L16.9818
              4.4993H13.25ZM18.5 8.44822H13.25V10.9975H10.75V8.44822H5.5V19.5007H18.5V8.44822Z"
              fill={ colors[ color ] }
              className="icon-color"
            />
            </svg>

        );
    }
}
export default OrderIconComponent;
