import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    ProductAttributeValue as SourceProductAttributeValue
} from 'SourceComponent/ProductAttributeValue/ProductAttributeValue.component';

/** @namespace Bodypwa/Component/ProductAttributeValue/Component */
export class ProductAttributeValueComponent extends SourceProductAttributeValue {
    static propTypes = {
        ...SourceProductAttributeValue.propTypes
    };

    static defaultProps = {
        ...SourceProductAttributeValue.defaultProps
    };

    renderDropdown(value, subLabel) {
        const { isSelected } = this.props;

        return (
            <Field
              type={ FIELD_TYPE.checkbox }
              attr={ {
                  id: value,
                  name: value,
                  defaultChecked: isSelected
              } }
              label={ this.getCheckboxLabel(value, subLabel) }
              mix={ {
                  block: 'ProductAttributeValue',
                  elem: 'Text',
                  mods: { isSelected }
              } }
            />
        );
    }

    renderImageValue(img, label) {
        const { isFormattedAsText, isSelected } = this.props;

        if (isFormattedAsText) {
            return label || __('N/A');
        }

        const style = {
            // stylelint-disable-next-line value-keyword-case
            '--option-is-selected': isSelected ? 1 : 0
        };

        return (
            <>
                <img
                  block="ProductAttributeValue"
                  elem="Image"
                  src={ `/media/attribute/swatch${img}` }
                  alt={ label }
                />
                <data
                  block="ProductAttributeValue"
                  elem="Image-Overlay"
                  value={ label }
                  title={ label }
                  style={ style }
                />
            </>
        );
    }

    render() {
        const {
            getLink,
            attribute,
            isAvailable,
            attribute: { attribute_code, attribute_value },
            mix,
            isFormattedAsText,
            showProductAttributeAsLink,
            isPlp
        } = this.props;

        if (attribute_code && !attribute_value) {
            return null;
        }

        const href = getLink(attribute);
        // Invert to apply css rule without using not()
        const isNotAvailable = isPlp ? !isPlp : !isAvailable;

        if (isFormattedAsText) {
            return (
                <span
                  block="ProductAttributeValue"
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </span>
            );
        }

        if (!showProductAttributeAsLink) {
            return (
                <span
                  block="ProductAttributeValue"
                  mods={ { isNotAvailable } }
                  onClick={ this.clickHandler }
                  onKeyDown={ this.clickHandler }
                  role="link"
                  tabIndex="-1"
                  aria-hidden={ isNotAvailable }
                  mix={ mix }
                >
                    { this.renderAttributeByType() }
                </span>
            );
        }

        return (
            <a
              href={ href }
              block="ProductAttributeValue"
              rel="nofollow"
              mods={ { isNotAvailable } }
              onClick={ this.clickHandler }
              aria-hidden={ isNotAvailable }
              mix={ mix }
            >
                { this.renderAttributeByType() }
            </a>
        );
    }
}

export default ProductAttributeValueComponent;
