import { STAR_EMPTY, STAR_FULL, STAR_HALF_FULL } from 'Component/StarIcon/StarIcon.config';
import {
    ProductReviewRating as SourceProductReviewRating
} from 'SourceComponent/ProductReviewRating/ProductReviewRating.component';

import './ProductReviewRating.style';

/** @namespace Bodypwa/Component/ProductReviewRating/Component */
export class ProductReviewRatingComponent extends SourceProductReviewRating {
    render() {
        const {
            summary,
            code,
            placeholder,
            mix,
            count,
            isHidden = false
        } = this.props;

        const [fullCount, halfFullCount, emptyCount] = this.getStarCounts();
        const ONE_FIFTH_OF_A_HUNDRED = 20;
        const HIDE_RATING_BELOW = 3;
        const ariaText = this.getAriaText(summary, code);
        const rating = parseFloat(summary / ONE_FIFTH_OF_A_HUNDRED).toFixed(1);

        if (placeholder) {
            return this.renderPlaceholder();
        }

        if (rating < HIDE_RATING_BELOW && isHidden) {
            return null;
        }

        return (
            <div
              block="ProductReviewRating"
              title={ `${summary}%` }
              ref={ this.reviewRating }
              aria-label={ ariaText }
              mix={ mix }
              onClick={ this.goToReviews }
              aria-hidden="true"
            >
                { this.renderStar(fullCount, STAR_FULL) }
                { this.renderStar(halfFullCount, STAR_HALF_FULL) }
                { this.renderStar(emptyCount, STAR_EMPTY) }
                <span block="ProductReviewRating" elem="Counter">
                    { count }
                </span>
            </div>
        );
    }

    goToReviews = () => {
        const { reviewSectionRef } = this.props;
        if (reviewSectionRef) {
            const top = reviewSectionRef.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top, behavior: 'smooth' });
        }
    };
}

export default ProductReviewRatingComponent;
