export const HEADER_HEIGHT = 73;
export const TOUCH_TIMEOUT = 10;
export const CURRENT_COUNTRY = 'bmgl';
export const NO_REDIRECT = 'no_r';
export const DOT_COM = '.com';
export const EXCLUDED_DOT_COM_STORES = ['uk', 'sk', 'ie', 'us', 'eu'];
export const COM_SUB_STORE = 'cbsBod';
export const STORE_REDIRECT_POPUP = 'STORE_REDIRECT_POPUP';
export const COOKIE_LIFETIME = 7;
export const HEADER_STORE_SELECTOR = 'HEADER_STORE_SELECTOR';
export const FOOTER_STORE_SELECTOR = 'FOOTER_STORE_SELECTOR';
