import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
    mapDispatchToProps,
    mapStateToProps,
    MenuItemContainer as SourceMenuItemContainer
} from 'SourceComponent/MenuItem/MenuItem.container';
import { DeviceType } from 'Type/Device.type';
import { noopFn } from 'Util/Common';
import media from 'Util/Media';

import { HOVER_TIMEOUT } from './MenuItem.config';

export {
    mapStateToProps,
    mapDispatchToProps
};

/** @namespace Bodypwa/Component/MenuItem/Container */
export class MenuItemContainer extends SourceMenuItemContainer {
    static propTypes = {
        ...SourceMenuItemContainer.propTypes,
        device: DeviceType.isRequired,
        hideIcon: PropTypes.bool,
        itemHidden: PropTypes.bool,
        onSubCategoryHover: PropTypes.func,
        setPromoImage: PropTypes.func
    };

    static defaultProps = {
        ...SourceMenuItemContainer.defaultProps,
        onSubCategoryHover: noopFn,
        setPromoImage: noopFn,
        hideIcon: false,
        itemHidden: false
    };

    handleCategoryHover() {
        const {
            onCategoryHover, item, setPromoImage, activeMenuItemsStack
        } = this.props;
        const { image, title } = item;
        const hoverTimeOut = activeMenuItemsStack.length === 0 ? HOVER_TIMEOUT : 0;

        if (!Object.keys(item.children).length) {
            setPromoImage({ imageSrc: media(image), imageTitle: title });
        } else {
            setPromoImage({});
        }
        this.menuHoverTimeout = setTimeout(() => {
            onCategoryHover(item);
        }, hoverTimeOut);
    }

    handleLinkLeave() {
        const { setPromoImage } = this.props;
        setPromoImage({});
        clearTimeout(this.menuHoverTimeout);
    }

    containerProps() {
        const {
            activeMenuItemsStack,
            isExpandable,
            isLink,
            item,
            itemMods,
            hideIcon,
            itemHidden,
            device
        } = this.props;

        return {
            activeMenuItemsStack,
            isExpandable,
            isLink,
            item,
            itemMods,
            hideIcon,
            itemHidden,
            device
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuItemContainer);
