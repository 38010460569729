import {
    Image as SourceImage
} from 'SourceComponent/Image/Image.component';

import {
    IMAGE_LOADING
} from './Image.config';

/** @namespace Bodypwa/Component/Image/Component */
export class ImageComponent extends SourceImage {
    renderLoader() {
        const { showIsLoading } = this.props;
        const { imageStatus } = this.state;

        if (imageStatus !== IMAGE_LOADING || !showIsLoading) {
            return null;
        }

        return (
            <div block="Image" elem="Loader" />
        );
    }

    renderImageNotSpecified() {
        return (
            <span block="Image" elem="Content" mods={ { noImage: true } }>{ __('Image not specified') }</span>
        );
    }
}

export default ImageComponent;
