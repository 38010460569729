/* eslint-disable max-lines */
import { CHECKOUT_ACCOUNT } from '@scandipwa/scandipwa/src/component/Header/Header.config';
import { connect } from 'react-redux';

import { CATEGORY_FILTER_OVERLAY_ID } from 'Component/CategoryFilterOverlay/CategoryFilterOverlay.config';
import { MENU, SEARCH } from 'Component/Header/Header.config';
import { CUSTOMER_ACCOUNT_OVERLAY_KEY } from 'Component/MyAccountOverlay/MyAccountOverlay.config';
import { CHECKOUT_URL } from 'Route/Checkout/Checkout.config';
import {
    HeaderContainer as SourceHeaderContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps
} from 'SourceComponent/Header/Header.container';
import { setIsCheckout } from 'Store/Checkout/Checkout.action';
import { showNotification } from 'Store/Notification/Notification.action';
import {
    setHeaderElement,
    setHeaderHeight, setHeaderHidden, setHeaderPlaceholderHeight,
    setHeaderTopRef,
    setWindowHeight
} from 'Store/Ui/Ui.action';
import { isSignedIn } from 'Util/Auth';
import { toggleScroll } from 'Util/Browser';
import { checkAppVersionFromHeader } from 'Util/Cache/VersionCheck';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import { appendWithStoreCode } from 'Util/Url';

import Header from './Header.component';
import {
    CART,
    CART_OVERLAY
} from './Header.config';

export const MyAccountDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
), 2);

/** @namespace Bodypwa/Component/Header/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    topPageLinks: state.ConfigReducer.bms_header_links,
    activeStep: state.CheckoutReducer.activeStep,
    headerMenu: state.ConfigReducer.header_menu,
    headerHeight: state.UiReducer.headerHeight,
    headerHidden: state.UiReducer.headerHidden,
    headerPlaceholderHeight: state.UiReducer.headerPlaceholderHeight,
    activeOverlay: state.OverlayReducer.activeOverlay,
    updateScroll: state.UiReducer.updateScroll,
    hideWishlistButton: !state.ConfigReducer.wishlist_general_active

});
/** @namespace Bodypwa/Component/Header/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    showNotification: (type, message) => dispatch(showNotification(type, message)),
    setHeaderHeight: (height) => dispatch(setHeaderHeight(height)),
    setIsCheckout: () => dispatch(setIsCheckout()),
    setWindowHeight: (windowHeight) => dispatch(setWindowHeight(windowHeight)),
    setHeaderHidden: (headerHidden) => dispatch(setHeaderHidden(headerHidden)),
    setHeaderPlaceholderHeight:
     (headerPlaceholderHeight) => dispatch(setHeaderPlaceholderHeight(headerPlaceholderHeight)),
    setHeaderElement: (headerElement) => dispatch(setHeaderElement(headerElement)),
    setHeaderTopRef: (headerTopRef) => dispatch(setHeaderTopRef(headerTopRef)),
    validateSession: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.validateSession(dispatch)
    )
});

/** @namespace Bodypwa/Component/Header/Container */
export class HeaderContainer extends SourceHeaderContainer {
    __construct(props) {
        super.__construct(props);

        this.state = {
            prevPathname: '',
            searchCriteria: '',
            isClearEnabled: this.getIsClearEnabled(),
            scrollDistance: 0,
            showMyAccountLogin: false,
            prevScroll: 0
        };

        this.scrollEndTimeout = null;
    }

    containerAdditionalFunctions = {
        onWishlistClick: this.onWishlistClick.bind(this),
        setIsCheckout: this.props.setIsCheckout
    };

    componentDidMount() {
        const { setNavigationState } = this.props;
        setNavigationState(this.getNavigationState());
        history.listen((history) => {
            this.handlePageScroll();
            this.setState(this.onRouteChanged(history));
        });
    }

    componentDidUpdate() {
        const {
            setIsCheckout, setWindowHeight
        } = this.props;

        setIsCheckout();
        setWindowHeight(window.visualViewport?.height || window.innerHeight);
    }

    containerProps() {
        const {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            device,
            isWishlistLoading,
            topPageLinks,
            activeStep,
            setHeaderHeight,
            headerPlaceholderHeight,
            headerHeight,
            setHeaderHight,
            hideWishlistButton,
            setHeaderPlaceholderHeight,
            updateScroll,
            setHeaderElement,
            setHeaderTopRef,
            headerMenu
        } = this.props;

        const {
            isClearEnabled,
            searchCriteria,
            showMyAccountLogin,
            shouldRenderCartOverlay
        } = this.state;

        const {
            location: {
                pathname
            }
        } = history;

        const isCheckout = pathname.includes(CHECKOUT_URL);

        return {
            activeOverlay,
            navigationState,
            cartTotals,
            header_logo_src,
            logo_alt,
            logo_height,
            logo_width,
            isLoading,
            isClearEnabled,
            searchCriteria,
            isCheckout,
            showMyAccountLogin,
            device,
            isWishlistLoading,
            shouldRenderCartOverlay,
            topPageLinks,
            activeStep,
            setHeaderHeight,
            headerPlaceholderHeight,
            headerHeight,
            firstname: this.getUserName(),
            setHeaderHight,
            hideWishlistButton,
            setHeaderPlaceholderHeight,
            updateScroll,
            setHeaderElement,
            setHeaderTopRef,
            headerMenu
        };
    }

    hideSearchOverlay() {
        const { hideActiveOverlay, activeOverlay } = this.props;

        this.setState({ searchCriteria: '' });

        if (activeOverlay === SEARCH) {
            document.activeElement.blur();
            hideActiveOverlay();
        }
    }

    onMyAccountButtonClick() {
        const {
            showOverlay,
            setNavigationState
        } = this.props;

        if (isSignedIn()) {
            history.push({ pathname: appendWithStoreCode('/my-account/my-orders') });
            return;
        }

        this.setState({ showMyAccountLogin: true }, () => {
            showOverlay(CUSTOMER_ACCOUNT_OVERLAY_KEY);
            setNavigationState({
                name: CHECKOUT_ACCOUNT,
                title: 'Sign in',
                onCloseClick: this.closeOverlay
            });
        });
    }

    onWishlistClick() {
        const { showNotification } = this.props;
        if (!isSignedIn()) {
            return showNotification('info', __('You must login or register to add items to your wishlist.'));
        }

        return false;
    }

    handleDesktopRouteChange() {
        const {
            hideActiveOverlay,
            setNavigationState,
            activeOverlay
        } = this.props;

        setNavigationState(this.routeMap['/']);
        if (activeOverlay !== CATEGORY_FILTER_OVERLAY_ID) {
            hideActiveOverlay();
        }

        return {};
    }

    onSearchBarDeactivate() {
        const { deactivateSearchBar, setNavigationState } = this.props;

        deactivateSearchBar();
        this.hideSearchOverlay();
        toggleScroll(true);
        setNavigationState({
            name: MENU
        });
    }

    onRouteChanged(history) {
        const { device, validateSession } = this.props;

        if (isSignedIn()) {
            // check if token is expired and logout
            validateSession();
        }
        this.onSearchBarDeactivate(this);

        const req = new XMLHttpRequest();
        req.open('GET', document.location, true);
        req.onload = () => {
            // eslint-disable-next-line no-magic-numbers
            if (req.status === 405 || req.status === 503) {
                navigator.serviceWorker.getRegistrations().then(
                    /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/getRegistrations/then */
                    (registrations) => {
                        // eslint-disable-next-line no-restricted-syntax
                        for (const registration of registrations) {
                            registration.unregister();
                        }
                    }
                );
                document.addEventListener('activate', (event) => {
                    event.waitUntil(
                        caches.keys().then(
                            /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/document/addEventListener/catch/event/waitUntil/keys/then */
                            (keyList) => Promise.all(
                                keyList.map((key) => caches.delete(key))
                            )
                        )
                    );
                });
                caches.keys().then(
                    /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/keys/then */
                    (keyList) => Promise.all(
                        keyList.map((key) => caches.delete(key))
                    ).then(
                        /** @namespace Bodypwa/Component/Header/Container/HeaderContainer/onRouteChanged/keys/then/all/then */
                        () => {
                            window.location.reload();
                        }
                    )
                );
            }
        };
        req.send(null);

        document.documentElement.classList.remove('om-position-popup');
        checkAppVersionFromHeader();
        if (!device.isMobile) {
            return this.handleDesktopRouteChange(history);
        }

        return this.handleMobileUrlChange(history);
    }

    onMinicartButtonClick() {
        const {
            showOverlay,
            navigationState: { name },
            device
        } = this.props;

        if (name === CART_OVERLAY) {
            return;
        }

        if (!device.isMobile) {
            this.setState({ shouldRenderCartOverlay: true });

            showOverlay(CART_OVERLAY);

            return;
        }

        history.push(appendWithStoreCode(`/${ CART }`));
    }

    render() {
        return (
            <Header
              { ...this.containerProps() }
              { ...this.containerFunctions }
              onWishlistClick={ this.containerAdditionalFunctions.onWishlistClick }
              setIsCheckout={ this.containerAdditionalFunctions.setIsCheckout }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderContainer);
