/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 */
export const PAYPAL_SCRIPT = 'PAYPAL_SCRIPT';
export const PAYPAL_EXPRESS_CREDIT = 'paypal_express_bml';
export const PAYPAL_EXPRESS = 'paypal_express';

export const ACTION_MAP = {
    Authorization: 'authorize',
    Order: 'order',
    Sale: 'capture'
};
