import { PAYMENT_WITH_FORM } from 'Component/CheckoutPayments/CheckoutPayments.config';
import Field from 'Component/Field';
import FIELD_TYPE from 'Component/Field/Field.config';
import {
    CheckoutPayment as SourceCheckoutPayment
} from 'SourceComponent/CheckoutPayment/CheckoutPayment.component';
import { formatPrice } from 'Util/Price';

import './CheckoutPayment.style';
/** @namespace Bodypwa/Component/CheckoutPayment/Component */
export class CheckoutPaymentComponent extends SourceCheckoutPayment {
    render() {
        const {
            isSelected,
            renderPayment,
            method: {
                title, code, type, fee
            },
            currencyCode
        } = this.props;

        const paymentFee = !+fee ? '' : formatPrice(fee, currencyCode);
        const render = PAYMENT_WITH_FORM.includes(type || code) ? renderPayment[code] : false;
        // disable checkbox in order to skip direct clicks on checkbox and handle clicks on entire button instead
        return (
            <li block="CheckoutPayment" className={ isSelected ? 'CheckoutDeliveryOption_selected' : '' }>
                <button
                  block="CheckoutPayment"
                  mods={ { isSelected } }
                  elem="Button"
                  type="button"
                  onClick={ this.onClick }
                >
                    <Field
                      type={ FIELD_TYPE.radio }
                      attr={ {
                          id: `option-${ title }`,
                          name: `option-${ title }`,
                          checked: isSelected
                      } }
                    />
                    <span block="CheckoutPayment" elem="Title">
                        { title }
                    </span>
                    <span block="CheckoutPayment" elem="fee">
                        { paymentFee }
                    </span>
                </button>
                { /* eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-conditional */ }
                { isSelected && render ? render() : '' }
            </li>
        );
    }
}

export default CheckoutPaymentComponent;
