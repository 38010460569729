/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';

import './MinusIcon.style.scss';

/** @namespace Bodypwa/Component/MinusIcon/Component */
export class MinusIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        isPrimary: PropTypes.bool
    };

    static defaultProps = {
        isPrimary: false
    };

    render() {
        const { size, color, isPrimary } = this.props;
        const { dimensions, colors } = this;
        return (
            <svg
              mods={ { isPrimary } }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              elem="About"
            >
            <path d="M20 13.2501V10.7501L4 10.75V13.2501H20Z" fill={ colors[ color ] } className="icon-color" />
            </svg>

        );
    }
}

export default MinusIconComponent;
