import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import componentLoader from 'Util/componentLoader';

import SearchCategory from './SearchCategory.component';

export const SearchBarDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/SearchBar/SearchBar.dispatcher'
), 2);

/** @namespace Bodypwa/Component/SearchCategory/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    hideActiveOverlay: () => dispatch(hideActiveOverlay())
});

/** @namespace Bodypwa/Component/SearchCategory/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Bodypwa/Component/SearchCategory/Container */
export class SearchCategoryContainer extends PureComponent {
    static propTypes = {
        category: PropTypes.isRequired,
        hideActiveOverlay: PropTypes.func.isRequired
    };

    containerFunctions = {
        onClick: this.handleItemClick.bind(this)
    };

    containerProps() {
        const { category } = this.props;

        return {
            category,
            linkTo: this.getLinkTo()
        };
    }

    handleItemClick() {
        // eslint-disable-next-line react/prop-types
        const { hideActiveOverlay, clearSearchResults, deactivateSearchBar } = this.props;

        hideActiveOverlay();
        clearSearchResults();
        deactivateSearchBar();
    }

    getLinkTo() {
        const {
            category: { url_path }
        } = this.props;

        if (!url_path) {
            return {};
        }

        return {
            pathname: url_path
        };
    }

    render() {
        return (
            <SearchCategory
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchCategoryContainer);
