/* eslint-disable max-lines */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MenuQuery from 'Query/Menu.query';
import {
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuContainer as SourceMenuContainer
} from 'SourceComponent/Menu/Menu.container';
import { resetCmsBlocks } from 'Store/Cms/Cms.action';
import {
    incrementRouteCounter, resetRouteCounter,
    setMenu, setTimeStart
} from 'Store/Menu/Menu.action';
import { CUSTOMER } from 'Store/MyAccount/MyAccount.dispatcher';
import { changeNavigationState, goToPreviousNavigationState } from 'Store/Navigation/Navigation.action';
import { BOTTOM_NAVIGATION_TYPE, TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';
import { hideActiveOverlay } from 'Store/Overlay/Overlay.action';
import { setHeaderHeight } from 'Store/Ui/Ui.action';
import {
    MY_WISHLIST,
    NEWSLETTER_SUBSCRIPTION
} from 'Type/Account.type';
import BrowserDatabase from 'Util/BrowserDatabase/BrowserDatabase';
import { noopFn } from 'Util/Common';
import componentLoader from 'Util/componentLoader';
import history from 'Util/History';
import MenuHelper from 'Util/Menu';
import { prepareQuery } from 'Util/Query';
import { hash } from 'Util/Request/Hash';
import { listenForBroadCast } from 'Util/Request/Request';

import { ACCOUNT_LINKS, ROUTE_CHANGE_THRESHOLD, STATIC_CONTENT_REFRESH_INTERVAL } from './Menu.config';

export const MyAccountDispatcher = componentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "dispatchers" */
    'Store/MyAccount/MyAccount.dispatcher'
), 2);

/** @namespace Bodypwa/Component/Menu/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    device: state.ConfigReducer.device,
    header_logo_src: state.ConfigReducer.header_logo_src,
    logo_alt: state.ConfigReducer.logo_alt,
    logo_height: state.ConfigReducer.logo_height,
    logo_width: state.ConfigReducer.logo_width,
    promoImage: state.SideMenuReducer.promoImage,
    isSignedIn: state.MyAccountReducer.isSignedIn,
    headerMenu: state.ConfigReducer.header_menu,
    activeOverlay: state.OverlayReducer.activeOverlay,
    menu: state.MenuReducer.menu,
    routeChangeCounter: state.MenuReducer.routeChangeCounter,
    timeStart: state.MenuReducer.timeStart
});

/** @namespace Bodypwa/Component/Menu/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    logout: () => MyAccountDispatcher.then(
        ({ default: dispatcher }) => dispatcher.logout(false, dispatch)
    ),
    setHeaderHeight: (height) => dispatch(setHeaderHeight(height)),
    hideActiveOverlay: () => dispatch(hideActiveOverlay()),
    setNavigationState: (stateName) => dispatch(changeNavigationState(TOP_NAVIGATION_TYPE, stateName)),
    goToPreviousNavigationState: () => dispatch(goToPreviousNavigationState(BOTTOM_NAVIGATION_TYPE)),
    setMenu: (menu) => dispatch(setMenu(menu)),
    incrementRouteCounter: () => dispatch(incrementRouteCounter()),
    resetRouteCounter: () => dispatch(resetRouteCounter()),
    setTimeStart: (timeStart) => dispatch(setTimeStart(timeStart)),
    resetCmsBlocks: () => dispatch(resetCmsBlocks())
});

/** @namespace Bodypwa/Component/Menu/Container */
export class MenuContainer extends SourceMenuContainer {
    static propTypes = {
        ...SourceMenuContainer.propTypes,
        header_logo_src: PropTypes.string.isRequired,
        onSignOut: PropTypes.func,
        logout: PropTypes.func.isRequired,
        isSignedIn: PropTypes.bool.isRequired
    };

    static defaultProps = {
        onSignOut: noopFn
    };

    componentDidUpdate(prevProps) {
        const { isSubMenuActive: prevIsSubMenuActive } = prevProps;
        const {
            isSubMenuActive, setHeaderHeight, headerMenu, menu
        } = this.props;

        if (headerMenu && JSON.stringify(menu) === '{}') {
            this._getMenu();
        }

        if (!isSubMenuActive && isSubMenuActive !== prevIsSubMenuActive) {
            setHeaderHeight('auto');
        }
    }

    onRouteChanged(history) {
        const { location: { pathname: prevPathname } = {} } = this.state;
        const {
            routeChangeCounter, incrementRouteCounter, resetRouteCounter, timeStart, setTimeStart, resetCmsBlocks
        } = this.props;
        const { pathname } = history;
        if (pathname !== prevPathname) {
            incrementRouteCounter();
            if (routeChangeCounter + 1 >= ROUTE_CHANGE_THRESHOLD
                || Date.now() - timeStart >= STATIC_CONTENT_REFRESH_INTERVAL) {
                this._getMenu(true);
                resetRouteCounter();
                setTimeStart(Date.now());
                resetCmsBlocks();
            }
            this.setState({ location: history });
        }
    }

    componentDidMount() {
        const { device: { isMobile }, timeStart, setTimeStart } = this.props;
        this._getMenu();
        if (isMobile) {
            window.addEventListener('popstate', this.historyBackHook);
        }
        this.setState({ location: history });
        history.listen((history) => {
            this.onRouteChanged(history);
        });
        if (!timeStart) {
            setTimeStart(Date.now());
        }
    }

    containerFunctions = {
        handleSubcategoryClick: this.handleSubcategoryClick.bind(this),
        closeMenu: this.closeMenu.bind(this),
        onCategoryHover: this.onCategoryHover.bind(this),
        closeSubMenu: this.closeSubMenu.bind(this),
        getMenuTitle: this.getMenuTitle.bind(this),
        handleLogout: this.handleLogout.bind(this)
    };

    _getMenu(getFreshData = false) {
        const { headerMenu, menu, setMenu } = this.props;
        const { identifier } = this._getMenuOptions();

        // eslint-disable-next-line no-debugger
        if ((headerMenu && JSON.stringify(menu) === '{}') || getFreshData) {
            this.fetchData(
                [MenuQuery.getQuery(this._getMenuOptions())],
                ({ menu }) => setMenu(MenuHelper.reduce(menu)),
                () => {},
                `Menu${identifier}`
            );

            const preparedQuery = prepareQuery([MenuQuery.getQuery(this._getMenuOptions())]);
            const { query, variables } = preparedQuery;
            const queryHash = hash(query + JSON.stringify(variables));
            listenForBroadCast(`Menu${identifier}`).then(
                /** @namespace Bodypwa/Component/Menu/Container/MenuContainer/_getMenu/listenForBroadCast/then */
                ({ menu }) => {
                    setMenu(MenuHelper.reduce(menu));
                    window.dataCache[queryHash] = { menu };
                }
            );
        }
    }

    handleLogout() {
        const { onSignOut, logout, closeSideMenu } = this.props;

        closeSideMenu();
        logout();
        onSignOut();
    }

    closeSubMenu() {
        const { showMainLevel } = this.props;
        this.setState({ activeMenuItemsStack: [], menuTitle: '' });
        const { menuRefProp } = this.props;
        menuRefProp.current.scrollTo(0, 0);
        showMainLevel();
    }

    getMenuTitle() {
        return this.state.menuTitle;
    }

    getUserName() {
        const { firstname, lastname } = BrowserDatabase.getItem(CUSTOMER) || {};

        return { firstname, lastname };
    }

    handleSubcategoryClick(e, activeSubcategory) {
        const { activeMenuItemsStack } = this.state;
        const { item_id, title } = activeSubcategory;
        const { menuRefProp } = this.props;
        e.stopPropagation();
        const newActiveMenuItemsStack = this.getNewActiveMenuItemsStack(activeMenuItemsStack, item_id);
        if (!activeMenuItemsStack.length) {
            this.setState({ menuTitle: title });
        }
        menuRefProp.current.scrollTo(0, 0);

        this.setState({ activeMenuItemsStack: newActiveMenuItemsStack });
    }

    onCategoryHover(activeSubcategory) {
        const { device, hideActiveOverlay, setNavigationState } = this.props;
        const { activeMenuItemsStack } = this.state;
        if (device.isMobile) {
            return;
        }

        const { item_id, children } = activeSubcategory;

        if (activeMenuItemsStack.includes(item_id)) {
            return;
        }
        if (Object.keys(children).length) {
            hideActiveOverlay();
            setNavigationState({
                name: ''
            });
        }

        this.setState({ activeMenuItemsStack: [item_id] });
    }

    closeMenu() {
        const { showMainLevel } = this.props;

        showMainLevel();
        this.setState({ activeMenuItemsStack: [] });
    }

    containerProps() {
        const {
            device, header_logo_src, showMainLevel, isSignedIn, menuRefProp, activeOverlay, setHeaderHeight,
            headerHeight, menu
        } = this.props;
        const { activeMenuItemsStack, promoImage } = this.state;

        return {
            showMainLevel,
            header_logo_src,
            activeMenuItemsStack,
            menu,
            promoImage,
            device,
            userName: this.getUserName(),
            isSignedIn,
            menuRefProp,
            activeOverlay,
            setHeaderHeight,
            headerHeight,
            accountLinks: this.linkFilterEnabled()
        };
    }

    linkFilterEnabled() {
        return { ...ACCOUNT_LINKS, items: ACCOUNT_LINKS.items.filter(({ name }) => this.isLinkEnabled(name)) };
    }

    isLinkEnabled(link) {
        const { isWishlistEnabled, newsletterActive } = this.props;
        switch (link) {
        case MY_WISHLIST:
            return isWishlistEnabled;
        case NEWSLETTER_SUBSCRIPTION:
            return newsletterActive;
        default:
            return true;
        }
    }

    _getMenuOptions() {
        const { headerMenu } = this.props;

        return {
            identifier: headerMenu || 'new-main-menu-nl'
        };
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuContainer);
