import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
import { DirectionType } from 'Type/Direction.type';

import { RIGHT } from './ChevronIcon.config';

import './ChevronIcon.style';
/** @namespace Bodypwa/Component/ChevronIcon/Component */
export class ChevronIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        direction: DirectionType
    };

    static defaultProps = {
        direction: RIGHT
    };

    render() {
        const { direction, size, color } = this.props;
        const { dimensions, colors } = this;

        return (
            <svg
              block="ChevronIcon"
              mods={ { direction } }
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                block="ChevronBodIcon"
                d="M4 6.23223L12 14.2322L20 6.23218V9.76771L12
              17.7677L4 9.76777V6.23223Z"
                fill={ colors[ color ] }
                className="icon-color"
              />
            </svg>

        );
    }
}

export default ChevronIconComponent;
