/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/control-has-associated-label */
import PropTypes from 'prop-types';
import { createRef, PureComponent } from 'react';

import './ScrollSwiperSlide.style';

/** @namespace Bodypwa/Component/ScrollSwiperSlide/Component */
export class ScrollSwiperSlideComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired
    };

    sliderRef = createRef();

    sliderContianerRef = createRef();

    slideNext = () => {
        this.sliderRef.current.scroll({
            top: 0,
            left: this.sliderRef.current.scrollLeft + this.sliderRef.current.offsetWidth,
            behavior: 'smooth'
        });
    };

    slidePrev = () => {
        this.sliderRef.current.scroll({
            top: 0,
            left: this.sliderRef.current.scrollLeft - this.sliderRef.current.offsetWidth,
            behavior: 'smooth'
        });
    };

    render() {
        return (
            <div className="swiper-slide">
                { this.props.children }
            </div>
        );
    }
}

export default ScrollSwiperSlideComponent;
