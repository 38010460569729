/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable max-lines */
// TODO restructure/split file to be easy to maintain
import PropTypes from 'prop-types';

import Button from 'Component/Button';
import {
    SIZE_NONE, TYPE_SECONDARY, TYPE_TEXT, WIDTH_HUG
} from 'Component/Button/Button.config';
import ChevronIcon from 'Component/ChevronIcon';
import { LEFT } from 'Component/ChevronIcon/ChevronIcon.config';
import CmsBlock from 'Component/CmsBlock';
import ContentWrapper from 'Component/ContentWrapper';
import CrossIcon from 'Component/CrossIcon';
import { BLACK_KEY, M_KEY } from 'Component/Icon/Icon.config';
import Image from 'Component/Image';
import Link from 'Component/Link';
import Logo from 'Component/Logo';
import MenuItem from 'Component/MenuItem';
import TextPlaceholder from 'Component/TextPlaceholder';
import {
    Menu as SourceMenu
} from 'SourceComponent/Menu/Menu.component';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import media from 'Util/Media';
import { LOGO_MEDIA } from 'Util/Media/Media';
import { getSortedItems } from 'Util/Menu';

import { TEXT_CENTER, WIDTH_FULL } from '../Button/Button.config';

import './Menu.override.style';

export const StoreSelector = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "store-selector" */
    'Component/StoreSelector'
), 2);

/** @namespace Bodypwa/Component/Menu/Component */
export class MenuComponent extends SourceMenu {
    static propTypes = {
        ...SourceMenu.propTypes,
        header_logo_src: PropTypes.string,
        logo_alt: PropTypes.string,
        promoImage: PropTypes.string,
        handleLogout: PropTypes.func.isRequired
    };

    static defaultProps = {
        ...SourceMenu.defaultProps,
        closeSubMenu: PropTypes.func.isRequired,
        logo_alt: '',
        header_logo_src: ''
    };

    renderTopMenuBar() {
        return (
            <div
              block="Menu"
              elem="Header"
            >
                { this.renderBackAction() }
                { this.renderMenuTitle() }
                { this.renderCloseMenuBtn() }
            </div>
        );
    }

    renderLogoImage() {
        const {
            header_logo_src,
            logo_alt
        } = this.props;

        const logoSrc = header_logo_src ? media(header_logo_src, LOGO_MEDIA) : null;
        return (
            <Logo
              src={ logoSrc }
              alt={ logo_alt }
              title={ logo_alt }
            />
        );
    }

    renderMenuTitle() {
        const { getMenuTitle } = this.props;
        const title = getMenuTitle();
        if (title) {
            return (
                <div
                  block="Menu"
                  elem="Title"
                >
                    <h3>{ title }</h3>
                </div>
            );
        }

        return this.renderLogo();
    }

    renderLogo() {
        return (
            <ContentWrapper
              isNotSection
              wrapperMix={ { block: 'Menu', elem: 'Logo' } }
              label=""
            >
                    { this.renderLogoImage() }
            </ContentWrapper>
        );
    }

    renderBackAction() {
        const { activeMenuItemsStack, device: { isMobile }, closeSubMenu } = this.props;
        if (!isMobile || !activeMenuItemsStack.length) {
            return null;
        }

        return (
            <div block="Menu" elem="Position">
                <Button
                  width={ WIDTH_HUG }
                  type={ TYPE_TEXT }
                  onClick={ closeSubMenu }
                  size={ SIZE_NONE }
                >
                    <ChevronIcon size={ M_KEY } direction={ LEFT } />
                </Button>
            </div>
        );
    }

    renderFirstLevelItems(item) {
        const {
            activeMenuItemsStack,
            handleSubcategoryClick,
            onCategoryHover,
            closeMenu,
            hideMainLevel,
            device
        } = this.props;

        const { children, item_id } = item;
        const childrenArray = Object.values(children);
        const itemMods = { type: 'main' };

        if (activeMenuItemsStack.length) {
            hideMainLevel();
        }

        if (childrenArray.length && device.isMobile) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  block="Menu"
                  elem="SubCatLink"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ { ...itemMods, isExpanded: activeMenuItemsStack.includes(item_id) } }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                      isExpandable
                    />
                    { this.renderSubLevel(item, true) }
                </div>
            );
        }

        return (
            <MenuItem
              activeMenuItemsStack={ activeMenuItemsStack }
              item={ item }
              itemMods={ itemMods }
              onCategoryHover={ onCategoryHover }
              hideIcon
              isLink
            />
        );
    }

    renderPromoContent() {
        const { promoImage: { imageSrc, imageTitle } } = this.props;

        if (imageSrc) {
            return (
                <div
                  block="PromoImage"
                  elem="Container"
                >
                    <Image
                      src={ imageSrc }
                      mix={ {
                          block: 'PromoImage',
                          elem: 'Image'
                      } }
                      ratio="custom"
                      alt={ __('%s Promo image', imageTitle) }
                    />
                </div>
            );
        }

        return (
            <CmsBlock identifier="menu_campaing_banner" />
        );
    }

    renderSubMenuDesktopItems = (item) => {
        const { item_id, children } = item;
        if (!Object.keys(children).length) {
            return null;
        }

        const {
            activeMenuItemsStack, closeMenu
        } = this.props;
        const isVisible = activeMenuItemsStack.includes(item_id);

        if (!isVisible) {
            return null;
        }

        return (
            <div
              block="Menu"
              elem="SubCategoriesWrapper"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="SubCategoriesWrapperInner"
                  mods={ { isVisible } }
                >
                    <div
                      block="Menu"
                      elem="SubCategories"
                    >

                        { this.renderSubLevel(item) }
                        <div
                          block="Menu"
                          elem="ItemList"
                        >
                        { this.renderPromoContent() }
                        </div>
                        { this.renderAdditionalInformation() }
                    </div>
                </div>
                <div
                  block="Menu"
                  elem="Overlay"
                  mods={ { isVisible } }
                  onMouseEnter={ closeMenu }
                />

            </div>
        );
    };

    renderTopLevel() {
        const { menu } = this.props;
        const categoryArray = Object.values(menu);

        if (!categoryArray.length) {
            return (
                <div block="Menu" elem="MainCategories">
                     <TextPlaceholder />
                </div>
            );
        }

        const [{ children, title: mainCategoriesTitle }] = categoryArray;
        const childrenArray = getSortedItems(Object.values(children));

        return (
            <>
                { this.renderTopMenuBar() }
                <div block="Menu" elem="MainCategories">
                    { this.renderAdditionalInformationTop(true) }
                    <ul
                      block="Menu"
                      elem="ItemList"
                      mods={ { type: 'main' } }
                      aria-label={ mainCategoriesTitle }
                    >
                        { childrenArray.map(this.renderFirstLevel) }
                    </ul>
                    { this.renderAdditionalInformationBottom(true) }
                </div>
                { this.renderSubMenuDesktop(children) }
            </>
        );
    }

    renderCloseMenuBtn() {
        const { closeSideMenu, device: { isMobile } } = this.props;
        if (!isMobile) {
            return null;
        }

        return (
            <div block="HamburgerMenu" elem="CloseBtnWrapper">
                <Button
                  onClick={ closeSideMenu }
                  aria-label={ __('Close Menu') }
                  type={ TYPE_TEXT }
                  width={ WIDTH_HUG }
                //   size={ SIZE_NONE }
                  isIconSmall
                >
                   <CrossIcon size={ M_KEY } color={ BLACK_KEY } />

                </Button>
            </div>
        );
    }

    renderAdditionalInformationTop(checkMobile = false) {
        const { device } = this.props;

        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <CmsBlock identifier="menu_campaing_banner_mobile" />
        );
    }

    renderAdditionalInformationBottom(checkMobile = false) {
        const { device } = this.props;

        if (checkMobile && !device.isMobile) {
            return null;
        }

        return (
            <>
                { this.renderAccountLinks() }
                { this.renderAdditionalLinks() }
                { this.renderStoreSwitcher() }
            </>
        );
    }

    renderSubLevel(category, isSecondLevel = false) {
        const { activeMenuItemsStack } = this.props;
        const { item_id, children, item_class } = category;
        const childrenArray = getSortedItems(Object.values(children));
        // TODO create global variable
        const isVisible = item_class === 'sub-menu-visible' || activeMenuItemsStack.includes(item_id);
        const subcategoryMods = { type: 'subcategory' };
        return (
            <div
              block="Menu"
              elem="SubMenu"
              mods={ { isVisible } }
              key={ item_id }
            >
                <div
                  block="Menu"
                  elem="ItemList"
                  mods={ { ...subcategoryMods } }
                >
                    { childrenArray.map((item) => this.renderSubLevelItems(item, isSecondLevel)) }
                </div>
            </div>
        );
    }

    renderSubLevelItems = (item, isSecondLevel) => {
        const {
            handleSubcategoryClick,
            activeMenuItemsStack,
            onCategoryHover,
            closeMenu,
            device
        } = this.props;

        const { item_id, children } = item;

        const childrenArray = Object.values(children);
        const subcategoryMods = { type: 'subcategory', isSecondLevel };

        if (childrenArray.length && device.isMobile) {
            return (
                // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                <div
                  key={ item_id }
                  // TODO: split into smaller components
                  // eslint-disable-next-line react/jsx-no-bind
                  onClick={ (e) => handleSubcategoryClick(e, item) }
                  tabIndex="0"
                  role="button"
                >
                    <MenuItem
                      activeMenuItemsStack={ activeMenuItemsStack }
                      item={ item }
                      itemMods={ {
                          ...subcategoryMods,
                          isExpanded: activeMenuItemsStack.includes(item_id)
                      } }
                      onCategoryHover={ onCategoryHover }
                      closeMenu={ closeMenu }
                      isExpandable
                    />
                    { this.renderSubLevel(item) }
                </div>
            );
        }

        return (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events
            <div
              block="Menu"
              elem="SubItemWrapper"
              key={ item_id }
              onClick={ this.stopPropagation }
              role="button"
              tabIndex="-1"
            >
                <MenuItem
                  activeMenuItemsStack={ activeMenuItemsStack }
                  item={ item }
                  itemMods={ { ...subcategoryMods, childrenArray } }
                  closeMenu={ closeMenu }
                  isLink
                />
                { this.renderDesktopSubLevel(item) }
            </div>
        );
    };

    renderLoggedInInfo() {
        const {
            userName: { firstname, lastname },
            handleLogout,
            isSignedIn
        } = this.props;

        if (!isSignedIn) {
            return null;
        }

        return (
            <div block="Menu" elem="LoggedIn">
                { __('Hi') }
                <span>
                    { `${firstname} ${lastname}` }
                </span>
                <Button
                  onClick={ handleLogout }
                  type={ TYPE_TEXT }
                  size={ SIZE_NONE }
                  width={ WIDTH_HUG }
                  text={ __('Logout') }
                />
            </div>
        );
    }

    renderAccountLinks() {
        const { accountLinks } = this.props;
        return (
            <>
                { this.renderLoggedInInfo() }
                <ContentWrapper
                  isNotSection
                  wrapperMix={ { block: 'Footer' } }
                  label=""
                >
                    { this.renderColumn(accountLinks, 'accountLinks') }
                </ContentWrapper>
            </>
        );
    }

    renderColumn = (column, i) => {
        const {
            columnActiveKey,
            items,
            mods = {}
        } = column;

        const { [columnActiveKey]: isColumnActive } = this.props;

        if (columnActiveKey && !isColumnActive === true) {
            return null;
        }

        return (
            <div block="Menu" elem="Column" mods={ mods } key={ i }>
                <div
                  block="Menu"
                  elem="ColumnContent"
                >
                    { items.map(this.renderColumnItem) }
                </div>
            </div>
        );
    };

    renderColumnItem = (item, i) => {
        const { isSignedIn } = this.props;

        const { icon: Icon, href, title } = item;
        const mods = Icon ? { type: 'image' } : {};
        const icon = Icon ? <Icon size={ M_KEY } color={ BLACK_KEY } /> : null;

        const link = isSignedIn ? href : '/login';

        return (
            <Link
              block="Menu"
              elem="AccountColumnItem"
              to={ link }
              mods={ mods }
              key={ i }
              aria-label={ title }
            >
                { icon }
                { title }
            </Link>
        );
    };

    renderAdditionalLinks() {
        const { isSignedIn } = this.props;

        if (isSignedIn) {
            return (
                <article block="MyAccountOverlay" elem="Additional">
                    <section className="Spacer" />
                </article>
            );
        }

        return (
            <article block="MyAccountOverlay" elem="Additional">
                <Button
                  customClass="Button Login"
                  goTo="account/login"
                  type={ TYPE_SECONDARY }
                  width={ WIDTH_FULL }
                  isLink
                  alignContent={ TEXT_CENTER }
                >
                    <div block="Button" elem="LogInOrRegister">
                        { __('Log in') }
                        <span>{ __('or') }</span>
                    { __('Register') }
                    </div>
                </Button>
            </article>
        );
    }

    renderStoreSwitcher() {
        const { device } = this.props;

        if (!device.isMobile) {
            return null;
        }

        return <StoreSelector isSimple storeSelectorId="MENU_STORE_SELECTOR" isRedirectPopUp />;
    }

    render() {
        const { closeMenu, activeMenuItemsStack } = this.props;
        const isOpened = !!activeMenuItemsStack.length;
        return (
            <div
              block="Menu"
              elem="MenuWrapper"
              mods={ { isOpened } }
              onMouseLeave={ closeMenu }
            >
                { this.renderTopLevel() }
            </div>
        );
    }
}

export default MenuComponent;
