/* eslint-disable react/prop-types */
/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

import { event, setPageType } from '@scandipwa/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { resetCheckout, setIsCheckout } from 'Store/Checkout/Checkout.action';

import CheckoutSuccess from './CheckoutSuccess.component';
/** @namespace Bodypwa/Component/CheckoutSuccess/Container/mapStateToProps */
export const mapStateToProps = () => ({});

/** @namespace Bodypwa/Component/CheckoutSuccess/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setIsCheckout: () => dispatch(setIsCheckout()),
    event: (eventName = '', customData) => dispatch(event(eventName, customData)),
    setPageType: (pageType) => dispatch(setPageType(pageType)),
    resetCheckout: () => dispatch(resetCheckout())
});

/** @namespace Bodypwa/Component/CheckoutSuccess/Container */
export class CheckoutSuccessContainer extends PureComponent {
    render() {
        const {
            email, firstname, lastname, isEmailAvailable, orderID, event, setIsCheckout, setPageType, resetCheckout
        } = this.props;

        return (
            <CheckoutSuccess
              email={ email }
              firstName={ firstname }
              lastName={ lastname }
              isEmailAvailable={ isEmailAvailable }
              orderID={ orderID }
              event={ event }
              setPageType={ setPageType }
              setIsCheckout={ setIsCheckout }
              resetCheckout={ resetCheckout }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutSuccessContainer);
