import {
    Slider as SourceSlider
} from 'SourceComponent/Slider/Slider.component';

import './Slider.style';

/** @namespace Bodypwa/Component/Slider/Component */
export class SliderComponent extends SourceSlider {
    // TODO implement logic
}

export default SliderComponent;
