/* eslint-disable max-len */
import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';

/** @namespace Bodypwa/Component/CartIcon/Component */
export class CartIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired,
        isChecked: PropTypes.bool.isRequired,
        isTransparent: PropTypes.bool.isRequired,
        isActive: PropTypes.bool.isRequired
    };

    renderCartEmpty(colors, color, isEmpty, isChecked) {
        if (!isEmpty || isChecked) {
            return null;
        }

        return (
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.73223 2H16.2678L18.25 3.98223V5.75H22V22H2V5.75H5.75V3.98223L7.73223
                2ZM5.75 8.25V9.5H8.25V8.25H15.75V9.5H18.25V8.25H19.5V19.5H4.5V8.25H5.75ZM15.75 5.75H8.25V5.01777L8.76777
                4.5H15.2322L15.75 5.01777V5.75Z"
              block="BodIcon"
              fill={ colors[color] }
            />
        );
    }

    renderCartChecked(colors, color, isChecked) {
        if (!isChecked) {
            return null;
        }

        return (
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M16.2678 2H7.73223L5.75 3.98223V5.75H2V22H22V5.75H18.25V3.98223L16.2678
                2ZM8.25 5.75H15.75V5.01777L15.2322 4.5H8.76777L8.25 5.01777V5.75ZM16.9393 9.9394L10.5001
                16.3787L7.06066 12.9392L6 13.9999L10.5001
                18.5L18 11.0001L16.9393 9.9394Z"
              block="BodIcon"
              fill={ colors[color] }
            />
        );
    }

    renderCartFull(colors, color, isEmpty, isChecked) {
        if (isEmpty || isChecked) {
            return null;
        }

        return (
            <g>
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.73223 2H16.2678L18.25 3.98223V5.75H22V22H2V5.75H5.75V3.98223L7.73223 2ZM15.75 5.75H8.25V5.01777L8.76777 4.5H15.2322L15.75 5.01777V5.75Z"
              fill={ colors[color] }
              className="icon-color"
              block="BodIcon"
            />
            </g>

        );
    }

    render() {
        const { dimensions, colors } = this;
        const {
            size, color, isTransparent, isChecked, isActive
        } = this.props;

        return (
            <svg
              width={ dimensions[ size ] }
              height={ dimensions[ size ] }
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              mods={ { isActive } }
            >
                    { this.renderCartEmpty(colors, color, isTransparent, isChecked) }
                    { this.renderCartChecked(colors, color, isChecked) }
                    { this.renderCartFull(colors, color, isTransparent, isChecked) }
            </svg>
        );
    }
}
export default CartIconComponent;
