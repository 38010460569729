/* eslint-disable max-len */
import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import {
    PLACEHOLDER_COUNTRY_NAME,
    STORE_SWIRCHER_WELCOME_ID, TYPE_PAGE, TYPE_POPUP, WELCOME_MEDIA_URL,
    WELCOME_PAGE_CLASS
} from 'Component/WelcomePage/WelcomePage.config';
import { CountryType } from 'Type/Stores.type';
import { lazyComponentLoader } from 'Util/lazyComponentLoader';
import media from 'Util/Media';

import './WelcomePage.style';

export const BodLogo = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "store-selector" */
    'Component/BodLogo'
), 2);

export const StoreSelector = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "store-selector" */
    'Component/StoreSelector'
), 2);

export const StoreRedirect = lazyComponentLoader(() => import(
    /* webpackMode: "lazy", webpackChunkName: "welcome-page" */
    'Component/StoreRedirect'
), 2);
/** @namespace Bodypwa/Component/WelcomePage/Component */
export class WelcomePageComponent extends PureComponent {
    static propTypes = {
        isMobile: PropTypes.bool.isRequired,
        backgroundMobileImage: PropTypes.string.isRequired,
        backgroundDesktopImage: PropTypes.string.isRequired,
        welcomeType: PropTypes.string.isRequired,
        redirectCountry: CountryType.isRequired,
        welcomePageTitle: PropTypes.string.isRequired,
        welcomePageDescription: PropTypes.string.isRequired,
        isShowStoreSelect: PropTypes.bool.isRequired,
        closeWelcomePage: PropTypes.func.isRequired
    };

    renderLogo() {
        return (
            <div block="WelcomePage" elem="Logo">
                <BodLogo />
            </div>
        );
    }

    renderTitle() {
        const { welcomePageTitle, redirectCountry: { label } } = this.props;
        return <h2 block="WelcomePage" elem="Title">{ welcomePageTitle.replace(PLACEHOLDER_COUNTRY_NAME, label) }</h2>;
    }

    renderDescription() {
        const { welcomePageDescription, redirectCountry: { label } } = this.props;
        return (
            <div block="WelcomePage" elem="Description">
                <p>{ welcomePageDescription.replace(PLACEHOLDER_COUNTRY_NAME, label) }</p>
            </div>
        );
    }

    renderStoreSwitcher() {
        const { redirectCountry, closeWelcomePage } = this.props;
        return (
            <StoreSelector storeSelectorId={ STORE_SWIRCHER_WELCOME_ID } currentCountry={ redirectCountry } onRedirect={ closeWelcomePage } />
        );
    }

    renderStoreRedirect() {
        const { redirectCountry, isShowStoreSelect } = this.props;
        return <StoreRedirect redirectCountry={ redirectCountry } isShowStoreSelect={ isShowStoreSelect } />;
    }

    renderWelcomePage() {
        const {
            isMobile, backgroundMobileImage, backgroundDesktopImage, isShowStoreSelect
        } = this.props;

        document.querySelector('html').classList.remove(WELCOME_PAGE_CLASS);
        if (!isShowStoreSelect) {
            return null;
        }
        document.querySelector('html').classList.add(WELCOME_PAGE_CLASS);
        return (
            <div block="WelcomePage" style={ { backgroundImage: isMobile ? `url('${media(backgroundMobileImage, WELCOME_MEDIA_URL)}')` : `url('${media(backgroundDesktopImage, WELCOME_MEDIA_URL)}')` } }>
                <div block="WelcomePage" elem="Content">
                    { this.renderLogo() }
                    <div block="WelcomePage" elem="InnerContent">
                        { this.renderTitle() }
                        { this.renderDescription() }
                        { this.renderStoreSwitcher() }
                    </div>
                </div>
            </div>
        );
    }

    render() {
        const { welcomeType } = this.props;
        switch (welcomeType) {
        case TYPE_PAGE:
            return this.renderWelcomePage();
        case TYPE_POPUP:
            return this.renderStoreRedirect();
        default:
            return null;
        }
    }
}

export default WelcomePageComponent;
