import PropTypes from 'prop-types';

import IconComponent from 'Component/Icon';
/** @namespace Bodypwa/Component/RulerIcon/Component */
export class RulerIconComponent extends IconComponent {
    static propTypes = {
        size: PropTypes.string.isRequired,
        color: PropTypes.string.isRequired
    };

    render() {
        const { dimensions, colors } = this;
        const { size, color } = this.props;
        return (
          <svg
            width={ dimensions[ size ] }
            height={ dimensions[ size ] }
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9.8 9.5H7.5V12H6.5V9.5H4.5V14.5H19.5V9.5H17.4V12H16.4V9.5H14.1V12H13.1V9.5H10.8V12H9.8V9.5ZM16.4
           7H17.4H22V17H2V7H6.5H7.5H9.8H10.8H13.1H14.1H16.4Z"
            fill={ colors[ color ] }
            className="icon-color"
          />
          </svg>

        );
    }
}

export default RulerIconComponent;
