/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/paypal
 * @link https://github.com/scandipwa/base-theme
 */

export const INSTANT_PAYMENT_LOCATION_CART = 'cart';
export const INSTANT_PAYMENT_LOCATION_MINICART = 'minicart';
export const INSTANT_PAYMENT_LOCATION_CHECKOUT = 'checkout';
