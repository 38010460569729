import {
    StoreItemContainer as SourceStoreItemContainer
} from 'SourceComponent/StoreItem/StoreItem.container';

/** @namespace Bodypwa/Component/StoreItem/Container */
export class StoreItemContainer extends SourceStoreItemContainer {
    getStoreCode() {
        const { item: { storeUrl } } = this.props;
        // TODO change this logic to handle it corectly
        window.location = storeUrl;
    }
}

export default StoreItemContainer;
