import { PureComponent } from 'react';

import {
    fetchQuery
} from 'Util/Request';

import I18nQuery from '../../query/I18n.query';
import I18n from './I18n.component';
import { BODYAPP_LOCALE } from './I18n.config';
/** @namespace Webpacki18nRuntime/Component/I18n/Container/mapStateToProps */
export const mapStateToProps = () => ({
});

/** @namespace Webpacki18nRuntime/Component/I18n/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});

/** @namespace Webpacki18nRuntime/Component/I18n/Container */
export class I18nContainer extends PureComponent {
    state = {
        locale: 'en_US'
    };

    componentDidMount() {
        this.setLocaleFromRequest();
    }

    setLocaleFromRequest() {
        const req = new XMLHttpRequest();
        req.open('GET', document.location, true);
        req.onload = () => {   
            const headers = req.getAllResponseHeaders().split('\n');
            const localeHeader = headers?.filter((header) => header?.includes(BODYAPP_LOCALE));
            const storeLocale = localeHeader[0]?.replace(`${BODYAPP_LOCALE}: `, '')?.replace('\r', '');

            if (storeLocale) {
                this.setState({ locale: storeLocale });
                document.documentElement.lang = this.getLanguageCode(storeLocale);
            } else {
                this.setLocale();
            }
        };

        req.send();
        
    }

    async setLocale() {
        const { storeConfig: { locale } } = await fetchQuery(
            I18nQuery.getQuery()
        );

        document.documentElement.lang = this.getLanguageCode(locale);
        this.setState({ locale });
    }

    getLanguageCode(lang) {
        return lang.substring(0, 2);
    }

    render() {
        const { locale } = this.state;
        return (
            <I18n
                // eslint-disable-next-line @scandipwa/scandipwa-guidelines/jsx-no-props-destruction
                { ...this.props }
                locale={ locale }
            />
        );
    }
}

export default I18nContainer;
