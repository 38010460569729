import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import i18n from '../../util/i18n';

/** @namespace Webpacki18nRuntime/Component/I18n/Component */
export class I18nComponent extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        locale: PropTypes.string.isRequired
    };

    // eslint-disable-next-line @scandipwa/scandipwa-guidelines/only-render-in-component
    __construct(props) {
        super.__construct(props);

        i18n.init(this.forceUpdate.bind(this));
    }

    render() {
        const { children, locale } = this.props;
        i18n.setLocale(locale);
        const currentLocale = i18n.getCurrentLocale();

        // Remount the application on locale change
        return (
            <div
              block="LocalizationWrapper"
              elem={ currentLocale }
              key={ currentLocale }
            >
                { children }
            </div>
        );
    }
}

export default I18nComponent;
