import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import ScrollSwiper from './ScrollSwiper.component';

/** @namespace Bodypwa/Component/ScrollSwiper/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    isMobile: state.ConfigReducer.device.isMobile
});

/** @namespace Bodypwa/Component/ScrollSwiper/Container/mapDispatchToProps */
export const mapDispatchToProps = () => ({
});
/** @namespace Bodypwa/Component/ScrollSwiper/Container */
export class ScrollSwiperContainer extends PureComponent {
    static propTypes = {
        children: PropTypes.node.isRequired,
        isAutoScroll: PropTypes.bool,
        isMobile: PropTypes.bool.isRequired
    };

    static defaultProps = {
        isAutoScroll: false
    };

    containerFunctions = {
        dragElement: this.dragElement.bind(this)
    };

    componentDidMount() {
        smoothscroll.polyfill();
    }

    containerProps() {
        const { children, isAutoScroll, isMobile } = this.props;

        return {
            children,
            isAutoScroll,
            isMobile
        };
    }

    dragElement(elem) {
        const slider = elem;
        let isDown = false;
        let startX;
        let scrollLeft;

        slider.addEventListener('mousedown', (e) => {
            e.preventDefault();
            const { scrollLeft: initialScollLeft } = slider;
            isDown = true;
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = initialScollLeft;
        });
        slider.addEventListener('mouseup', (e) => {
            e.preventDefault();
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('active');
        });
        slider.addEventListener('mousemove', (e) => {
            if (!isDown) {
                return;
            }
            slider.classList.add('active');
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            // eslint-disable-next-line no-magic-numbers
            const walk = (x - startX) * 3; // scroll-fast
            slider.scrollLeft = scrollLeft - walk;
        });
    }

    render() {
        return (
            <ScrollSwiper
              { ...this.containerFunctions }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScrollSwiperContainer);
