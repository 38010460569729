import {
    EVENT_WELCOME_PAGE_NOT_VISIBLE,
    EVENT_WELCOME_PAGE_OTHER_REDIRECT,
    EVENT_WELCOME_PAGE_REDIRECT,
    EVENT_WELCOME_PAGE_VISIBLE
} from '@scandipwa/gtm-new/src/component/GoogleTagManager/GoogleTagManager.events';
import { event } from '@scandipwa/gtm-new/src/store/GoogleTagManager/GoogleTagManager.action';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import {
    COM_SUB_STORE,
    COOKIE_LIFETIME,
    CURRENT_COUNTRY,
    DOT_COM,
    EXCLUDED_DOT_COM_STORES,
    NO_REDIRECT
} from 'Component/StoreSelector/StoreSelector.config';
import { setWindowHeight } from 'Store/Ui/Ui.action';
import { CountryType } from 'Type/Stores.type';
import { setCookie } from 'Util/Cookies/Cookies';

import { getCookie } from '../../util/Cookies';
import WelcomePage from './WelcomePage.component';

import './WelcomePage.style';
/** @namespace Bodypwa/Component/WelcomePage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    device: state.ConfigReducer.device,
    backgroundMobileImage: state.ConfigReducer.welcome_background_image_mobile,
    backgroundDesktopImage: state.ConfigReducer.welcome_background_image_desktop,
    countries: state.StoresReducer.countries,
    welcomeType: state.ConfigReducer.welcome_type,
    currentStoreCode: state.ConfigReducer.default_country,
    welcomePageTitle: state.ConfigReducer.welcome_page_title,
    welcomePageDescription: state.ConfigReducer.welcome_page_description
});

/** @namespace Bodypwa/Component/WelcomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    setWindowHeight: (windowHeight) => dispatch(setWindowHeight(windowHeight)),
    event: (eventName = '', customData) => dispatch(event(eventName, customData))
});
/** @namespace Bodypwa/Component/WelcomePage/Container */
export class WelcomePageContainer extends PureComponent {
    static propTypes = {
        device: PropTypes.shape({
            isMobile: PropTypes.bool
        }).isRequired,
        backgroundMobileImage: PropTypes.string.isRequired,
        backgroundDesktopImage: PropTypes.string.isRequired,
        setWindowHeight: PropTypes.func.isRequired,
        welcomeType: PropTypes.string.isRequired,
        countries: PropTypes.arrayOf(CountryType).isRequired,
        currentStoreCode: PropTypes.string.isRequired,
        welcomePageTitle: PropTypes.string.isRequired,
        welcomePageDescription: PropTypes.string.isRequired,
        event: PropTypes.func.isRequired
    };

    state= {
        redirectCountry: {},
        showStoreSelect: false
    };

    containerFunctions = {
        closeWelcomePage: this.closeWelcomePage.bind(this)
    };

    componentDidMount() {
        const { setWindowHeight } = this.props;
        setWindowHeight(window.innerHeight);
    }

    componentDidUpdate(prevProps) {
        const { countries, welcomeType } = this.props;
        const { countries: prevCountries, welcomeType: prevWelcomType } = prevProps;
        if (((countries !== prevCountries && welcomeType)
         || (welcomeType !== prevWelcomType)) && countries.length) {
            this.getCurrentCountry();
        }
    }

    getCurrentCountry() {
        const req = new XMLHttpRequest();
        req.open('GET', document.location, true);
        const {
            countries, currentStoreCode, event
        } = this.props;

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const base_url = window.location.origin;
        const pathArray = window.location.pathname.split('/');
        const isComSubdomain = base_url.endsWith(DOT_COM) && EXCLUDED_DOT_COM_STORES.includes(pathArray[1]);
        if (urlParams.get(NO_REDIRECT)) {
            if (isComSubdomain) {
                setCookie(COM_SUB_STORE, pathArray[1], COOKIE_LIFETIME);
            } else {
                setCookie(NO_REDIRECT, true, COOKIE_LIFETIME);
            }
            event(EVENT_WELCOME_PAGE_NOT_VISIBLE, {});
        } else {
            req.onload = () => {
                const headers = req.getAllResponseHeaders().split('\n');
                const localeHeader = headers.find((header) => header.startsWith(`${CURRENT_COUNTRY}:`));
                if (localeHeader) {
                    const redirectCountryCode = localeHeader.replace(`${CURRENT_COUNTRY}: `, '').trim();
                    const redirectCountry = countries.filter((country) => country.code === redirectCountryCode)[0]
                    || {};
                    const subDomainStore = getCookie(COM_SUB_STORE);
                    const showStoreSelect = (!isComSubdomain && !getCookie(NO_REDIRECT)
                                        && redirectCountry.code !== currentStoreCode) || (isComSubdomain
                                        && ((isComSubdomain && (pathArray[1] !== subDomainStore))));

                    if (showStoreSelect) {
                        event(EVENT_WELCOME_PAGE_VISIBLE, { redirectCountryCode });
                        this.setState({
                            redirectCountry, isPrefered: true, showStoreSelect
                        });
                    } else {
                        event(EVENT_WELCOME_PAGE_NOT_VISIBLE, {});
                    }
                } else {
                    this.setState({ showStoreSelect: false });
                    event(EVENT_WELCOME_PAGE_NOT_VISIBLE, {});
                }
            };
            req.send();
        }
    }

    containerProps() {
        const {
            device: {
                isMobile
            },
            backgroundMobileImage,
            backgroundDesktopImage,
            welcomeType,
            welcomePageTitle,
            welcomePageDescription,
            event
        } = this.props;

        const { redirectCountry, isPrefered, showStoreSelect } = this.state;
        return {
            isMobile,
            backgroundMobileImage,
            backgroundDesktopImage,
            redirectCountry,
            isShowStoreSelect: showStoreSelect,
            welcomeType,
            isPrefered,
            welcomePageTitle,
            welcomePageDescription,
            event
        };
    }

    closeWelcomePage(redirectUrl, redirectCode) {
        const { event } = this.props;
        const { redirectCountry: { code } } = this.state;
        if (redirectCode === code) {
            event(EVENT_WELCOME_PAGE_REDIRECT, { redirectCode: code });
        } else {
            event(EVENT_WELCOME_PAGE_OTHER_REDIRECT, { redirectCode, geoIpCode: code });
        }
        const url = new URL(redirectUrl);
        url.searchParams.append(NO_REDIRECT, true);
        window
            .location
            .assign(url.href);
    }

    render() {
        return (
            <WelcomePage
              { ...this.containerProps() }
              { ...this.containerFunctions }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(WelcomePageContainer);
